import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./containerItem.style.css";
import Button from "antd/lib/button";
import { Trans, useTranslation } from "react-i18next";
import { ContainerType, PostUnclaimApiResponse } from "../../../api/apiTypes";
import { claimContainer, unclaimContainer } from "../../../api/shippingApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import { showApiError, showMessageWithTitle } from "../../../fns/message";
import { Input } from "antd";
import { getUserClaimCount, updateUser } from "../../../api/userApi";
import { updateClaimSts } from "../../../reducers/commonReducer";
import UploadBtn from "../shippingComplete/UploadBtn";
import Compressor from "compressorjs";
import { TERM_DELIVERY_LINK } from "../../../common/consts.common";
import Checkbox from "antd/lib/checkbox";

interface Props extends ContainerType {
  order_status?: "return" | "finished";
  onClaimDone: () => void;
  onUnclaimDone: () => void;
  onShowPhoto: () => void;
  onUploadImage: (file: any) => void;
}
const ContainerLeftBtn = ({
  order_status,
  status,
  pickup_only,
  photo_return,
  uuid,
  container_class,
  is_assigned,
  onShowPhoto,
  onClaimDone,
  onUnclaimDone,
  onUploadImage,
}: Props) => {
  const { t } = useTranslation(["order"]);
  const dispatch = useDispatch();
  const { count_unclaim_in_range } = useSelector(
    (state: RootState) => state.common
  );
  const { terms_agreed } = useSelector((state: RootState) => state.profile);
  const [isOpenTerm, setOpenTerm] = useState(terms_agreed);
  const unclaimReasonRef = useRef("");
  const termModalRef = useRef<any>();

  useEffect(() => {
    setOpenTerm(terms_agreed);
  }, [terms_agreed]);

  useEffect(() => {
    console.log("isTermChecked", termModalRef.current);
    isOpenTerm &&
      termModalRef.current &&
      termModalRef.current.update({
        content: (
          <Checkbox
            className={"order-check body1 white85"}
            checked={isOpenTerm}
          >
            <Trans
              className={"body1 white85"}
              i18nKey={"popup.term.check.label"}
              ns={"order"}
              components={{
                u: (
                  <a
                    onClick={handleOpenTerm}
                    className={"bold white85 underline"}
                  />
                ),
              }}
            />
          </Checkbox>
        ),
        okText: (
          <Button className={"order-check-btn"} disabled={!isOpenTerm}>
            {t("popup.term.check.btn")}
          </Button>
        ),
      });
  }, [isOpenTerm]);

  const handleClaimIfTermCheck = () => {
    if (terms_agreed) {
      claimContainer(uuid, showClaimDone);
    } else {
      askCheckTerm(() => claimContainer(uuid, showClaimDone));
    }
  };

  const handleAcceptIfTermChecked = () => {
    if (terms_agreed) {
      claimContainer(uuid, showAcceptDone);
    } else {
      askCheckTerm(() => claimContainer(uuid, showAcceptDone));
    }
  };

  const handleUnclaimReasonChange = (e: any) => {
    unclaimReasonRef.current = e.target.value;
  };

  const handleUnclaim = () => {
    unclaimContainer(uuid, unclaimReasonRef.current, showUnclaimDone);
  };

  const askUnclaimOrder = () => {
    if (container_class === "BLACK" || container_class === "BLUE") {
      showMessageWithTitle(
        t("popup.unclaim.title"),
        t("popup.unassign.content"),
        t("popup.unclaim.btn.ok"),
        handleUnclaim,
        t("popup.unclaim.btn.cancel")
      );
      return;
    }

    let content = t("popup.unclaim.content");
    if (count_unclaim_in_range > 3) {
      //4회 업무 취소시, 1시간 업무 제한함
      content = t("popup.unclaim.limit.content");
    }
    showMessageWithTitle(
      t("popup.unclaim.title"),
      <div className={"column"}>
        {content}
        <Input
          maxLength={30}
          className={"order-unclaim-input"}
          placeholder={t("unclaim.input.place")}
          autoComplete={"off"}
          onChange={handleUnclaimReasonChange}
        />
      </div>,
      t("popup.unclaim.btn.ok"),
      handleUnclaim,
      t("popup.unclaim.btn.cancel")
    );
  };

  const showClaimDone = () => {
    showMessageWithTitle(
      pickup_only
        ? t("popup.pickup.return.done.title")
        : t("popup.pickup.done.title"),
      <Trans
        className={"body grey92"}
        i18nKey={
          pickup_only
            ? "popup.pickup.return.done.content"
            : "popup.pickup.done.content"
        }
        ns={"order"}
        components={{ dark: <span className={"bold info"} /> }}
      />,
      t("popup.pickup.done.btn.ok"),
      onClaimDone
    );
  };

  const showAcceptDone = () => {
    onClaimDone();
    showMessageWithTitle(
      t("popup.pickedup.return.title"),
      t("popup.accept.container.content")
    );
  };

  const showUnclaimDone = (res: PostUnclaimApiResponse) => {
    getUserClaimCount((res) => {
      dispatch(updateClaimSts(res));
    });
    let content: string | ReactNode = t("popup.unclaim.done.content");
    if (container_class === "BLACK" || container_class === "BLUE") {
      content = t("popup.unassign.content.success");
    } else if (!!res.expire_time_end) {
      content = (
        <Trans
          ns={"order"}
          i18nKey={"popup.unclaim.done.expire.content"}
          className={"body black"}
          values={{
            date: res.expire_time_end,
            minute: res.expire_time_left,
          }}
          components={{
            blue: <span className={"info underline"} />,
          }}
        />
      );
    }
    showMessageWithTitle(
      t("popup.unclaim.done.title"),
      content,
      t("popup.unclaim.done.btn.ok"),
      onUnclaimDone,
      t("popup.unclaim.btn.cancel")
    );
  };

  const handleOpenTerm = () => {
    window.open(TERM_DELIVERY_LINK);
    setOpenTerm(true);
  };

  const handleUpload = (file: File) => {
    if (file?.size >= 1000000) {
      let quality = 0.1;
      if (file.size < 10000000) {
        quality = 1 - file.size / 10000000;
      }
      console.log("file quality", quality);
      //1Gb
      new Compressor(file, {
        quality,
        convertSize: 1000000,
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(blob) {
          const file: File = blob as File;
          onUploadImage && onUploadImage(file);
        },
        error: showApiError,
      });
    } else {
      onUploadImage && onUploadImage(file);
    }
  };

  const askClaimOrder = () => {
    showMessageWithTitle(
      pickup_only ? t("popup.pickup.return.title") : t("popup.pickup.title"),
      pickup_only ? (
        t("popup.pickup.return.content")
      ) : (
        <Trans
          className={"body grey92"}
          i18nKey={"popup.pickup.content"}
          ns={"order"}
          components={{ dark: <span className={"bold info"} /> }}
        />
      ),
      t("popup.pickup.btn.ok"),
      handleClaimIfTermCheck,
      t("popup.unclaim.btn.cancel")
    );
  };

  const askCheckTerm = (func: () => void) => {
    const handleClickOk = () => {
      console.log("updateUser handleClick ok", terms_agreed);
      terms_agreed || updateUser({ terms_agreed: true }, func, dispatch);
    };

    termModalRef.current = showMessageWithTitle(
      t("popup.term.check.title"),
      <Checkbox className={"order-check body1 white85"} checked={isOpenTerm}>
        <Trans
          className={"body1 white85"}
          i18nKey={"popup.term.check.label"}
          ns={"order"}
          components={{
            u: (
              <a
                onClick={handleOpenTerm}
                className={"bold white85 underline"}
              />
            ),
          }}
        />
      </Checkbox>,
      <Button className={"order-check-btn"} disabled={!isOpenTerm}>
        {t("popup.term.check.btn")}
      </Button>,
      handleClickOk,
      "",
      undefined,
      true,
      undefined,
      () => {}
    );
  };

  if (status === "UNITARRIVED") {
    //available or assigned container
    if (is_assigned) {
      return (
        <Button
          type={"text"}
          className={"bold flex border-right order-item-btn"}
          onClick={handleAcceptIfTermChecked}
        >
          {t(`btn.accept`)}
        </Button>
      );
    }
    return (
      <Button
        type={"text"}
        className={"bold flex border-right order-item-btn"}
        onClick={askClaimOrder}
      >
        {t(`btn.claim`)}
      </Button>
    );
  }

  if (status === "CLAIMED") {
    return (
      <Button
        type={"text"}
        className={"bold flex border-right order-item-btn"}
        onClick={askUnclaimOrder}
      >
        {is_assigned ? t("btn.unassign") : t("btn.unclaim")}
      </Button>
    );
  }

  if (order_status === "return") {
    return (
      <UploadBtn
        id={"container-return-upload"}
        containerClass={"flex"}
        className={"bold border-right order-item-btn primary"}
        onUpload={handleUpload}
      >
        {t(`btn.return.box`)}
      </UploadBtn>
    );
  }

  return (
    <Button
      type={"text"}
      className={"bold flex border-right order-item-btn black45"}
      data-cy={"showSuccess"}
      disabled={!photo_return}
      onClick={onShowPhoto}
    >
      {t(`btn.finished`)}
      {!!photo_return && (
        <img className={"order-btn-thumbnail"} src={photo_return} />
      )}
    </Button>
  );
};

export default React.memo(ContainerLeftBtn);
