import Button from "antd/lib/button";
import React, { useRef } from "react";
import styles from "./mapWarningEdu.module.css";
import { Trans } from "react-i18next";
import SafetySvg from "../../../assets/svgs/safety-certificate.svg";
import HelmetPopup from "../helmet/HelmetPopup";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import { useHistory } from "react-router-dom";

const MapWarningEdu = () => {
  const history = useHistory();
  const helmetRef = useRef<any>();
  const { visitedEdu } = useSelector((state: RootState) => state.common);
  const { ride_type, helmet_verified } = useSelector(
    (state: RootState) => state.profile
  );

  const navigateEdu = () => {
    history.push("/profile/edu");
  };
  const showHelmet = () => {
    helmetRef.current.show();
  };

  return (
    <div className={"profile-round-cntr " + styles.container}>
      {visitedEdu || (
        <>
          <Button onClick={navigateEdu} className={styles.divideItem}>
            <img
              alt={"safetyedu"}
              className={styles.safetyImg}
              src={SafetySvg}
            />
            <Trans
              className={"body " + styles.text}
              i18nKey={"warning.edu"}
              ns={"order"}
              components={{
                dark: <span className={"bold " + styles.textError} />,
              }}
            />
          </Button>
          <div className={"profile-divider"} />
        </>
      )}
      {ride_type === "BIKE" && !helmet_verified && (
        <Button onClick={showHelmet} className={styles.divideItem}>
          <img
            alt={"safetyhelmet"}
            className={styles.safetyImg}
            src={SafetySvg}
          />
          <Trans
            className={"body " + styles.text}
            i18nKey={"warning.helmet"}
            ns={"order"}
            components={{
              dark: <span className={"bold " + styles.textError} />,
              d: <span />,
            }}
          />
        </Button>
      )}
      <HelmetPopup ref={helmetRef} />
    </div>
  );
};

export default MapWarningEdu;
