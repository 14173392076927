import React, { useRef, useState } from "react";
import "./scan.style.css";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import PickupContainerScan from "../pickupContainer/PickupContainerScan";

interface Params {
  params: {
    index: number;
  };
}
const ScanScreen = ({ match }: { match: Params }) => {
  const { index } = match.params; // selected order uuid
  const { t } = useTranslation(["scan"]);
  const history = useHistory();
  const html5QrcodeScannerRef = useRef<any>();

  const navigateBarcodeManual = () => {
    history.push(`/scan/container/manual/`);
  };

  const navigateBack = () => {
    history.goBack();
  };

  return (
    <LeftTitledTemplate
      header={t("header")}
      classname={"scanner-screen-container"}
      headerProps={{
        right: t("header.right"),
        onRight: () => {
          if (html5QrcodeScannerRef.current) {
            if (html5QrcodeScannerRef.current.isScanning === true) {
              html5QrcodeScannerRef.current.stop();
              html5QrcodeScannerRef.current.clear();
              navigateBarcodeManual();
            } else {
              html5QrcodeScannerRef.current.clear();
              navigateBarcodeManual();
            }
          } else {
            navigateBarcodeManual();
          }
        },
        onLeft: () => {
          if (html5QrcodeScannerRef.current) {
            if (html5QrcodeScannerRef.current.isScanning === true) {
              html5QrcodeScannerRef.current.stop();
              html5QrcodeScannerRef.current.clear();
              navigateBack();
            } else {
              html5QrcodeScannerRef.current.clear();
              navigateBack();
            }
          } else {
            navigateBack();
          }
        },
      }}
    >
      <PickupContainerScan ref={html5QrcodeScannerRef} />
    </LeftTitledTemplate>
  );
};

export default ScanScreen;
