import React from "react";
import Button from "antd/lib/button";
import logo from "../../../assets/svgs/logo.svg";
import "./launchScreen.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LaunchScreen = () => {
  const { t } = useTranslation(["login"]);

  return (
    <div data-cy={"launchView"} className={"container"}>
      <div className={"inner-container intro-container"}>
        <img src={logo} className="logo" />
        <div className={"subtitle intro-text"}>
          {t("launch.title")}
          <span className={"bold"}>{t("app.name")}</span>
        </div>
        <Link to="/auth/login">
          <Button
            data-cy={"gotoAuth"}
            type={"primary"}
            className={"button btn-b"}
          >
            {t("launch.btn")}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default LaunchScreen;
