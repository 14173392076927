import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getDeliveriesCount,
  getShippingStatus,
  pickupBox,
} from "../../../api/shippingApi";
import { showMessageWithTitle } from "../../../fns/message";
import {
  updateDeliveryCount,
  updateDeliveryStarted,
  updateShippingSts,
} from "../../../reducers/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import { countDeliveries } from "../../../fns/commonFns";
import { ContainerPickupResponse } from "../../../api/apiTypes";
import { RootState } from "../../../reducers/reducer";
import ScanView from "../../organisms/scanView/ScanView";

const PickupContainerScan = React.forwardRef((props, ref: any) => {
  const { t } = useTranslation(["order"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { total_pending_pickup } = useSelector(
    (state: RootState) => state.common
  );

  useEffect(() => {
    getShippingStatus((data) => {
      dispatch(updateShippingSts(data));
    });
  }, []);

  const navigateDelivery = () => {
    history.replace("/home/delivery/");
  };

  const pickup = (
    res: string | null,
    onSuccess: (data: any) => void,
    onError: (data: any) => void
  ) => {
    if (res && !loading) {
      setLoading(true);
      showMessageWithTitle(
        t("popup.pickedup.return.title"),
        t("popup.pickup.shipping.content"),
        t("popup.pickedup.return.btn.cancel"),
        () => {
          pickupBox(res || "", true, onSuccess, onError);
        },
        t("popup.pickedup.return.btn.ok"),
        () => {
          pickupBox(res || "", false, onSuccess, onError);
        }
      );
    }
  };

  const onPickupSuccess = (res: ContainerPickupResponse) => {
    getDeliveriesCount((res) => {
      dispatch(updateDeliveryCount(countDeliveries(res.results)));
    });
    showMessageWithTitle(
      t("popup.pickedup.return.title"),
      <Trans
        className={"body grey92"}
        i18nKey={
          res.count_box < 2
            ? "popup.pickedup.shipping.content"
            : "popup.pickedup.shipping.content.multi"
        }
        values={{
          cnt: res.count_total_items,
          count_box: res.count_box,
        }}
        ns={"order"}
        components={{ dark: <span className={"bold info"} /> }}
      />,
      t("popup.pickedup.return.btn"),
      navigateDelivery
    );
    dispatch(updateDeliveryStarted(false));
  };

  return (
    <ScanView
      pendingLabel={t("cnt.pending.box")}
      guideTitle={t("label.guide.box")}
      guideLabel={t("guide.box")}
      ref={ref}
      pendingCount={total_pending_pickup}
      callApi={pickup}
      onSuccess={onPickupSuccess}
    />
  );
});

export default PickupContainerScan;
