import React, { useState } from "react";
import "../login/loginPhoneScreen.css";
import SectorView from "./SectorView";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import {
  addSectorToProfile,
  clearSectorFromProfile,
} from "../../../reducers/profileReducer";
import { showMessage } from "../../../fns/message";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SectorType } from "../../../api/apiTypes";

const SignupSectorContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["signup"]);
  const [selectedSector, setSector] = useState<SectorType | undefined>();
  const { sector } = useSelector((state: RootState) => state.profile);

  const addSector = () => {
    if (sector?.length >= 3) {
      showMessage(t("sector.msg.max3"));
    } else if (!selectedSector) {
      showMessage(t("sector.msg.select"));
    } else {
      dispatch(addSectorToProfile(selectedSector));
      setSector(undefined);
    }
  };

  const onSelect = (selected: SectorType) => {
    if (sector?.length >= 3) {
      showMessage(t("sector.msg.max3"));
    } else {
      setSector(selected);
    }
  };

  const clearSector = (sector: SectorType) => {
    dispatch(clearSectorFromProfile(sector.id));
    showMessage(t("sector.msg.delete"));
  };

  const onSkip = () => {
    history.push("/auth/account");
  };

  const navigateNext = () => {
    if (sector?.length <= 0) {
      showMessage(t("sector.msg.select"));
    } else {
      onSkip();
    }
  };

  return (
    <SectorView
      btnText={t("sector.btn")}
      selectedSector={selectedSector}
      selectedSectors={sector}
      secure={false}
      onSelectSector={onSelect}
      onAddSector={addSector}
      onDeleteSector={clearSector}
      onNext={navigateNext}
      onRight={onSkip}
    />
  );
};

export default SignupSectorContainer;
