import React, { useMemo } from "react";
import styles from "./containerHistoryItem.module.css";
import { ContainerType } from "../../../api/apiTypes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";

interface Props extends ContainerType {}
const ContainerHistoryItem = ({
  duration,
  timestamp_claimed,
  unit_location,
  box,
  count_total_items,
  count_shipping_incomplete,
  uuid,
  price,
}: Props) => {
  const { t } = useTranslation(["delivery"]);
  const history = useHistory();
  const formatted = useMemo(
    () => format(new Date(timestamp_claimed), "yyyy.MM.dd HH:mm"),
    [timestamp_claimed]
  );

  const navigateDetail = () => {
    history.push(
      `/profile/history/${uuid}/${box?.alias}/${unit_location?.name}`
    );
  };

  return (
    <div
      className={"column round-white " + styles.item}
      data-cy={"navDetail"}
      onClick={navigateDetail}
    >
      <div className={"row " + styles.top}>
        <div className={"body black black flex border-right"}>{formatted}</div>
        <div className={"small primaryActive flex text-right "}>
          {t("history.duration", {
            hour: Math.floor(duration / 60),
            minute: Math.ceil(duration % 60),
          })}
        </div>
      </div>
      <div className={"row border-top " + styles.bottom}>
        <div className={"column flex border-right"}>
          <span className={"body1 black black85"}>
            {unit_location?.name + t("count.divider") + box?.alias}
          </span>
          <div className={"small black45"}>
            {t("history.count", {
              total: count_total_items,
              done: count_total_items - count_shipping_incomplete,
            })}
          </div>
        </div>
        <div className={"column flex "}>
          <div className={"body1 black black85 text-right"}>
            {t("history.won", { won: price.toLocaleString() })}
          </div>
          <div className={"small black45 text-right"}>
            {t("history.price", { won: price.toLocaleString() })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerHistoryItem;
