import React, { useRef, useState } from "react";
import "./camera.style.css";
import CameraIcon from "../../../assets/svgs/iphoneCamera";
import { Camera } from "react-camera-pro";
import Button from "antd/lib/button";
import Bugsnag from "@bugsnag/js";
import { useTranslation } from "react-i18next";

type Props = {
  onSubmit: (photo: any) => void;
  onImageTaken?: () => void;
  submitTxt: string;
  isCameraVisible: boolean;
};
const CameraComponent = ({
  onSubmit,
  submitTxt,
  isCameraVisible,
  onImageTaken,
}: Props) => {
  const cameraRef = useRef(null);
  const imageRef = useRef();
  const { t } = useTranslation("delivery");

  const [previewImage, setPreview] = useState<any>();

  const base64ToBlob = (url: string) => {
    fetch(url)
      .then((res) => res.blob())
      .then((res: any) => {
        console.log("camera base64ToBlob", res);
        setPreview(URL.createObjectURL(res));
        onImageTaken && onImageTaken();
        imageRef.current = res;
      });
  };

  const handleSubmit = () => {
    onSubmit(imageRef.current);
  };

  const takePhoto = () => {
    let camera: any = cameraRef.current;

    if (camera && camera.takePhoto) {
      const img = camera.takePhoto();
      base64ToBlob(img);
    } else {
      Bugsnag.notify(new Error("camera is null"));
    }
  };

  const resultNode = (
    <div className={"camera-container"}>
      <div className={"camera-inner-container"}>
        <div className={"camera-dim"} />
        {previewImage && <img className={"camera-img"} src={previewImage} />}
        {previewImage && (
          <img className={"camera-img-back"} src={previewImage} />
        )}
      </div>
      <div className={"camera-res-btn-container"}>
        <Button data-cy={"onSubmit"} type={"primary"} onClick={handleSubmit}>
          {submitTxt}
        </Button>
      </div>
    </div>
  );

  const cameraNode = (
    <div className={"camera-container"}>
      <div className={"camera-inner-container"}>
        <Camera
          ref={cameraRef}
          errorMessages={{
            noCameraAccessible: t("camera.no.accessible"),
            permissionDenied: t("camera.denied.permission"),
            switchCamera: t("camera.error.switchcamera"),
            canvas: t("camera.error.canvas"),
          }}
          facingMode={"environment"}
          aspectRatio={3 / 4}
        />
      </div>
      <div className={"camera-btn-container"}>
        <div data-cy={"takePhoto"} className={"camera-btn"} onClick={takePhoto}>
          <CameraIcon />
        </div>
      </div>
    </div>
  );

  if (isCameraVisible) return cameraNode;

  return resultNode;
};

export default CameraComponent;
