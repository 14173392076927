export default function ({ color = "#D9D9D9" }: { color?: string }) {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      s
      <rect
        x="18"
        y="18"
        width="36"
        height="36"
        rx="18"
        stroke="white"
        stroke-opacity="0.85"
        stroke-width="36"
      />
      <rect
        className={"camera-inner-circle"}
        x="8"
        y="8"
        width="56"
        height="56"
        rx="28"
        fill="white"
        fillOpacity="0.85"
      />
    </svg>
  );
}
