import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProfileType, UpdateProfileParam } from "./reducerTypes";
import { AppDispatch, AppThunk } from "./store";
import { SectorType } from "../api/apiTypes";

const initialState: ProfileType = (typeof window !== "undefined" &&
  window.Cypress &&
  window.initialState?.profile) || {
  phonenumber: "",
  di: "",
  fullname: "",
  ssn: "",
  dob: "",
  gender: "",
  res_seq: "",
  ride_type: "",
  bank_info_name: "",
  bank_info_bank_code: "",
  bank_info_account_number: "",
  sector: [],
  clickedDeliveryUuid: "",
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateProfile: (state, action: PayloadAction<UpdateProfileParam>) => {
      return { ...state, ...action.payload };
    },
    updateProfileReset: (state, action: PayloadAction<UpdateProfileParam>) => {
      return { ...initialState, ...action.payload };
    },
    updateClickedDelivery: (state, action: PayloadAction<string>) => {
      return { ...state, clickedDeliveryUuid: action.payload };
    },
    clearClickedDelivery: (state, action: PayloadAction) => {
      return { ...state, clickedDeliveryUuid: "" };
    },
    clearProfile: (state, action: PayloadAction) => {
      return initialState;
    },
    addSector: (state, action: PayloadAction<SectorType>) => {
      let sector = state.sector;
      console.log("addSector", action);
      // sector.push(action.payload);

      sector = [action.payload, ...sector];
      return { ...state, sector };
    },

    clearSector: (state, action: PayloadAction<string>) => {
      let sector = state.sector;
      if (sector) {
        sector = sector.filter((s) => s.id !== action.payload);
      }
      return { ...state, sector };
    },
  },
});

export const updateProfile = (data: UpdateProfileParam): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(profileSlice.actions.updateProfile(data));
};

export const updateProfileReset = (data: UpdateProfileParam): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(profileSlice.actions.updateProfileReset(data));
};

export const updateDeliveryUuid = (data: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(profileSlice.actions.updateClickedDelivery(data));
};

export const clearDeliveryUuid = (): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(profileSlice.actions.clearClickedDelivery());
};

export const addSectorToProfile = (sector: SectorType): AppThunk => async (
  dispatch: AppDispatch
) => {
  console.log("addSectorToProfile", sector);
  dispatch(profileSlice.actions.addSector(sector));
};

export const clearSectorFromProfile = (sectorId: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(profileSlice.actions.clearSector(sectorId));
};

export const clearProfile = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(profileSlice.actions.clearProfile());
};

export default profileSlice.reducer;
