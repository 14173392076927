import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/reducer";

type Props = {
  isLoggedIn: boolean;
  component: any;
  path?: string;
};

function ProtectedRouteComponent({
  component: Component,
  isLoggedIn,
  path,
  ...rest
}: Props) {
  const { isFromRN } = useSelector((state: RootState) => state.common);
  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoggedIn ? (
          path?.includes("home") && isFromRN ? (
            <Redirect to={"/pwa/profile"} />
          ) : (
            <Component path={path} {...props} />
          )
        ) : (
          <Redirect to={"/auth/login"} />
        );
      }}
    />
  );
}

export default ProtectedRouteComponent;
