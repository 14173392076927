import React, { useMemo, useState } from "react";
import "./shipping.css";
import { useTranslation } from "react-i18next";
import Button from "antd/lib/button";
import camera from "../../../assets/svgs/camera.svg";
import UploadBtn from "./UploadBtn";
import { PhotoType } from "../../../api/apiTypes";
import Compressor from "compressorjs";
import { showApiError } from "../../../fns/message";
import DeliveryHistoryPhotos from "../historyPhotos/DeliveryHistoryPhotos";

type Props = {
  photo: string;
  onSelect: (image: File) => void;
  photos?: PhotoType[];
  disabled: boolean;
};
const ShippingPhoto = ({ photo, disabled, onSelect, photos }: Props) => {
  const [image, setImage] = useState<File>();
  const [visiblePhoto, setVisiblePhoto] = useState(false);
  const [visiblePhotos, setVisiblePhotos] = useState(false);
  const previewImage = useMemo(
    () => (image ? URL.createObjectURL(image) : photo),
    [image, photo]
  );

  const { t } = useTranslation(["delivery"]);

  const showPhoto = () => {
    setVisiblePhoto(true);
  };

  const hidePhoto = () => {
    setVisiblePhoto(false);
  };

  const hidePhotos = () => {
    setVisiblePhotos(false);
  };

  const handleUpload = (file: File) => {
    if (file?.size >= 1000000) {
      let quality = 0.1;
      if (file.size < 10000000) {
        quality = 1 - file.size / 10000000;
      }
      console.log("file quality", quality);
      //1Gb
      new Compressor(file, {
        quality,
        convertSize: 1000000,
        // The compression process is asynchronous,
        // which means you have to access the `result` in the `success` hook function.
        success(blob) {
          const file: File = blob as File;
          setImage(file);
          onSelect(file);
        },
        error: showApiError,
      });
    } else {
      setImage(file);
      onSelect(file);
    }
  };

  const showPhotos = () => {
    setVisiblePhotos(true);
  };

  return (
    <div className={"column delivery-complete-image-container"}>
      <div className={"row"}>
        {previewImage ? (
          <Button className={"delivery-complete-image"} onClick={showPhoto}>
            <img
              src={previewImage}
              className={
                disabled
                  ? "delivery-preview delivery-preview-disabled"
                  : "delivery-preview"
              }
            />
          </Button>
        ) : (
          <UploadBtn
            className={"delivery-complete-image"}
            onUpload={handleUpload}
          >
            <img src={camera} className={"delivery-camera"} />
            <div className={"small1 black45 bold delivery-camera-txt"}>
              {!!previewImage ? 1 : 0}/1
            </div>
          </UploadBtn>
        )}
        <div className={"column flex"}>
          <div className={"delivery-button-cntr delivery-button-camera-top"}>
            <UploadBtn
              title={t("complete.btn.photo")}
              className={"primary"}
              onUpload={handleUpload}
            />
          </div>
          <div className={"delivery-button-cntr"}>
            <Button
              type={"text"}
              className={"delivery-button-camera black45"}
              onClick={showPhotos}
            >
              {t("history.btn.photo")}
            </Button>
          </div>
        </div>
      </div>
      <DeliveryHistoryPhotos
        visible={visiblePhoto}
        onBack={hidePhoto}
        previewImage={previewImage}
      />
      <DeliveryHistoryPhotos
        visible={visiblePhotos}
        onBack={hidePhotos}
        photos={photos}
      />
    </div>
  );
};

export default ShippingPhoto;
