import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ProfileVehicleContainer from "../components/pages/vehicle/ProfileVehicleContainer";
import ProfileSectorContainer from "../components/pages/sector/ProfileSectorContainer";
import ProfileBankScreen from "../components/pages/bank/ProfileBankScreen";
import NotifyScreen from "../components/pages/notify/NotifyScreen";
import SafetyEduScreen from "../components/pages/edu/SafetyEduScreen";
import HelmetScreen from "../components/pages/helmet/HelmetScreen";
import ContainerHistoryScreen from "../components/pages/history/ContainerHistoryScreen";
import ShippingInContainerScreen from "../components/pages/shippingInContainer/ShippingInContainerScreen";
import KakaoContainer from "../components/pages/kakao/KakaoContainer";
import SelectNaviContainer from "../components/pages/selectNavi/SelectNaviContainer";
function ProfileRoutes() {
  const renderProfileRedirect = () => <Redirect to={"/profile/vehicle"} />;

  return (
    <Switch>
      <Route
        exact
        path="/profile/vehicle"
        component={ProfileVehicleContainer}
      />
      <Route exact path="/profile/helmet" component={HelmetScreen} />
      <Route exact path="/profile/edu" component={SafetyEduScreen} />
      <Route exact path="/profile/sector" component={ProfileSectorContainer} />
      <Route exact path="/profile/bank" component={ProfileBankScreen} />
      <Route exact path="/profile/notify" component={NotifyScreen} />
      <Route exact path="/profile/notify/kakao" component={KakaoContainer} />
      <Route exact path="/profile/navi" component={SelectNaviContainer} />
      <Route
        exact
        path="/profile/history/"
        component={ContainerHistoryScreen}
      />
      <Route
        exact
        path="/profile/history/:uuid/:box/:unit"
        component={ShippingInContainerScreen}
      />
      <Route path="*" component={renderProfileRedirect} />
    </Switch>
  );
}

export default ProfileRoutes;
