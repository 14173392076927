import React, { useEffect, useMemo, useRef, useState } from "react";
import "./sector.style.css";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";

import Button from "antd/lib/button";
import Select from "antd/lib/select";
import { useTranslation } from "react-i18next";
import SectorItem from "./SectorItem";
import { SectorType } from "../../../api/apiTypes";
import { useSectors } from "../../../api/useSectors";
import areas from "../../../datas/sector.data.json";

const { Option } = Select;

type Props = {
  btnText: string;
  selectedSectors: SectorType[];
  selectedSector?: SectorType;
  onAddSector: () => void;
  onNext: () => void;
  onRight?: () => void;
  secure?: boolean;
  onSelectSector: (sector: SectorType) => void;
  onDeleteSector: (sector: SectorType) => void;
};
const SectorView = ({
  btnText,
  selectedSectors,
  selectedSector,
  onAddSector,
  onSelectSector,
  onDeleteSector,
  onNext,
  onRight,
}: Props) => {
  useEffect(() => {}, []);
  const { t } = useTranslation(["signup"]);
  const loadMoreFuncRef = useRef<any>();
  const [area, setArea] = useState<string>();
  const { sectors } = useSectors({
    name_category__icontains: area,
    preference: 1,
  });

  const filtered = useMemo(
    () =>
      Array.isArray(sectors)
        ? sectors.filter((ad) => selectedSectors.every((sd) => sd.id !== ad.id))
        : [],
    [sectors, selectedSectors]
  );

  const selectedSectorsNode =
    selectedSectors &&
    selectedSectors.map((sector, index) => (
      <SectorItem
        key={"sector_" + sector.id}
        onDeleteSector={onDeleteSector}
        sector={sector}
      />
    ));

  const onScroll = (event: any) => {
    var target = event.target;
    if (target.scrollTop + target.offsetHeight + 30 >= target.scrollHeight) {
      if (loadMoreFuncRef.current) {
        loadMoreFuncRef.current();
      }
    }
  };

  const handleSelect = (id: string) => {
    const found = sectors && sectors.find((element) => element.id === id);
    found && onSelectSector(found);
  };

  console.log("area", area);
  return (
    <LeftTitledTemplate
      header={t("sector.title")}
      headerProps={
        onRight
          ? {
              right: t("sector.header.right"),
              onRight: onRight,
            }
          : undefined
      }
      title={t("sector.title")}
      subtitle={t("sector.subtitle")}
    >
      <div className={"vehicle-container"}>
        <Select
          size={"large"}
          className={"local-select"}
          placeholder={t("sector.picker")}
          data-cy={"areaList"}
          value={area}
          onChange={setArea}
          onPopupScroll={onScroll}
        >
          {areas.map((a, index) => (
            <Option key={"sectopt_" + index} value={a}>
              {a}
            </Option>
          ))}
        </Select>
        <Select
          size={"large"}
          className={"btn-add"}
          placeholder={t("district.picker")}
          data-cy={"sectorList"}
          value={selectedSector?.id}
          onChange={handleSelect}
          onPopupScroll={onScroll}
        >
          {filtered.map((v, index) => (
            <Option key={"sectopt_" + v.id} value={v.id} className={"sector"}>
              {v.name}
            </Option>
          ))}
        </Select>
        <Button
          type={"primary"}
          className={"btn-b btn-add"}
          data-cy={"addSector"}
          onClick={onAddSector}
        >
          {t("sector.btn.add")}
        </Button>
        <div className={"local-info-title body1 bold"}>
          {t("sector.label.bold")}
        </div>
        <div className={"local-info body"}>{t("sector.label")}</div>
        {selectedSectorsNode}
      </div>
      <Button
        type={"primary"}
        data-cy={"sectorNext"}
        className={"bottom-btn"}
        onClick={onNext}
      >
        {btnText}
      </Button>
    </LeftTitledTemplate>
  );
};

export default SectorView;
