import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import "./shipping.css";
import incompleteReturnTypes from "../../../datas/incompleteReturn.data.json";
import incompleteTypes from "../../../datas/incomplete.data.json";
import { useTranslation } from "react-i18next";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import Radio from "antd/lib/radio";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import Drawer from "antd/lib/drawer";
import { ReasonDeliveryIncomplete } from "../../../api/apiTypes";
import ReDeliveryPopup from "../ReDeliveryPopup";
import {
  showApiError,
  showMessage,
  showMessageWithTitle,
} from "../../../fns/message";
import { collectReturnItem } from "../../../api/shippingApi";
import { AxiosError } from "axios";

type Props = {
  onCompleted: () => void;
  tracking_number: string;
  delivery_uuid: string;
  is_return: "return" | "delivery";
};
const ShippingIncompleteDrawer = forwardRef(
  ({ delivery_uuid, tracking_number, is_return, onCompleted }: Props, ref) => {
    const redeliveryRef = useRef<any>();
    const { t } = useTranslation(["delivery"]);
    const [reason, setReason] = useState<ReasonDeliveryIncomplete>(
      "NOCUSTOMER"
    );
    let loading = false;
    const otherRef = useRef("");
    const data = useMemo(
      () => (is_return === "return" ? incompleteReturnTypes : incompleteTypes),
      [is_return]
    );

    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({
      show,
    }));

    const show = (reason: ReasonDeliveryIncomplete) => {
      setVisible(true);
      setReason(reason || "NOCUSTOMER");
    };

    const hide = () => {
      setVisible(false);
    };

    const handleChange = (e: any) => {
      setReason(e.target.value);
    };

    const handleChangeOther = (e: any) => {
      otherRef.current = e.target.value;
    };

    const askRedelivery = () => {
      if (reason.endsWith("OTHER") && !otherRef.current) {
        showMessage(t("popup.error.other.empty"));
        return;
      } else {
        redeliveryRef.current.show({
          reason_delivery_incomplete: reason,
          manual_reason: otherRef.current,
        });
      }
    };

    const giveupPickup = () => {
      if (!loading) {
        loading = true;
        if (reason.endsWith("OTHER") && !otherRef.current) {
          showMessage(t("popup.error.other.empty"));
          return;
        } else {
          collectReturnItem(
            tracking_number,
            onGiveupSuccess,
            {
              reason_return_incomplete: reason,
              manual_reason: otherRef.current,
            },
            onCompleteError
          );
        }
      }
    };

    const onGiveupSuccess = () => {
      loading = false;
      showMessageWithTitle(
        t(`popup.delivery.incompleted.title`),
        t("popup.return.incompleted.giveup"),
        t("popup.delivery.incompleted.btn.ok"),
        hide
      );
      onCompleted();
    };

    const onCompleteError = (e: AxiosError) => {
      loading = false;
      setVisible(false);
      showApiError(e);
    };

    return (
      <Drawer
        closable={false}
        mask={false}
        closeIcon={<div />}
        onClose={hide}
        visible={visible}
        placement={"bottom"}
        key={"delivery-incomplete"}
        height={window.innerHeight}
        bodyStyle={{ backgroundColor: "#f3f3f3" }}
      >
        <LeftTitledTemplate
          header={t(`incomplete.${is_return}.header`)}
          title={t(`incomplete.${is_return}.header`)}
          subtitle={t(`incomplete.${is_return}.subtitle`)}
          headerProps={{ onLeft: hide }}
        >
          <Radio.Group
            onChange={handleChange}
            value={reason}
            className={"delivery-detail-inner delivery-incomplete-container"}
          >
            {data.map((meth, idx) => (
              <div key={"ship-radio" + idx}>
                <Radio
                  value={meth}
                  className={"body medium delivery-incomplete-value"}
                >
                  {t(meth)}
                </Radio>
                {reason === "OTHER" && meth === "OTHER" && (
                  <Input
                    defaultValue={""}
                    autoComplete={"off"}
                    className={"delivery-input-incomplete"}
                    placeholder={t("input.incomplete.other")}
                    onChange={handleChangeOther}
                  />
                )}
              </div>
            ))}
          </Radio.Group>
          <div className={"delivery-button-container"}>
            <Button
              type={"primary"}
              className={"btn-b delivery-button-cancel"}
              onClick={is_return === "return" ? giveupPickup : askRedelivery}
            >
              {t(`undone.${is_return}`)}
            </Button>
          </div>
          <ReDeliveryPopup
            ref={redeliveryRef}
            tracking_number={tracking_number}
            delivery_uuid={delivery_uuid}
            onComplete={onCompleted}
          />
        </LeftTitledTemplate>
      </Drawer>
    );
  }
);

export default ShippingIncompleteDrawer;
