export const CompassSvg = ({
  fill = "#6E6EFF",
  className,
}: {
  fill: string;
  className: string;
}) => (
  <svg
    width="11"
    height="11"
    className={className}
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.89961 6.19024V9.86218C4.89961 10.2328 5.20004 10.5332 5.57063 10.5332C5.8248 10.5332 6.05715 10.3896 6.17081 10.1623L10.3283 1.84727L10.5278 1.24868C10.6693 0.82429 10.2656 0.420536 9.84116 0.562L9.24257 0.761529L0.927574 4.91903C0.700242 5.0327 0.556641 5.26505 0.556641 5.51921C0.556641 5.88981 0.857069 6.19024 1.22767 6.19024H4.89961Z"
      fill={fill}
    />
  </svg>
);
