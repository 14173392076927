export default function DeliveryIcon({
  color = "black",
  className,
}: {
  color: string;
  className: string;
}) {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0625 3.0625H3.9375C3.45352 3.0625 3.0625 3.45352 3.0625 3.9375V24.0625C3.0625 24.5465 3.45352 24.9375 3.9375 24.9375H24.0625C24.5465 24.9375 24.9375 24.5465 24.9375 24.0625V3.9375C24.9375 3.45352 24.5465 3.0625 24.0625 3.0625ZM10.3906 19.0312C9.78633 19.0312 9.29688 18.5418 9.29688 17.9375C9.29688 17.3332 9.78633 16.8438 10.3906 16.8438C10.9949 16.8438 11.4844 17.3332 11.4844 17.9375C11.4844 18.5418 10.9949 19.0312 10.3906 19.0312ZM10.3906 15.0938C9.78633 15.0938 9.29688 14.6043 9.29688 14C9.29688 13.3957 9.78633 12.9062 10.3906 12.9062C10.9949 12.9062 11.4844 13.3957 11.4844 14C11.4844 14.6043 10.9949 15.0938 10.3906 15.0938ZM10.3906 11.1562C9.78633 11.1562 9.29688 10.6668 9.29688 10.0625C9.29688 9.4582 9.78633 8.96875 10.3906 8.96875C10.9949 8.96875 11.4844 9.4582 11.4844 10.0625C11.4844 10.6668 10.9949 11.1562 10.3906 11.1562ZM18.7031 18.5938C18.7031 18.7141 18.6047 18.8125 18.4844 18.8125H13.4531C13.3328 18.8125 13.2344 18.7141 13.2344 18.5938V17.2812C13.2344 17.1609 13.3328 17.0625 13.4531 17.0625H18.4844C18.6047 17.0625 18.7031 17.1609 18.7031 17.2812V18.5938ZM18.7031 14.6562C18.7031 14.7766 18.6047 14.875 18.4844 14.875H13.4531C13.3328 14.875 13.2344 14.7766 13.2344 14.6562V13.3438C13.2344 13.2234 13.3328 13.125 13.4531 13.125H18.4844C18.6047 13.125 18.7031 13.2234 18.7031 13.3438V14.6562ZM18.7031 10.7188C18.7031 10.8391 18.6047 10.9375 18.4844 10.9375H13.4531C13.3328 10.9375 13.2344 10.8391 13.2344 10.7188V9.40625C13.2344 9.28594 13.3328 9.1875 13.4531 9.1875H18.4844C18.6047 9.1875 18.7031 9.28594 18.7031 9.40625V10.7188Z"
        fill={color}
        fillOpacity={0.85}
      />
    </svg>
  );
}
