import React, { ReactNode, useCallback, useMemo } from "react";
import styles from "./currentDeliveryItem.module.css";

import Button from "antd/lib/button";
import { useTranslation } from "react-i18next";
import { AddressType, DeliveryListItemType } from "../../../api/apiTypes";
import MapMarker from "../../../assets/svgs/mapMarker";
import ForkRight from "../../../assets/svgs/fork_right.svg";
import { useHistory } from "react-router-dom";
import { Space, Tag } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import SelectNaviPopup from "../selectNaviApp/SelectNaviPopup";
import { useRef } from "react";
import { navigateMapApp } from "../../../fns/commonFns";
import { updateDeliveryUuid } from "../../../reducers/profileReducer";

interface Props extends DeliveryListItemType {
  calledFromMap?: boolean;
  onMap?: (address: AddressType, uuid: string, type: "delivery") => void;
  children?: ReactNode;
  className?: string;
  order?: number;
}
const CurrentDeliveryItem = ({
  uuid,
  address,
  children,
  box_alias,
  order,
  onMap,
  return_count,
  shipping_items_alias,
  return_count_completed,
  return_count_incompleted,
  return_count_retry,
  shipping_count_completed,
  shipping_count_incompleted,
  shipping_count_retry,
  shipping_count,
  complete,
  delivery_info,
  calledFromMap,
  className,
}: Props) => {
  const navigateRef = useRef<any>();
  const { t } = useTranslation(["delivery"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const { naviMap, pendingShippings } = useSelector(
    (state: RootState) => state.common
  );

  let disableStyle = "";
  let disableBackStyle = "";
  if (complete) {
    disableStyle = " black45";
    disableBackStyle = styles.backDisable;
  }

  const uploading = useMemo(() => {
    let uploading = undefined;
    for (let i = 0; i < pendingShippings?.length; i++) {
      if (pendingShippings[i].delivery_uuid === uuid) {
        uploading = pendingShippings[i].status;
        break;
      }
    }
    return uploading;
  }, [pendingShippings]);

  const shipping_count_giveup =
    shipping_count_incompleted - shipping_count_retry;
  const return_count_giveup = return_count_incompleted - return_count_retry;

  const navigateDetail = useCallback(() => {
    if (calledFromMap) {
      dispatch(updateDeliveryUuid(uuid));
    }
    history.push(`/delivery/${uuid}`);
  }, [uuid]);

  const handleMap = useCallback(() => {
    if (!!address && !!onMap) {
      onMap(address, uuid, "delivery");
    } else {
      console.log("no address");
    }
  }, [uuid, address]);

  const navigateDirection = useCallback(() => {
    if (address) {
      console.log("navimap", naviMap);
      if (!naviMap) {
        navigateRef.current.show(address);
      } else {
        navigateMapApp(naviMap, address);
      }
    } else {
      console.log("no address selected");
    }
  }, [address, naviMap]);

  const topNode = (
    <Button className={styles.link} onClick={navigateDetail}>
      <div className={"row flex"}>
        <div className={styles.boxAlias + " small bold " + disableBackStyle}>
          {shipping_items_alias && shipping_items_alias?.length === 1
            ? shipping_items_alias[0]
            : order
            ? (return_count > 0 ? "R" : "") + order
            : box_alias}
        </div>
        <span className={styles.receiver + " body bold " + disableStyle}>
          {address?.name + "\u00a0"}
        </span>
        {uploading && (
          <div className={"row align-center"}>
            <div className={styles.badge} />
            <span className={styles.uploading + " small1 "}>
              {uploading === "fail"
                ? t("shipping.uploading.failed")
                : t("shipping.uploading")}
            </span>
          </div>
        )}
      </div>
      <div className={"border-left " + styles.countCntr}>
        {!!shipping_count && (
          <span className={styles.count + " bold " + disableStyle}>
            {t("shipping.count", {
              count: shipping_count,
            })}
          </span>
        )}
        {!!shipping_count && !!return_count && (
          <span className={styles.count + " bold " + disableStyle}>
            {t("count.divider")}
          </span>
        )}
        {!!return_count && (
          <span
            className={"bold " + (disableStyle ? disableStyle : "errorActive")}
          >
            {t("return.count", {
              count: return_count,
            })}
          </span>
        )}
      </div>
    </Button>
  );

  const completeCountNode = (
    <Space split={"|"} className={styles.completeCountContainer}>
      {!!shipping_count_completed && (
        <span className={" small1 bold black45"}>
          {t("shipping.complete.count", {
            count: shipping_count_completed,
          })}
        </span>
      )}
      {!!shipping_count_giveup && (
        <span className={"small1  bold errorActive "}>
          {t("shipping.incomplete.count", {
            count: shipping_count_giveup,
          })}
        </span>
      )}
      {!!shipping_count_retry && (
        <span className={"small1  bold errorActive "}>
          {t("shipping.retry.count", {
            count: shipping_count_retry,
          })}
        </span>
      )}
      {!!return_count_completed && (
        <span className={"small1  bold black45"}>
          {t("return.complete.count", {
            count: return_count_completed,
          })}
        </span>
      )}
      {!!return_count_giveup && (
        <span className={"small1  bold errorActive"}>
          {t("return.incomplete.count", {
            count: return_count_giveup,
          })}
        </span>
      )}
      {!!return_count_retry && (
        <span className={"small1  bold errorActive "}>
          {t("return.retry.count", {
            count: return_count_retry,
          })}
        </span>
      )}
    </Space>
  );

  const addressNode = (
    <div className={"row border-top " + styles.content}>
      <div className={"order-item-inner text-inside"} onClick={navigateDetail}>
        <div
          className={
            disableStyle + " body bold " + (calledFromMap ? styles.line2 : "")
          }
        >
          {(address?.address_road ||
            address?.address1 ||
            address?.address_jibun) +
            "\u00a0" +
            address?.address2}
        </div>
        <div className={styles.line2 + " small1 black45 " + styles.jibon}>
          <span className={"bold"}>{t("addr.jibon")}</span>
          {"\u00a0" + (address?.address_jibun || address?.address1)}
        </div>
        {completeCountNode}
      </div>
      {calledFromMap ? (
        <Button
          type={"primary"}
          shape={"circle"}
          className={"order-item-direction " + disableBackStyle}
          onClick={navigateDirection}
        >
          <img alt={"deliveryfork"} className={"fork-img"} src={ForkRight} />
        </Button>
      ) : (
        <Button
          type={"text"}
          shape={"circle"}
          className={"order-item-map"}
          onClick={handleMap}
        >
          <MapMarker color={disableStyle ? "rgba(0, 0, 0, 0.45)" : undefined} />
        </Button>
      )}
    </div>
  );

  const deliveryInfoNode = (
    <div className={"row border-top " + styles.bottom}>
      {!!delivery_info?.delivery_complete_on && (
        <Tag color="magenta">{t(delivery_info?.delivery_complete_on)}</Tag>
      )}
      {delivery_info?.delivery_complete_on === "OTHER" && (
        <Tag color="magenta">{delivery_info?.delivery_complete_on_info}</Tag>
      )}
      {!!delivery_info?.entrance_option && (
        <Tag color="magenta">{t(delivery_info?.entrance_option)}</Tag>
      )}
      {delivery_info?.entrance_option === "GATECODE" && (
        <Tag color="magenta">{delivery_info?.entrance_password}</Tag>
      )}
    </div>
  );

  return (
    <div className={styles.container + " " + className}>
      {topNode}
      {addressNode}
      {deliveryInfoNode}
      {children}
      <SelectNaviPopup ref={navigateRef} />
    </div>
  );
};

export default CurrentDeliveryItem;
