import React, { useEffect, useState } from "react";
import "../login/loginPhoneScreen.css";
import { useHistory } from "react-router-dom";
import SignupVehicleView from ".//VehicleView";
import vehicles from "../../../datas/vehicles.data.json";
import { useDispatch, useSelector } from "react-redux";
import {updateProfile, updateProfileReset} from "../../../reducers/profileReducer";
import message from "antd/lib/message";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../reducers/reducer";

const SignupVehicleContainer = ({ location }: { location: any }) => {
  const history = useHistory();
  const { t } = useTranslation(["signup"]);
  const params = new URLSearchParams(location.search);
  const phonenumber = params.get("phonenumber") || undefined;
  const fullname = params.get("fullname") || undefined;
  const res_seq = params.get("resseq") || undefined;
  const { ride_type: savedRideType } = useSelector(
    (state: RootState) => state.profile
  );

  const dispatch = useDispatch();
  const [ride_type, setRideType] = useState(savedRideType);

  useEffect(() => {
    if (phonenumber && fullname && res_seq) {
      dispatch(
        updateProfileReset({
          phonenumber,
          fullname,
          res_seq,
        })
      );
    } else {
      history.replace("/auth/login");
    }
  }, []);

  const navigateNext = () => {
    if (ride_type) {
      dispatch(updateProfile({ ride_type }));
      history.push("/auth/sector");
    } else {
      message.open({
        type: "info",
        icon: <div />,
        content: t("vehicle.popup.select"),
        duration: 2,
      });
    }
  };

  const saveVehicle = (vehicle: string) => {
    setRideType(vehicle);
  };

  return (
    <div data-cy={"vehicleDiv"}>
      <SignupVehicleView
        rideType={ride_type}
        vehicles={vehicles}
        btnText={t("vehicle.btn")}
        onSelectVehicle={saveVehicle}
        onNext={navigateNext}
      />
    </div>
  );
};

export default SignupVehicleContainer;
