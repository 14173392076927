import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ProfileScreen from "../components/pages/profile/ProfileScreen";
import MapScreen from "../components/pages/map/MapScreen";
import CurrentDeliveriesScreen from "../components/pages/currentDeliveries/CurrentDeliveriesScreen";
import ContainerAssignedScreen from "../components/pages/containerAssigned/ContainerAssignedScreen";
import ContainerWhiteScreen from "../components/pages/container/ContainerWhiteScreen";

function MainRoutes() {
  const renderHomeRedirect = () => <Redirect to={"/home/map"} />;

  return (
    <Switch>
      <Route exact path="/home/container/" component={ContainerWhiteScreen} />
      <Route exact path="/home/request/" component={ContainerAssignedScreen} />
      <Route
        exact
        path="/home/request/:index"
        component={ContainerAssignedScreen}
      />
      <Route
        exact
        path="/home/container/:index"
        component={ContainerWhiteScreen}
      />
      <Route exact path="/home/profile" component={ProfileScreen} />
      <Route exact path="/home/delivery/" component={CurrentDeliveriesScreen} />
      <Route
        exact
        path="/home/delivery/:index"
        component={CurrentDeliveriesScreen}
      />
      <Route exact path="/home/:uuid/:type/:preview" component={MapScreen} />
      <Route exact path="/home/:uuid/:type" component={MapScreen} />
      <Route exact path="/home/map" component={MapScreen} />
      <Route path="*" component={renderHomeRedirect} />
    </Switch>
  );
}

export default MainRoutes;
