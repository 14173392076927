import React, { useEffect, useRef } from "react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./mapContainer.module.css";
import { ContainerType } from "../../../api/apiTypes";
// Import Swiper styles
import "swiper/swiper.min.css";
import OrderItem from "../../organisms/container/ContainerItem";
// @ts-ignore
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

interface Props {
  filteredContainers?: ContainerType[];
  onRefresh: () => void;
  onPreview: (uuid: string) => void;
  isPreviewEnabled?: boolean;
  onSelectedChange: (uuid: string) => void;
}

const MapContainerList = ({
  filteredContainers,
  onRefresh,
  onPreview,
  onSelectedChange,
  isPreviewEnabled,
}: Props) => {
  const activeIdxRef = useRef(0);

  useEffect(() => handleSlideChange(activeIdxRef.current), [
    filteredContainers,
    isPreviewEnabled,
  ]);

  function handleSlideChange(slideIndex: number) {
    console.log("handleSlideChange", slideIndex, filteredContainers);
    if (
      !!filteredContainers &&
      slideIndex >= 0 &&
      filteredContainers.length > slideIndex
    ) {
      activeIdxRef.current = slideIndex;
      onSelectedChange(filteredContainers[slideIndex].uuid);
    }
  }

  if (!filteredContainers || !(filteredContainers?.length >= 0)) {
    return null;
  }
  if (filteredContainers && filteredContainers?.length > 0) {
    if (filteredContainers.length === 1) {
      return (
        <div className={styles.container + " " + styles.itemContainer}>
          <OrderItem
            {...filteredContainers[0]}
            calledFromMap
            onClaimDone={onRefresh}
            onUnclaimDone={onRefresh}
            onPreview={onPreview}
          />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <Swiper
          spaceBetween={5}
          slidesPerView={"auto"}
          navigation
          scrollbar={{ draggable: true }}
          centeredSlidesBounds
          width={100}
          draggable
          slidesOffsetAfter={20}
          slidesOffsetBefore={20}
          onSlideChange={(e) => handleSlideChange(e.realIndex)}
        >
          {filteredContainers.map((container, index) => (
            <SwiperSlide
              key={"delivery_slide" + index}
              style={{
                width: "calc(100vw - 40px)",
                marginBottom: 20,
                marginTop: 20,
              }}
              virtualIndex={index}
            >
              <OrderItem
                {...container}
                calledFromMap
                onPreview={onPreview}
                onClaimDone={onRefresh}
                onUnclaimDone={onRefresh}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
  }
  return null;
};

export default MapContainerList;
