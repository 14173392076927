import React, { ReactNode } from "react";
import "./shipping.css";
import { Button } from "antd";
import { showApiError } from "../../../fns/message";
import { useRef } from "react";

type Props = {
  onUpload: (file: File, id?: string) => void;
  title?: string;
  children?: ReactNode;
  id?: string;
  className?: string;
  containerClass?: string;
};
const UploadBtn = ({
  children,
  onUpload,
  className,
  containerClass,
  title,
  id = "file",
}: Props) => {
  const inputRef = useRef<any>();
  const inputFile: any = document.querySelector("input[id=" + id + "]");
  const sendUploadListener = (e: any) => {
    console.log("sendUploadListener", id, inputRef);
    if (inputRef.current) {
      inputRef.current.click();
    } else {
      showApiError(new Error("Unexpected error. Image reader is null"));
    }
  };

  const handleUpload = (e: any) => {
    const files = e.target.files;
    if (files.length > 0) {
      const file = files[0];
      onUpload(file, id);
    }

    // var image: any = document.getElementById("output");
    // image.src = URL.createObjectURL(e.target.files[0]);

    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        // this.setState({image: e.target.result});
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div className={"row " + containerClass}>
      <Button
        type={"text"}
        onClick={sendUploadListener}
        className={"delivery-button-camera " + className}
      >
        {title ? title : children}
      </Button>
      <input
        ref={inputRef}
        type="file"
        name="image"
        accept="image/*"
        id={id}
        style={{ display: "none" }}
        onChange={handleUpload}
      />
    </div>
  );
};

export default UploadBtn;
