import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import DeliveryScreen from "../components/pages/delivery/DeliveryScreen";
import DeliveryinContainerScreen from "../components/pages/deliveryInContainer/DeliveryinContainerScreen";
import MapContainerScreen from "../components/pages/map/MapContainerScreen";
function DeliveryRoutes() {
  const renderDeliveryRedirect = () => <Redirect to={"/home/map"} />;

  return (
    <Switch>
      <Route
        exact
        path="/delivery/recentlist/:uuid/:box/:unit"
        component={DeliveryinContainerScreen}
      />
      <Route
        exact
        path="/delivery/container/:uuid/:alias"
        component={MapContainerScreen}
      />
      <Route exact path="/delivery/:uuid" component={DeliveryScreen} />
      <Route path="*" component={renderDeliveryRedirect} />
      <Route exact path="/delivery/:uuid/:map" component={DeliveryScreen} />
    </Switch>
  );
}

export default DeliveryRoutes;
