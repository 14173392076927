import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import Select from "antd/lib/select";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./mapDelivery.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import { DeliveryListItemType } from "../../../api/apiTypes";
import { useTranslation } from "react-i18next";

// Import Swiper styles
import "swiper/swiper.min.css";
import CurrentDeliveryItem from "../../organisms/currentDelivery/CurrentDeliveryItem";
import DeliveryCountTitle from "../deliveryTitle/DeliveryCountTitle";
import { DeliverySortType } from "../../../reducers/reducerTypes";
const { Option } = Select;
// @ts-ignore
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export const SORT_NOTHING = "";
export const SORT_BOX = "count";
export const SORT_DISTANCE = "distance";

interface Props {
  deliveries?: DeliveryListItemType[];
  onSort?: (sort: DeliverySortType) => void;
  onSelectedChange: (uuid: string) => void;
}

const MapDeliveryList = forwardRef(
  ({ onSelectedChange, onSort, deliveries }: Props, ref) => {
    const { t } = useTranslation(["delivery"]);
    const swiperRef = useRef<any>();
    const selectedIndex = useRef(0);
    const { count_return, count_shipping, count_deliveries } = useSelector(
      (state: RootState) => state.common
    );

    useImperativeHandle(ref, () => ({
      setSelectedIndex: (index: number) => {
        selectedIndex.current = index;
        slideToIndex(index);
      },
    }));

    useEffect(() => {
      const swiperCurrent: any = document.querySelector(".swiper-container");
      swiperRef.current = swiperCurrent?.swiper;
    }, []);

    useEffect(() => {
      if (deliveries && deliveries.length > 0) {
        slideToIndex(selectedIndex.current);
      }
    }, [deliveries]);

    function slideToIndex(index: number) {
      if (deliveries && swiperRef.current && index < deliveries.length) {
        swiperRef.current.slideTo(index, 1);
      }
    }

    function handleSlideChange(e: any) {
      if (deliveries && e.realIndex < deliveries.length)
        onSelectedChange(deliveries[e.realIndex].uuid);
    }

    const deliverySortNode = (
      <Select
        size={"small"}
        className={styles.sortContainer}
        dropdownStyle={{
          borderRadius: 7,
        }}
        defaultValue={SORT_NOTHING}
        onChange={onSort}
      >
        <Option value={SORT_DISTANCE} className={styles.option}>
          <span className={styles.optionFont}>{t("sort.route")}</span>
        </Option>
        <Option value={SORT_BOX} className={styles.option}>
          <span className={styles.optionFont}>{t("sort.box")}</span>
        </Option>
        <Option value={SORT_NOTHING} className={styles.option}>
          <span className={styles.optionFont}>{t("sort.nothing")}</span>
        </Option>
      </Select>
    );

    console.log("swiper deliveroes", deliveries);

    if (deliveries && deliveries.length > 0) {
      return (
        <div className={styles.container}>
          {deliveries && deliveries?.length > 0 && (
            <div className={"row align-center"}>
              <DeliveryCountTitle
                alias={deliveries[0].box_alias}
                totalCount={count_deliveries}
                shippingCount={count_shipping}
                returnCount={count_return}
              />
              {/*{deliverySortNode}*/}
            </div>
          )}
          <Swiper
            spaceBetween={5}
            slidesPerView={"auto"}
            navigation
            scrollbar={{ draggable: true }}
            centeredSlidesBounds
            slidesOffsetAfter={20}
            slidesOffsetBefore={20}
            width={100}
            speed={100}
            style={{ height: "85%" }}
            // onSwiper={(swiper) => {
            //   swiperRef.current = swiper;
            // }}
            onSlideChange={handleSlideChange}
          >
            {deliveries &&
              deliveries.map((delivery, index) => (
                <SwiperSlide
                  key={"delivery_slide" + index}
                  style={{
                    width: "calc(100vw - 40px)",
                  }}
                  className={"column"}
                  virtualIndex={index}
                >
                  <div className={"flex"} />
                  <CurrentDeliveryItem
                    {...delivery}
                    order={index + 1}
                    calledFromMap
                    className={styles.deliveryItem}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      );
    }
    return null;
  }
);

export default MapDeliveryList;
