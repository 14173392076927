import React, { useEffect, useRef, useState } from "react";
import "./account.style.css";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import Select from "antd/lib/select";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import { Trans, useTranslation } from "react-i18next";
import UploadBtn from "../../organisms/shippingComplete/UploadBtn";
import { GUIDE_BANK_LINK, GUIDE_ID_LINK } from "../../../common/consts.common";
import BankSvg from "../../../assets/svgs/bank.svg";
import FolderSvg from "../../../assets/svgs/folder.svg";
import { UpdatePhotoApiParam } from "../../../api/apiTypes";
import { compressFile } from "../../../fns/commonFns";
import { showMessage } from "../../../fns/message";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import banks from "../../../datas/bank.data.json";
import { Form } from "antd";
import { ssnRegex } from "../../../common/regex";
const { Option } = Select;

interface Props {
  onNext: (account: UpdatePhotoApiParam) => void;
  onSkip?: () => void;
  btnText: string;
}

const BankView = ({ btnText, onNext, onSkip }: Props) => {
  const { t } = useTranslation(["signup"]);
  const { t: textBank } = useTranslation(["bank"]);
  const fileBankRef = useRef<File>();
  const fileSsnRef = useRef<File>();
  const [form] = Form.useForm();

  const profile = useSelector((state: RootState) => state.profile);
  const [photo_bank, setPreviewBank] = useState<string>();
  const [photo_ssn, setPreviewSsn] = useState<string>();

  const {
    photo_bank: photoBankProp,
    photo_ssn: photoSsnProp,
    fullname,
  } = profile;

  useEffect(() => {
    console.log("profile update", profile);
    setPreviewBank(photoBankProp);
    setPreviewSsn(photoSsnProp);
    form.setFieldsValue({
      ssn: profile.ssn,
      bank: profile.bank_info_bank_code,
      account: profile.bank_info_account_number,
    });
  }, [profile]);

  useEffect(() => {}, [photoSsnProp]);

  const handleSubmit = (values: {
    account: string;
    bank: string;
    ssn: string;
  }) => {
    console.log("submit", values, form.getFieldError("account"));
    if (!values.ssn) {
      showMessage(t("account.popup.ssn.req.error"), "error");
    } else if (!values.ssn.match(ssnRegex)) {
      showMessage(t("account.popup.ssn.valid.error"), "error");
    } else if (!values.bank) {
      showMessage(t("account.popup.bank.req.error"), "error");
    } else if (!values.account) {
      showMessage(t("account.popup.account.req.error"), "error");
    } else if (!fileBankRef.current && !photo_bank) {
      showMessage(t("account.popup.image"));
    } else if (!fileSsnRef.current && !photo_ssn) {
      showMessage(t("account.popup.image"));
    } else
      onNext({
        file_bank: fileBankRef.current,
        file_ssn: fileSsnRef.current,
        bank_info_account_number: values.account,
        bank_info_bank_code: values.bank,
        ssn: values.ssn,
      });
  };

  const handleUploadBank = (file: File, id?: string) => {
    console.log("handleUploadBank", id);
    setPreviewBank(URL.createObjectURL(file));
    compressFile(file, (file) => {
      fileBankRef.current = file;
    });
  };

  const handleUploadId = (file: File, id?: string) => {
    console.log("handleUploadSnn", id);
    setPreviewSsn(URL.createObjectURL(file));
    compressFile(file, (file) => {
      fileSsnRef.current = file;
    });
  };

  return (
    <LeftTitledTemplate
      header={t("account.header")}
      headerProps={
        onSkip
          ? {
              right: t("account.btn.skip"),
              onRight: onSkip,
            }
          : undefined
      }
      classname={"account-container"}
    >
      <Form
        form={form}
        name="basic"
        data-cy={"submitBar"}
        onFinish={handleSubmit}
      >
        {({ ssn, account, bank }) => {
          return (
            <div className={"account-form-container"} data-cy={"finalSubmit"}>
              <div className={"account-inner"}>
                <div className={"body bold black85 account-title"}>
                  {t("account.title")}
                </div>
                <Input
                  inputMode="numeric"
                  disabled
                  value={fullname}
                  placeholder={t("account.input.noname")}
                  autoComplete={"off"}
                />
                <Form.Item name="ssn" className={"form-item"}>
                  <div className={"account-name"}>
                    <Input
                      inputMode="numeric"
                      maxLength={13}
                      value={ssn}
                      data-cy={"bankAccInput"}
                      placeholder={t("account.input.ssn")}
                      autoComplete={"off"}
                    />
                  </div>
                </Form.Item>
                <Form.Item name={"bank"} className={"form-item"}>
                  <Select
                    size={"large"}
                    data-cy={"bankSelect"}
                    className={"account-name"}
                    value={bank}
                    placeholder={t("account.input.bank")}
                  >
                    {banks.map((b: string, index: number) => (
                      <Option key={b} value={b}>
                        {textBank(b)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item className={"form-item"} name={"account"}>
                  <div className={"account-name"}>
                    <Input
                      inputMode="numeric"
                      maxLength={18}
                      value={account}
                      placeholder={t("account.input.account")}
                      autoComplete={"off"}
                    />
                  </div>
                </Form.Item>
                <div className={"body bold black85 account-title-bank"}>
                  {t("account.title.account")}
                </div>
                <UploadBtn
                  className={"account-upload-btn"}
                  onUpload={handleUploadBank}
                >
                  {photo_bank ? (
                    <div className={"body primary text-inside"}>
                      <img className={"account-upload-img"} src={photo_bank} />
                      {photo_bank}
                    </div>
                  ) : (
                    <>
                      <img
                        alt={"refresh"}
                        className={"account-svg"}
                        src={FolderSvg}
                      />
                      <span>{t("account.input.noimg")}</span>
                    </>
                  )}
                </UploadBtn>
                <div className={"body bold black85 account-title"}>
                  {t("account.title.id")}
                </div>
                <UploadBtn
                  id={"ssn"}
                  className={"account-upload-btn "}
                  onUpload={handleUploadId}
                >
                  {photo_ssn ? (
                    <div className={"body primary text-inside"}>
                      <img className={"account-upload-img"} src={photo_ssn} />
                      {photo_ssn}
                    </div>
                  ) : (
                    <>
                      <img
                        alt={"refresh"}
                        className={"account-svg"}
                        src={FolderSvg}
                      />
                      <span>{t("account.input.noimg")}</span>
                    </>
                  )}
                </UploadBtn>
                <div className={"profile-round-cntr"}>
                  <Button
                    onClick={() => window.open(GUIDE_BANK_LINK)}
                    className={"account-divide-item account-label"}
                  >
                    <img
                      alt={"refresh"}
                      className={"account-svg"}
                      src={BankSvg}
                    />
                    <Trans
                      ns={"signup"}
                      i18nKey={"account.label.account.guide"}
                      className={"body profile-label"}
                      values={{
                        reg: t("account.label.reg"),
                      }}
                      components={{
                        dark: <span className={"bold"} />,
                      }}
                    />
                  </Button>
                  <div className={"profile-divider"} />
                  <Button
                    onClick={() => window.open(GUIDE_ID_LINK)}
                    className={"account-divide-item account-label"}
                  >
                    <img
                      alt={"refresh"}
                      className={"account-svg"}
                      src={BankSvg}
                    />
                    <Trans
                      ns={"signup"}
                      i18nKey={"account.label.id.guide"}
                      className={"body black85 row"}
                      values={{
                        reg: t("account.label.reg"),
                      }}
                      components={{
                        dark: <span className={"bold"} />,
                      }}
                    />
                  </Button>
                </div>
              </div>
              <Button
                data-cy={"submitBank"}
                className={"bottom-btn"}
                type={"primary"}
                htmlType="submit"
              >
                {btnText}
              </Button>
            </div>
          );
        }}
      </Form>
    </LeftTitledTemplate>
  );
};

export default BankView;
