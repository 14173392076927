//a somewhat verbose approach to iOS detection
import {
  AddressType,
  DeliveryCountType,
  DeliveryWorkingType,
} from "../api/apiTypes";
import Compressor from "compressorjs";
import { showApiError } from "./message";
import {
  KAKAO_DIRECT_LINK,
  NAVER_DIRECT_LINK,
  TMAP_DIRECT_LINK,
} from "../common/consts.common";

export const isBrowser = () => typeof window !== "undefined";
export function countDeliveries(list: DeliveryWorkingType[]) {
  let res: DeliveryCountType = {
    count_deliveries: 0,
    count_return: 0,
    count_shipping: 0,
    count_total_items: 0,
    count_remaining: 0,
  };

  list.forEach((l) => {
    res.count_deliveries += l.count_deliveries;
    res.count_return += l.count_return;
    res.count_shipping += l.count_shipping;
    res.count_total_items += l.count_total_items;
    res.count_remaining += l.count_remaining;
  });
  return res;
}

export function navigateMapApp(
  app: any,
  address: AddressType,
  currentPosition?: any
) {
  if (app === "naver")
    NAVER_DIRECT_LINK(
      address.lng,
      address.lat,
      address.address1 || address.address_road || address.address_jibun,
      currentPosition?.coords?.longitude,
      currentPosition?.coords?.latitude
    );
  else if (app === "tmap")
    TMAP_DIRECT_LINK(
      address.lng,
      address.lat,
      address.address1 || address.address_road || address.address_jibun
    );
  else
    KAKAO_DIRECT_LINK(
      address.lng,
      address.lat,
      address.address1 || address.address_road || address.address_jibun,
      currentPosition?.coords?.longitude,
      currentPosition?.coords?.latitude
    );
}

export function compressFile(file: File, callback: (file: File) => void) {
  if (file?.size >= 1000000) {
    let quality = 0.1;
    if (file.size < 10000000) {
      quality = 1 - file.size / 10000000;
    }
    console.log("file quality", quality);
    //1Gb
    new Compressor(file, {
      quality,
      convertSize: 1000000,
      // The compression process is asynchronous,
      // which means you have to access the `result` in the `success` hook function.
      success(blob) {
        callback(blob as File);
      },
      error: showApiError,
    });
  } else {
    callback(file);
  }
}
