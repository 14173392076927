import { configureStore, Action } from "@reduxjs/toolkit";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { ThunkAction } from "redux-thunk";
import rootReducer, { RootState } from "./reducer";
import Bugsnag from "@bugsnag/js";

function saveToLocalStorage(state: RootState) {
  try {
    const serializedState = JSON.stringify({
      common: state.common,
    });
    localStorage.setItem("state", serializedState);
  } catch (e: any) {
    Bugsnag.notify(e);
  }
}

function loadFromLocalStorage(): RootState | undefined {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e: any) {
    Bugsnag.notify(e);
    return undefined;
  }
}
const preloadedState = loadFromLocalStorage();

const middleware: [any] = [thunk];

if (process.env.NODE_ENV === "development" && module.hot) {
  middleware.push(logger);
}

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: middleware,
});

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./reducer", () => {
    const newRootReducer = require("./reducer").default;
    store.replaceReducer(newRootReducer);
  });
}

store.subscribe(() => saveToLocalStorage(store.getState()));
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
