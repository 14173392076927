import React from "react";
import { Route, Redirect } from "react-router-dom";

function AuthRouteComponent({
  component: Component,
  isLoggedIn,
  ...rest
}: any) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoggedIn ? (
          <Redirect to={"/home/map"} />
        ) : (
          <Component {...props} />
        );
      }}
    />
  );
}

export default AuthRouteComponent;
