import http, { API_VER2, http2 } from "../services/axios.service";
import {
  ContainerPickupResponse,
  ContainerType,
  CreateDamagedInvoiceApiResponse,
  GetDeliveryDetailApiResponse,
  GetDeliveryListApiResponse,
  GetDeliveryListCountApiResponse,
  GetSectorApiResponse,
  GetShippingIteminfoResponse,
  GetShippingStatusApiResponse,
  PostUnclaimApiResponse,
  ReturnContainerApiResponse,
  UnitCheckinResponse,
  CompleteShippingApiParams,
  ENTRANCE_OPTION,
  ShippingDetailApiResponse,
  CollectReturnApiParams,
  ReturnContainerApiParams,
  UploadEvent,
} from "./apiTypes";
import { AxiosError } from "axios";
import { showApiError } from "../fns/message";

export const createDamagedInvoice = (
  tracking_number: string,
  onSucceed: (data: CreateDamagedInvoiceApiResponse) => void,
  onError?: () => void
) => {
  http
    .post(
      `/flex/shippingitems/${tracking_number}/invoice/`,
      {}
      // TokenStorage.getAuthentication()
    )
    .then((res) => {
      console.log("createDamagedInvoice", res.data);
      onSucceed(res.data);
    })
    .catch((err) => {
      if (onError) {
        onError();
      }
      showApiError(err);
    });
};

export const getContainerDetail = (
  uuid: string,
  onSucceed: (data: ContainerType) => void
) => {
  http
    .get(`/shipping/containers/${uuid}/`)
    .then((res) => {
      console.log("getContainerDetail", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getShippingItem = (
  tracking_number: string,
  onSucceed: (data: ShippingDetailApiResponse) => void
) => {
  http
    .get(`/flex/shippingitems/${tracking_number}/`)
    .then((res) => {
      console.log("getShippingItem", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const editShippingItem = (
  tracking_number: string,
  data: { entrance_password: string; entrance_option?: ENTRANCE_OPTION },
  onSucceed: (data: ReturnContainerApiResponse) => void,
  onError: (e: AxiosError) => void
) => {
  http
    .patch(`/flex/shippingitems/${tracking_number}/deliveryinfo/`, {
      ...data,
      save: true,
    })
    .then((res) => {
      console.log("editShippingItem", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const returnContainerApi = (
  { photo_return, uuid }: ReturnContainerApiParams,
  onUploadProgress: (event: any) => void,
  onSucceed: (data: ReturnContainerApiResponse) => void,
  onError: () => void
) => {
  let formData = new FormData();
  formData.append("photo_return", photo_return, photo_return.name);
  formData.append("uuid", uuid);
  http
    .post(`/flex/containers/return/`, formData, {
      onUploadProgress,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      console.log("returnContainerApi", res);
      onSucceed(res.data);
    })
    .catch((e) => {
      showApiError(e);
      onError();
    });
};

export const claimContainer = (
  uuid: string,
  onSucceed: (data: GetSectorApiResponse) => void
) => {
  http
    .post(`/flex/containers/${uuid}/claim/`)
    .then((res) => {
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const unclaimContainer = (
  uuid: string,
  reason_text: string,
  onSucceed: (data: PostUnclaimApiResponse) => void
) => {
  http
    .post(`/flex/containers/${uuid}/unclaim/`, { reason_text })
    .then((res) => {
      console.log("unclaimContainer", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const pickupBox = (
  uuid: string,
  lastmile_without_box: boolean,
  onSucceed: (data: ContainerPickupResponse) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/flex/box/${uuid}/pickup/`, { lastmile_without_box })
    .then((res) => {
      console.log("pickupBox", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const rejectAssignedContainer = (
  uuid: string,
  onSucceed: (data: ContainerPickupResponse) => void
) => {
  http
    .post(`/flex/containers/${uuid}/reject/`)
    .then((res) => {
      console.log("rejectAssignedContainer", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const unitCheckin = (
  token: string,
  onSucceed: (data: UnitCheckinResponse) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post(`/flex/checkin/unit/`, { token })
    .then((res) => {
      console.log("unitCheckin", res);
      onSucceed(res.data);
    })
    .catch(onError);
};

export const getShippingStatus = (
  onSucceed: (data: GetShippingStatusApiResponse) => void
) => {
  http
    .get(`/users/self/stat/shipping/`)
    .then((res) => {
      console.log("getShippingStatus", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getAssignedContainers = (page_size: number = 50) =>
  http.get(`/flex/containers/assigned/`, {
    params: { page_size },
  });

export const getClaimedContainers = (
  page_size: number = 10 //한명 당 신청할 업무 갯수는 3으로 제한되어 있다.
) =>
  http.get("/flex/containers/claimed/", {
    params: { page_size },
  });

export const getReturningContainers = (
  page_size: number = 10 //한명 당 신청할 업무 갯수는 3으로 제한되어 있다.
) =>
  http.get("/flex/containers/returning/", {
    params: { page_size },
  });

export const getFinishedContainers = (
  page_size: number = 10 //한명 당 신청할 업무 갯수는 3으로 제한되어 있다.
) =>
  http.get("/flex/containers/finished/?last_in_hours=48", {
    params: { page_size },
  });

export const getDeliveriesCount = (
  onSucceed: (data: GetDeliveryListCountApiResponse) => void,
  last_in_hours?: number
) => {
  let url = "/flex/containers/working/";
  if (last_in_hours) {
    url += `?last_in_hours=${last_in_hours}`;
  }
  http
    .get(url, {
      params: { page_size: 100 },
    })
    .then((res) => {
      console.log("getDeliveriesCount", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export type DeliveryFilter = "all" | "complete" | "incomplete" | "pending";
export const getCurrentDeliveries = async (
  onSucceed: (data: GetDeliveryListApiResponse) => void,
  params: {
    sort?: string;
    filter?: DeliveryFilter;
    lat?: number;
    lng?: number;
  }
) => {
  const res = await http.get(`/flex/deliveries/`, {
    params,
  });
  const page_size = res?.data?.count >= 10 ? res?.data?.count : 10;
  http
    .get(`/flex/deliveries/?page_size=${page_size}`, {
      params,
    })
    .then((res) => {
      console.log("getCurrentDeliveries", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getDeliveriesInContainer = (
  uuid: string,
  onSucceed: (data: GetDeliveryListApiResponse) => void
) => {
  http
    .get(`/flex/containers/${uuid}/deliveries/?filter=all`, {
      params: { page_size: 100 },
    })
    .then((res) => {
      console.log("getDeliveriesInContainer", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const geShippingItemInfo = (
  tracking: string,
  onSucceed: (data: GetShippingIteminfoResponse) => void
) => {
  http
    .get(`/shipping/shippingitems/${tracking}/info/`)
    .then((res) => {
      console.log("geShippingItemInfo", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const getDeliveryDetail = (
  uuid: string,
  onSucceed: (data: GetDeliveryDetailApiResponse) => void
) => {
  http
    .get(`/flex/deliveries/${uuid}/`)
    .then((res) => {
      console.log("getDeliveryDetail", res);
      onSucceed(res.data);
    })
    .catch(showApiError);
};

export const completeShippingItem = (
  tracking_number: string,
  access: string,
  onSucceed: (data: GetDeliveryDetailApiResponse) => void,
  {
    delivery_complete_on,
    reason_delivery_change,
    reason_delivery_incomplete,
    reason_return_incomplete,
    delivery_complete_on_info,
    photo,
    delivery_box_number,
    delivery_box_password,
    retry,
    manual_reason,
  }: CompleteShippingApiParams,
  onError?: (error: AxiosError) => void,
  onUploadProgress?: (progressEvent: UploadEvent) => void
) => {
  let formData = new FormData();
  !!photo && formData.append("photo", photo, photo.name);
  if (!!delivery_complete_on) {
    formData.append("delivery_complete_on", delivery_complete_on);
  }
  !!reason_delivery_change &&
    formData.append("reason_delivery_change", reason_delivery_change);
  !!reason_delivery_incomplete &&
    formData.append("reason_delivery_incomplete", reason_delivery_incomplete);
  !!reason_return_incomplete &&
    formData.append("reason_return_incomplete", reason_return_incomplete);
  !!delivery_complete_on_info &&
    formData.append("delivery_complete_on_info", delivery_complete_on_info);
  !!delivery_box_number &&
    formData.append("delivery_box_number", delivery_box_number);
  !!delivery_box_password &&
    formData.append("delivery_box_password", delivery_box_password);
  !!manual_reason && formData.append("manual_reason", manual_reason);
  retry && formData.append("retry", retry + "");

  console.log(
    "postDeliveryComplete",
    access,
    delivery_complete_on,
    reason_delivery_change,
    reason_delivery_incomplete,
    reason_return_incomplete,
    delivery_complete_on_info,
    photo,
    delivery_box_number,
    delivery_box_password,
    retry
  );
  http2
    .post(`/flex/shippingitems/${tracking_number}/complete/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    })
    .then((res) => {
      console.log("postDeliveryComplete", res);
      onSucceed(res.data);
    })
    .catch((error) => {
      console.log("postDeliveryComplete error", error);
      if (onError) {
        onError(error);
      } else {
        showApiError(error);
      }
    });
};

export const completeFetchShippingItem = (
  tracking_number: string,
  access: string,
  onSucceed: (data: any) => void,
  {
    delivery_complete_on,
    reason_delivery_change,
    reason_delivery_incomplete,
    reason_return_incomplete,
    delivery_complete_on_info,
    photo,
    delivery_box_number,
    delivery_box_password,
    retry,
  }: CompleteShippingApiParams,
  onError?: (error: any) => void,
  onUploadProgress?: (progressEvent: UploadEvent) => void
) => {
  let formData = new FormData();
  !!photo && formData.append("photo", photo, photo.name);
  if (!!delivery_complete_on) {
    formData.append("delivery_complete_on", delivery_complete_on);
  }
  !!reason_delivery_change &&
    formData.append("reason_delivery_change", reason_delivery_change);
  !!reason_delivery_incomplete &&
    formData.append("reason_delivery_incomplete", reason_delivery_incomplete);
  !!reason_return_incomplete &&
    formData.append("reason_return_incomplete", reason_return_incomplete);
  !!delivery_complete_on_info &&
    formData.append("delivery_complete_on_info", delivery_complete_on_info);
  !!delivery_box_number &&
    formData.append("delivery_box_number", delivery_box_number);
  !!delivery_box_password &&
    formData.append("delivery_box_password", delivery_box_password);
  retry && formData.append("retry", retry + "");

  console.log(
    "postDeliveryComplete",
    access,
    delivery_complete_on,
    reason_delivery_change,
    reason_delivery_incomplete,
    reason_return_incomplete,
    delivery_complete_on_info,
    photo,
    delivery_box_number,
    delivery_box_password,
    retry
  );
  fetch(
    `${
      process.env.REACT_APP_API_URL + API_VER2
    }/flex/shippingitems/${tracking_number}/complete/`,
    {
      method: "post",
      headers: {
        Authorization: "Bearer " + access,
      },
      body: formData,
    }
  )
    .then((res) => {
      return res.json();
    })
    .then(function (data) {
      console.log("postDeliveryComplete data", data);
      if (!data.errors) {
        onSucceed(data);
      } else {
        if (onError) {
          onError(data);
        } else {
          showApiError(data);
        }
      }
    })
    .catch((error) => {
      console.log("postDeliveryComplete error", error);
      if (onError) {
        onError(error);
      } else {
        showApiError(error);
      }
    });
};

export const collectReturnItem = (
  tracking_number: string,
  onSucceed: (data: GetDeliveryDetailApiResponse) => void,
  { reason_return_incomplete, manual_reason, retry }: CollectReturnApiParams,
  onError?: (error: AxiosError) => void
) => {
  let formData = new FormData();
  if (!!manual_reason) {
    formData.append("manual_reason", manual_reason);
  }
  !!reason_return_incomplete &&
    formData.append("reason_return_incomplete", reason_return_incomplete);
  retry && formData.append("retry", retry + "");

  http2
    .post(`/flex/shippingitems/${tracking_number}/collect/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      console.log("postDeliveryComplete", res);
      onSucceed(res.data);
    })
    .catch((error) => {
      if (onError) {
        onError(error);
      } else {
        showApiError(error);
      }
    });
};
