import React, { useMemo } from "react";
import styles from "./shippingInContainer.module.css";
import { DeliveryHistoryItemType } from "../../../api/apiTypes";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

interface Props extends DeliveryHistoryItemType {}
const ShippingInContainerItem = ({
  tracking_number,
  timestamp_delivery_complete,
  timestamp_return_complete,
  timestamp_delivery_incomplete,
  timestamp_return_incomplete,
  status,
  retry,
  retry_count,
  is_return,
}: Props) => {
  const { t } = useTranslation(["delivery"]);
  const date = useMemo(() => {
    const timestamp =
      timestamp_delivery_complete ||
      timestamp_return_incomplete ||
      timestamp_delivery_incomplete ||
      timestamp_return_complete;
    return timestamp ? format(new Date(timestamp), "yyyy.MM.dd HH:mm") : "";
  }, [timestamp_delivery_complete]);

  const statusString = useMemo(() => {
    switch (status) {
      case "DELIVERYINCOMPLETE":
        if (is_return) {
          return t("return.giveup");
        }
        return t("delivery.giveup");
      case "DELIVERYCOMPLETE":
        if (is_return) {
          return retry_count > 0 ? t("retry.return.done") : t("return.done");
        }
        return retry_count > 0 ? t("retry.delivery.done") : t("delivery.done");
      default:
        return t(status);
    }
  }, [status]);

  return (
    <div className={"row " + styles.container}>
      <div className={"column"}>
        <div className={"body medium black"}>{tracking_number}</div>
        <div className={"small medium grey93"}>{date}</div>
      </div>
      <div className={"small medium info"}>{statusString}</div>
    </div>
  );
};

export default ShippingInContainerItem;
