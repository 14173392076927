import axios, { AxiosInstance } from "axios";
import { TokenStorage } from "./token.service";

import { updateAuthenticated } from "../reducers/commonReducer";
// @ts-ignore
const API_VER1 = "/api/v1";
export const API_VER2 = "/api/v2";
const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL + API_VER1,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
  timeout: 10000,
});

export const http2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL + API_VER2,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
  },
  timeout: 10000,
});

http.interceptors.request.use(
  async (config) => {
    let accessToken = TokenStorage.getToken();
    console.log("request token 1", accessToken, config);
    if (accessToken) {
      config.headers["authorization"] = `Bearer ${accessToken}`;
      return config;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

http2.interceptors.request.use(
  async (config) => {
    let accessToken = TokenStorage.getToken();
    console.log("request token 2", accessToken, config);
    if (accessToken) {
      config.headers["authorization"] = `Bearer ${accessToken}`;
      return config;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const setResponse = (dispatch: any, http: AxiosInstance) => {
  http.interceptors.response.use(
    (response) => {
      // Return a successful response back to the calling service
      return response;
    },
    (error) => {
      // Return any error which is not due to authentication back to the calling service
      if (error.response?.status !== 401) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
      // Logout user if token refresh didn't work or user is disabled
      if (error.config.url.includes("/auth/token/refresh/")) {
        TokenStorage.clear();
        dispatch(updateAuthenticated(false));
        return new Promise((resolve, reject) => {
          reject(error);
        });
      } else if (
        error.config.url === "/users/self/" &&
        error.response.data.errors
      ) {
        TokenStorage.clear();
        dispatch(updateAuthenticated(false));
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
      // Get new access token and re request failed API with new token
      return TokenStorage.refreshToken()
        .then((response: any) => {
          if (error.config.url.includes("/auth/")) {
            return response;
          }
          const config = error.config;
          console.log(
            "http intercept",
            error.config.url,
            response.data.access,
            error.response
          );
          // New request with new token
          return new Promise((resolve, reject) => {
            http
              .request(config)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          });
        })
        .catch((error: any) => {
          TokenStorage.clear();
          dispatch(updateAuthenticated(false));
          Promise.reject(error);
        });
    }
  );
};
export const fetcher = (url: string) => http.get(url).then((res) => res?.data);

export default http;
