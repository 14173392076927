import React, { Suspense, useCallback, useEffect } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TabbarView from "./components/molecules/tabbar/TabbarView";
import { getUser, getUserClaimCount, getUserSector } from "./api/userApi";
import { clearProfile, updateProfile } from "./reducers/profileReducer";
import ProtectedRouteComponent from "./router/ProtectedRouteComponent";
import ProfileRoutes from "./router/ProfileRoutes";
import AuthRoutes from "./router/AuthRoutes";
import MainRoutes from "./router/MainRoutes";
import AuthRouteComponent from "./router/AuthRouteComponent";
import {
  getFiles,
  syncShippings,
  UPLOAD_COMPLETED,
} from "./services/indexdb.service";
import {
  clearCommon,
  setFromRN,
  setPendingShippings,
  updateAuthenticated,
  updateClaimSts,
  updateDeliveryCount,
  updateNaviMap,
} from "./reducers/commonReducer";
import DeliveryRoutes from "./router/DeliveryRoutes";
import { getDeliveriesCount } from "./api/shippingApi";
import { countDeliveries } from "./fns/commonFns";
import { TokenStorage } from "./services/token.service";
import http, { http2, setResponse } from "./services/axios.service";
import { RootState } from "./reducers/reducer";
import { showApiError, showMessage } from "./fns/message";
import PopupSpinner from "./components/molecules/spinner/PopupSpinnerView";
import ScanRoutes from "./router/ScanRoutes";
import { RenderNaverMap } from "@delivus/daas-naver-map";
import { signout } from "./api/authApi";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import ProfileScreen from "./components/pages/profile/ProfileScreen";
import { NaviAppEnum } from "./components/organisms/selectNaviApp/SelectNaviPopup";

interface SyncManager {
  getTags(): Promise<string[]>;
  register(tag: string): Promise<void>;
}

declare global {
  interface Window {
    $: any;
    naver: any;
    Kakao: any;
    Cypress: any;
    initialState: any;
    install: any;
    installed: boolean;
    gtag: any;
    ReactNativeWebView: any;
    DeviceOrientationEvent: DeviceOrientationEvent;
  }
  interface ServiceWorkerRegistration {
    readonly sync: SyncManager;
  }

  interface DeviceOrientationEvent {
    readonly requestPermission: ()=>Promise<string>;
  }

  interface SyncEvent extends ExtendableEvent {
    readonly lastChance: boolean;
    readonly tag: string;
  }

  interface ServiceWorkerGlobalScopeEventMap {
    sync: SyncEvent;
  }
}

console.log("process.env.REACT_APP_STAGE", process.env.REACT_APP_STAGE);
if (process.env.REACT_APP_STAGE === "PROD") {
  console.log = function no_console() {};
}
function App() {
  const queryParameters = new URLSearchParams(window.location.search);
  const access = queryParameters.get("access") || undefined;
  const refresh = queryParameters.get("refresh") || undefined;
  const naviapp = queryParameters.get("naviapp");
  const { t } = useTranslation("delivery");
  const dispatch = useDispatch();

  const appHeight = () => {
    const doc = document.documentElement;
    let vh = window.innerHeight * 0.01;
    doc.style.setProperty("--vh", `${vh}px`);
  };
  const { authenticated } = useSelector(
    (state: RootState) => state.common
  );


  useEffect(() => {
    if (authenticated === false && !access) {
      const refresh = TokenStorage.getRefreshToken();
      if (refresh) {
        signout(
          refresh,
          () => {
            TokenStorage.clear();
            dispatch(clearProfile());
            dispatch(clearCommon());
          },
          () => {
            TokenStorage.clear();
            dispatch(clearProfile());
            dispatch(clearCommon());
          }
        );
      }
    }
  }, [dispatch, authenticated]);

  useEffect(() => {
    if (naviapp && naviapp in NaviAppEnum) {
      // @ts-ignore
      dispatch(updateNaviMap(naviapp));
    }
  }, [naviapp]);

  const onTokenValid = useCallback(() => {
    dispatch(updateAuthenticated(true));
    getUser(
      (pro) => {
        if (pro.type === "RIDER") {
          getFiles().then((files) => {
            console.log("upload files", files);
            dispatch(setPendingShippings(files));
          });
          dispatch(updateProfile(pro));
          getUserSector((res) => {
            dispatch(updateProfile({ sector: res.results }));
          });
          getDeliveriesCount((res) => {
            dispatch(updateDeliveryCount(countDeliveries(res.results)));
          });
          getUserClaimCount((res) => {
            dispatch(updateClaimSts(res));
          });
        } else {
          showMessage('라이더 계정으로 로그인 해주세요."', "warning");
          TokenStorage.clear();
          dispatch(clearProfile());
          dispatch(clearCommon());
        }
      },
      (err) => {
        showApiError(err);
        if (err.response?.status === 401 || err.response?.status === 403) {
          TokenStorage.clear();
          dispatch(clearProfile());
          dispatch(clearCommon());
        }
      },
      access
    );
  }, [access]);

  useEffect(() => {
    setResponse(dispatch, http);
    setResponse(dispatch, http2);
    console.log("serviceworker", navigator.serviceWorker);
    if (navigator.serviceWorker?.controller) {
      //listen to messages
      navigator.serviceWorker.addEventListener("message", onMessage);
    }

    window.addEventListener("resize", appHeight);
    appHeight();
    syncShippings(dispatch, t);

    document.addEventListener("contextmenu", onContext);
    window.addEventListener("beforeinstallprompt", onBeforeAppInstalled);
    window.addEventListener("appinstalled", onAppInstalled);
    window.addEventListener("DOMContentLoaded", onContentLoaded);

    if (!navigator.serviceWorker) {
      window.installed = true;
    }
    window.addEventListener("online", onLine);

    if (authenticated || access) {
      if (!!access) TokenStorage.storeToken(access);
      if (!!refresh) TokenStorage.storeRefreshToken(refresh);
      TokenStorage.verifyToken(onTokenValid, onRefreshError);
      if (access) {
        dispatch(setFromRN());
      }
    }
    return () => {
      window.removeEventListener("message", onMessage);
      window.removeEventListener("resize", appHeight);
      window.removeEventListener("contextmenu", onContext);
      window.removeEventListener("beforeinstallprompt", onBeforeAppInstalled);
      window.removeEventListener("DOMContentLoaded", onContentLoaded);
      window.removeEventListener("appinstalled", onAppInstalled);
      window.removeEventListener("online", onLine);
    };
  }, [onTokenValid]);

  const onLine = (event: any) => {
    console.log("onLine");
    if (!("SyncManager" in window)) {
      syncShippings(dispatch, t);
    }
  };

  const onMessage = (event: any) => {
    console.log("onMessage", event);
    if (event.data && event.data.type === UPLOAD_COMPLETED) {
      console.log("UPLOAD_COMPLETED");
      if (event.data.error) {
        showApiError(event.data.error);
      }
      getFiles().then((files) => {
        console.log("UPLOAD_COMPLETED", files);
        dispatch(setPendingShippings(files));
      });
    }
  };

  const onContext = (e: any) => {
    // e.preventDefault();
  };

  const onBeforeAppInstalled = (e: any) => {
    e.preventDefault();
    window.install = e;
  };

  const onAppInstalled = () => {
    window.installed = true;
  };

  const onContentLoaded = () => {
    if (
      // @ts-ignore
      navigator?.standalone ||
      window.matchMedia("(display-mode: standalone)").matches ||
      window.matchMedia("(display-mode: fullscreen)").matches ||
      window.matchMedia("(display-mode: minimal-ui)").matches
    ) {
      window.installed = true;
    }
  };

  const onRefreshError = (e: AxiosError) => {
    dispatch(updateAuthenticated(false));
    dispatch(clearProfile());
    dispatch(clearCommon());
  };

  const renderRootRedirect = () => (
    <Redirect
      to={
        authenticated === false && !access
          ? "/auth"
          : access
          ? "/pwa/profile"
          : "/home"
      }
    />
  );

  return (
    <RenderNaverMap
      clientId={process.env.REACT_APP_NCP_CLIENT_ID || ""}
      ncpClientId={process.env.REACT_APP_NCP_CLIENT_ID || ""}
    >
      <Router>
        <Suspense fallback={<PopupSpinner />}>
          <TabbarView />
          <Switch>
            <Route exact path="/" component={renderRootRedirect} />
            <ProtectedRouteComponent
              path={"/profile"}
              isLoggedIn={authenticated}
              component={ProfileRoutes}
            />
            <ProtectedRouteComponent
              path={"/pwa/profile"}
              isLoggedIn={authenticated || !!access}
              component={ProfileScreen}
            />
            <ProtectedRouteComponent
              path={"/delivery"}
              isLoggedIn={authenticated}
              component={DeliveryRoutes}
            />
            <ProtectedRouteComponent
              path={"/scan"}
              isLoggedIn={authenticated}
              component={ScanRoutes}
            />
            <ProtectedRouteComponent
              path={"/home"}
              isLoggedIn={authenticated || !!access}
              component={MainRoutes}
            />
            <AuthRouteComponent
              path={"/auth"}
              isLoggedIn={authenticated}
              component={AuthRoutes}
            />
            <Route path="*" component={renderRootRedirect} />
          </Switch>
        </Suspense>
      </Router>
    </RenderNaverMap>
  );
}

export default App;
