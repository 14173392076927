import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, AppThunk } from "../reducers/store";
import { CommonType, DeliverySortType } from "./reducerTypes";
import {
  CompleteShippingApiParams,
  DeliveryCountType,
  GetShippingStatusApiResponse,
  GetUserClaimCountApiResponse,
  IndexDBDataType,
} from "../api/apiTypes";
import { isBrowser } from "../fns/commonFns";
import { NaviAppEnum } from "../components/organisms/selectNaviApp/SelectNaviPopup";

const initialState: CommonType = (isBrowser() &&
  window &&
  window.Cypress &&
  window.initialState?.profile) || {
  count_claimed_container: 0,
  count_assigned_container: 0,
  count_shipping_items: 0,
  count_deliveries: 0,
  count_shipping: 0,
  count_return: 0,
  sortBy: "",
  showedInstallAlarm: undefined,
  count_container_working: 0,
  count_delivery_working: 0,
  count_unclaim_in_range: 0,
  deliveryStarted: false,
  visitedEdu: false,
  authenticated: false,
  pendingShippings: [],
  naviMap: undefined,
  isFromRN: false,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setPendingShippings: (
      state,
      action: PayloadAction<IndexDBDataType<CompleteShippingApiParams>[]>
    ) => {
      const items = action.payload;
      const pendingShippings: any[] = [];
      items.map(({ id, delivery_uuid, status }) => {
        pendingShippings.push({ tracking_number: id, delivery_uuid, status });
      });
      console.log("setPendingShippings", pendingShippings);
      return { ...state, pendingShippings };
    },
    updateNaviMap: (state, action: PayloadAction<NaviAppEnum>) => {
      return { ...state, naviMap: action.payload };
    },
    setFromRN: (state, action: PayloadAction) => {
      return { ...state, isFromRN: true };
    },
    updateDeliverySort: (state, action: PayloadAction<DeliverySortType>) => {
      return { ...state, sortBy: action.payload };
    },
    updateDeliveryCount: (state, action: PayloadAction<DeliveryCountType>) => {
      return { ...state, ...action.payload };
    },
    updateClaimedOrder: (state, action: PayloadAction<number>) => {
      return { ...state, count_claimed_container: action.payload };
    },
    updateAssignedOrder: (state, action: PayloadAction<number>) => {
      return { ...state, count_assigned_container: action.payload };
    },
    updateDeliveryStarted: (state, action: PayloadAction<boolean>) => {
      return { ...state, deliveryStarted: action.payload };
    },
    updateVisitedEdu: (state, action: PayloadAction<boolean>) => {
      return { ...state, visitedEdu: action.payload };
    },
    updateAuthenticated: (state, action: PayloadAction<boolean>) => {
      return { ...state, authenticated: action.payload };
    },
    updateShowedInstallAlarm: (state, action: PayloadAction<Date>) => {
      return { ...state, showedInstallAlarm: action.payload };
    },
    updateShippingSts: (
      state,
      action: PayloadAction<GetShippingStatusApiResponse>
    ) => {
      return { ...state, ...action.payload };
    },
    updateClaimSts: (
      state,
      action: PayloadAction<GetUserClaimCountApiResponse>
    ) => {
      return { ...state, ...action.payload };
    },
    clearCommon: (state, action: PayloadAction) => {
      return initialState;
    },
  },
});

export const setPendingShippings = (items: any): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(commonSlice.actions.setPendingShippings(items));
};

export const updateNaviMap = (data: NaviAppEnum): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(commonSlice.actions.updateNaviMap(data));
};

export const updateClaimedOrder = (data: number): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(commonSlice.actions.updateClaimedOrder(data));
};

export const updateAssignedOrder = (data: number): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(commonSlice.actions.updateAssignedOrder(data));
};

export const updateDeliveryCount = (
  data: DeliveryCountType
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(commonSlice.actions.updateDeliveryCount(data));
};

export const updateDeliveryStarted = (data: boolean): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(commonSlice.actions.updateDeliveryStarted(data));
};

export const updateVisitedEdu = (data: boolean): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(commonSlice.actions.updateVisitedEdu(data));
};

export const updateAuthenticated = (data: boolean): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(commonSlice.actions.updateAuthenticated(data));
};

export const updateShippingSts = (
  data: GetShippingStatusApiResponse
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(commonSlice.actions.updateShippingSts(data));
};

export const updateDeliverySort = (data: DeliverySortType): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(commonSlice.actions.updateDeliverySort(data));
};

export const updateClaimSts = (
  data: GetUserClaimCountApiResponse
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(commonSlice.actions.updateClaimSts(data));
};

export const clearCommon = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(commonSlice.actions.clearCommon());
};

export const setFromRN = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(commonSlice.actions.setFromRN());
};

export const updateShowedInstallAlarm = (): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(commonSlice.actions.updateShowedInstallAlarm(new Date()));
};
export default commonSlice.reducer;
