import React, { useRef, useState } from "react";
import "./loginPhoneScreen.css";
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import { MaskedInput } from "antd-mask-input";
import Timer from "../../molecules/timer/TimerView";
import { showMessage } from "../../../fns/message";

type Props = {
  expireDate?: Date;
  onCheckPhone: (phonenumber: string) => void;
  onRequestCode: (onSuccess: () => void) => void;
  onVerifyCode: (token: string) => void;
};
const LoginPhoneScreen = ({
  expireDate,
  onRequestCode,
  onCheckPhone,
  onVerifyCode,
}: Props) => {
  const timerRef = useRef<any>();
  const phoneRef = useRef("");
  const codeRef = useRef("");
  const [disableRequest, setDisableRequest] = useState(true);
  const { t } = useTranslation(["login"]);

  const onEndTimer = () => {
    setDisableRequest(false);
  };

  const handleChangePhone = (e: any) => {
    phoneRef.current = e.target.value;
  };

  const handleChangeCode = (e: any) => {
    codeRef.current = e.target.value;
  };

  const handleCheckPhone = () => {
    if (!phoneRef.current || phoneRef.current?.length < 13) {
      showMessage(t("login.popup.error.phone"));
    } else if (!!phoneRef.current && !!onCheckPhone) {
      onCheckPhone(phoneRef.current);
    } else {
      console.log("no onCheckPhone:" + phoneRef.current);
    }
  };

  const handleVerifyCode = () => {
    if (!codeRef.current || codeRef.current?.length < 4) {
      showMessage(t("login.popup.error.code"));
    } else if (!!codeRef.current && !!onVerifyCode) {
      onVerifyCode(codeRef.current);
    } else {
      console.log("no onCheckPhone:" + phoneRef.current);
    }
  };

  const handleRequestCode = () => {
    if (onRequestCode) {
      onRequestCode(onRequestSuccess);
    }
  };

  const onRequestSuccess = () => {
    timerRef.current.reset();
    setDisableRequest(true);
  };

  const renderCodeInput = !!expireDate && (
    <div>
      <div className={"form-code"}>
        <Input
          inputMode="numeric"
          className={"input-code"}
          placeholder={t("login.input.code")}
          autoComplete={"off"}
          maxLength={6}
          data-cy={"codeVerify"}
          onChange={handleChangeCode}
          onSubmit={handleVerifyCode}
          suffix={
            <Button
              type={"default"}
              className={"btn-input"}
              data-cy={"submitVerify"}
              disabled={disableRequest}
              onClick={handleRequestCode}
            >
              {t("login.btn.code")}
            </Button>
          }
        />
      </div>
      <Timer
        ref={timerRef}
        hoursMinSecs={{ minutes: 3 }}
        expireDate={expireDate}
        onEnd={onEndTimer}
      />
      <div className={"form-verify-btn"}>
        <Button
          type={"primary"}
          className={"btn-verify"}
          htmlType="submit"
          data-cy={"submitVerifyFinal"}
          onClick={handleVerifyCode}
        >
          {t("login.btn.recode")}
        </Button>
      </div>
    </div>
  );
  return (
    <LeftTitledTemplate
      title={t("login.title")}
      subtitle={t("login.subtitle")}
      classname={"login-cntr"}
    >
      <div className={"form-phone"}>
        <MaskedInput
          mask="111 1111 1111"
          inputMode="numeric"
          autoComplete={"off"}
          placeholderChar={" "}
          maxLength={16}
          autoFocus={!!expireDate}
          data-cy={"phoneInput"}
          className={"input-phone"}
          onChange={handleChangePhone}
          onSubmit={handleCheckPhone}
          placeholder={t("login.input.phone")}
        />
      </div>
      <Button
        type={"primary"}
        className={"btn-b btn-get-code"}
        data-cy={"submitPhone"}
        htmlType="submit"
        onClick={handleCheckPhone}
      >
        {t("login.btn.phone")}
      </Button>
      {renderCodeInput}
    </LeftTitledTemplate>
  );
};

export default LoginPhoneScreen;
