import {
  CompleteShippingApiParams,
  GetDeliveryDetailApiResponse,
  IndexDBDataType,
} from "../api/apiTypes";
import {
  completeFetchShippingItem,
  completeShippingItem,
} from "../api/shippingApi";
import { setPendingShippings } from "../reducers/commonReducer";
import { showApiError, showMessageWithTitle } from "../fns/message";

const DB_VERSION = 1;
const DB_NAME = "pwa-upload";
export const UPLOAD_STORE_NAME = "uploads";
export const UPLOAD_SYNC_NAME = "upload-sync";
export const UPLOAD_COMPLETED = "upload-completed";

export enum IndexDBErrorType {
  offline,
  failed,
  server,
}

declare const self: ServiceWorkerGlobalScope;

export const openDB = (storeName: string) => {
  console.log("index db open");
  return new Promise((resolve, reject) => {
    if (!self.indexedDB) {
      reject("IndexedDB not supported");
    }

    const request = self.indexedDB.open(DB_NAME, DB_VERSION);
    request.onerror = (event: any) => {
      console.log("object store failed to create", event.target.error);
      reject("DB error: " + event.target.error);
    };

    //when the database is opened with a higher version
    request.onupgradeneeded = (event: any) => {
      const db = event?.target?.result;
      const upgradeTransaction = event.target.transaction;
      if (!db.objectStoreNames.contains(storeName)) {
        //create object store
        db.createObjectStore(storeName, { keyPath: "id" });
      } else {
        upgradeTransaction.objectStore(storeName);
      }
    };

    request.onsuccess = (event: any) => {
      console.log("object store created");
      resolve(event.target.result);
    };
  });
};

export const openObjectStore = (
  db: any,
  name: string,
  transactionMode: any
) => {
  return db.transaction(name, transactionMode).objectStore(name);
};

export const addObject = (storeName: string, dataObject: any) => {
  return new Promise((resolve, reject) => {
    console.log("addObject", dataObject);
    openDB(storeName)
      .then((db) => {
        console.log("serviceworker opendb", db);
        openObjectStore(db, storeName, "readwrite").add(
          dataObject
        ).onsuccess = resolve;
      })
      .catch((reason) => reject(reason));
  });
};

// delete datas from indexedb, that sent to server
export function deleteFromIndexdb(storeName: string, index: string) {
  return new Promise((resolve, reject) => {
    openDB(storeName)
      .then((db) => {
        openObjectStore(db, storeName, "readwrite").delete(
          index
        ).onsuccess = resolve;
      })
      .catch((reason) => reject(reason));
  });
}

// delete datas from indexedb, that sent to server
export function updateIndexdb(
  storeName: string,
  data: IndexDBDataType<CompleteShippingApiParams>
) {
  return new Promise((resolve, reject) => {
    openDB(storeName)
      .then((db) => {
        openObjectStore(db, storeName, "readwrite").put(
          data
        ).onsuccess = resolve;
      })
      .catch((reason) => reject(reason));
  });
}

export const onStoreUpdated = (
  onError: (type: IndexDBErrorType, e?: any) => void,
  t: any,
  dispatch: any
) => {
  console.log("onStoreUpdated");
  onError(IndexDBErrorType.offline, new Error("saved"));
  getFiles().then((files) => {
    console.log("UPLOAD_COMPLETED", files);
    dispatch(setPendingShippings(files));
  });
  showMessageWithTitle(
    t("popup.shipping.nonetwork.title"),
    t("popup.shipping.nonetwork.content")
  );
};

export const onStoreFailed = (
  e: Error,
  onError: (type: IndexDBErrorType, e?: any) => void,
  t: any
) => {
  console.error("onStoreFailed", e);
  onError(IndexDBErrorType.offline, e);
  showMessageWithTitle(
    t("popup.shipping.nonetwork.title"),
    t("popup.shipping.failed.content"),
    "확인",
    () => {},
    undefined,
    () => {},
    false
  );
};

export const uploadShippingBG = async (
  trackingNumber: string,
  access: string,
  delivery_uuid: string,
  onComplete: (res: GetDeliveryDetailApiResponse) => void,
  data: CompleteShippingApiParams,
  onError: (type: IndexDBErrorType, e?: any) => void,
  dispatch: any,
  t: any,
  onUploadProgress?: (event: any) => void
) => {
  const postData: IndexDBDataType<CompleteShippingApiParams> = {
    id: trackingNumber,
    delivery_uuid,
    access,
    data: data,
    status: "pending",
  };
  console.log(
    "uploadShippingBG",
    "SyncManager" in self,
    "serviceWorker" in navigator
  );
  if ("serviceWorker" in navigator && !navigator.onLine) {
    const files = await getFiles();
    const isExist =
      files && files.filter((file) => file.id === trackingNumber).length > 0;
    console.log("serviceworker indexdb", isExist, trackingNumber, files);
    if (isExist) {
      updateIndexdb(UPLOAD_STORE_NAME, postData)
        .then(() => onStoreUpdated(onError, t, dispatch))
        .catch((e) => onStoreFailed(e, onError, t));
    } else {
      addObject(UPLOAD_STORE_NAME, postData)
        .then(() => onStoreUpdated(onError, t, dispatch))
        .catch((e) => onStoreFailed(e, onError, t));
    }
    if ("SyncManager" in self) {
      navigator.serviceWorker.ready
        .then(function (registration) {
          console.log("serviceworker sync register 1");
          registration.sync.register(UPLOAD_SYNC_NAME);
        })
        .catch((e) => {
          onError(IndexDBErrorType.failed, e);
          console.log("serviceworker sync error", JSON.stringify(e));
        });
    }
  } else {
    completeShippingItem(
      trackingNumber,
      access,
      (res) => {
        deleteFromIndexdb(UPLOAD_STORE_NAME, trackingNumber);
        onComplete(res);
      },
      data,
      (e) => {
        showApiError(e);
        onError(IndexDBErrorType.server, e);
      },
      onUploadProgress
    );
  }
};

// get datas from indexedb and send to server
export const getFiles = () => {
  console.log("getFiles");
  return new Promise<IndexDBDataType<any>[]>((resolve, reject) => {
    openDB(UPLOAD_STORE_NAME)
      .then((db) => {
        const files: IndexDBDataType<any>[] = [];
        console.log("getFiles opendb");
        const store = openObjectStore(db, UPLOAD_STORE_NAME, "readwrite");
        console.log("getFiles opendb store", store);
        store.openCursor().onsuccess = (event: any) => {
          const cursor = event.target.result;
          if (cursor) {
            files.push(cursor.value);
            cursor.continue();
          } else {
            resolve(files);
          }
        };
      })
      .catch(function (e) {
        console.error("Error to open db on unsent", e);
        reject(e);
      });
  });
};

export const syncShippings = (dispatch: any, t: any) => {
  syncFiles()
    .then((responses) => {
      console.log("syncShippings resposnes", responses);
      getFiles().then((files) => {
        console.log("UPLOAD_COMPLETED", files);
        dispatch(setPendingShippings(files));
      });
    })
    .catch((e) => {
      console.log("UPLOAD_COMPLETED error", e);
      showApiError(e);
    });
};

export const syncFiles = () => {
  console.log("syncFiles enter");
  return getFiles().then((files) => {
    console.log("sync files 1", files);
    return Promise.all(
      // @ts-ignore
      files.map(
        ({
          data,
          id,
          access,
          delivery_uuid,
        }: IndexDBDataType<CompleteShippingApiParams>) => {
          console.log("sync files", data);
          return new Promise<any>(async (resolve, reject) => {
            completeFetchShippingItem(
              id,
              access,
              async (data) => {
                console.log("sync oncomplete upload", data);
                await deleteFromIndexdb(UPLOAD_STORE_NAME, id);
                resolve(null);
              },
              data || {},
              async (e) => {
                if (e.response?.status === 401) {
                  deleteFromIndexdb(UPLOAD_STORE_NAME, id);
                } else {
                  await updateIndexdb(UPLOAD_STORE_NAME, {
                    data,
                    id,
                    access,
                    delivery_uuid,
                    status: "fail",
                  });
                }
                resolve(e);
              }
            );
          });
        }
      )
    );
  });
};
