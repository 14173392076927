import Button from "antd/lib/button";
import React, { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import "./navbar.css";

export type NavbarProps = {
  classname?: string;
  titleClassname?: string;
  title?: string;
  right?: ReactNode;
  left?: string;
  onLeft?: () => void;
  onRight?: () => void;
};

const Navbar = ({
  classname,
  titleClassname,
  left = "뒤로",
  title,
  right,
  onRight,
  onLeft,
}: NavbarProps) => {
  const history = useHistory();

  const onBack = () => {
    if (onLeft) {
      onLeft();
    } else {
      history.goBack();
    }
  };

  const rightNode = (
    <Button
      data-cy={"onRight"}
      className={"right-node subtitle2"}
      type="text"
      onClick={onRight}
    >
      {right}
    </Button>
  );

  const leftNode = (
    <Button
      data-cy={"onLeft"}
      className={"left-node subtitle2"}
      type="text"
      onClick={onBack}
    >
      {left}
    </Button>
  );

  const titleNode = (
    <span className={"title-node subtitle2 bold " + titleClassname}>
      {title}
    </span>
  );

  return (
    <nav className={"header-container " + classname}>
      {leftNode}
      {titleNode}
      {rightNode}
    </nav>
  );
};

export default Navbar;
