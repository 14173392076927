import React, { useEffect, useState } from "react";
import "./helmet.style.css";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import { useTranslation } from "react-i18next";
import CameraComponent from "../../molecules/camera/camera.component";

type Props = {
  onSubmit: (photo: any) => void;
};
const HelmetView = ({ onSubmit }: Props) => {
  useEffect(() => {}, []);
  const { t } = useTranslation(["helmet"]);
  const [isCameraVisible, setCameraVisible] = useState(true);

  const showCamera = () => {
    setCameraVisible(true);
  };

  const hideCamera = () => {
    setCameraVisible(false);
  };

  return (
    <LeftTitledTemplate
      classname={"helmet-container"}
      header={t("header.camera")}
      headerProps={{
        classname: "helmet-header",
        titleClassname: "helmet-title",
        onLeft: isCameraVisible ? undefined : showCamera,
      }}
    >
      <CameraComponent
        onSubmit={onSubmit}
        onImageTaken={hideCamera}
        submitTxt={t("btn")}
        isCameraVisible={isCameraVisible}
      />
    </LeftTitledTemplate>
  );
};

export default HelmetView;
