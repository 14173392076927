import React, { useCallback, useEffect, useState } from "react";
import "../../organisms/container/containerItem.style.css";
import { useTranslation } from "react-i18next";

import ToptabView from "../../molecules/toptab/ToptabView";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import {
  updateClaimedOrder, updateAssignedOrder,
} from "../../../reducers/commonReducer";
import { getCurrentPosition } from "../../../services/location.service";
import OrderItemsInTab from "../../organisms/ContainersInTab";
import { ContainerType } from "../../../api/apiTypes";
import Select from "antd/lib/select";
import {
  FILTER_GENERAL_ORDER,
  FILTER_RETURN_ORDER,
  SORT_PAY,
  SORT_ROUTE,
} from "../../../common/consts.common";
import EmptyContent from "../../organisms/emptyContent/EmptyContent";
import {
  getAssignedContainers,
  getClaimedContainers,
  getFinishedContainers,
  getReturningContainers,
} from "../../../api/shippingApi";
import { InfiniteList } from "@delivus/react-infinite-list";
import { showApiError } from "../../../fns/message";
import http from "../../../services/axios.service";

const { Option } = Select;

interface Params {
  params: {
    index: number;
  };
}
const ContainerWhiteScreen = ({ match }: { match: Params }) => {
  const { index } = match.params; // selected order uuid
  const { t } = useTranslation(["order"]);
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(index || 0);
  const [refresh, setRefresh] = useState(false);
  const [sortBy, setSortBy] = useState(SORT_PAY);
  const [sortOpen, setSortOpen] = useState(false);
  const [currentPosition, setCurrentPosition] = useState<GeolocationPosition>();
  const { count_assigned_container } = useSelector(
    (state: RootState) => state.common
  );
  const { type } = useSelector((state: RootState) => state.profile);

  const [counts, setCounts] = useState([0, 0, 0, 0]);

  useEffect(() => {
    fetchCounts();
  }, [refresh]);

  const fetchCounts = useCallback(async () => {
    let assignedCount = 0,
      claimedCount = 0,
      finishedCount = 0,
      returningCount = 0;
    try {
      const claimed = await getClaimedContainers();
      console.log("getClaimedContainers", claimed);
      claimedCount = claimed.data.count;
      dispatch(updateClaimedOrder(claimedCount));
    } catch (error) {
      showApiError(error);
    }
    try {
      const assigned = await getAssignedContainers();
      console.log("getAssignedContainers", assigned);
      assignedCount = assigned.data.count;
      dispatch(updateAssignedOrder(assignedCount));
    } catch (error) {
      showApiError(error);
    }
    try {
      const finished = await getFinishedContainers();
      finishedCount = finished.data.count;
      console.log("getFinishedContainers", finished, finishedCount);
    } catch (error) {
      showApiError(error);
    }
    try {
      const returning = await getReturningContainers();
      returningCount = returning.data.count;
      console.log("getReturningContainers", returning, returningCount);
    } catch (error) {
      showApiError(error);
    }
    setCounts((prevCounts: any[]) => {
      let items = [...prevCounts];
      items[0] = assignedCount;
      items[1] = claimedCount;
      items[2] = returningCount;
      items[3] = finishedCount;
      return items;
    });
  }, []);

  useEffect(() => {
    getCurrentPosition(onLocationRetrieved, onLocationError);
  }, []);

  function onLocationRetrieved(position: GeolocationPosition | null) {
    if (position?.coords) {
      setCurrentPosition(position);
    } else {
      console.log("current position");
    }
  }

  function onLocationError() {
    console.log("current position error");
  }

  const updateCount = (count: number, index: number) => {
    setCounts((prevCounts: any[]) => {
      let items = [...prevCounts];
      let item = items[index];
      item = count;
      items[index] = item;
      return items;
    });
    if (index === 0) {
      if (count_assigned_container !== count) {
        dispatch(updateAssignedOrder(count));
      }
    }
  };

  const handleTabChange = (index: any) => {
    setSelectedIndex(index);
    window.history.replaceState(
      "container",
      "container",
      "/home/container/" + index
    );
  };

  const handleClaimDone = useCallback(() => {
    setSelectedIndex(1); //claimed 탭으로 이동
    setRefresh((prev) => !prev);
  }, []);

  const handleUnclaimDone = useCallback(() => {
    setSelectedIndex(0); //claimed 탭으로 이동
    setRefresh((prev) => !prev);
  }, []);

  const handleSortChanged = useCallback((res) => {
    setSortBy(res);
    setRefresh((prev) => !prev);
  }, []);

  const handleRefresh = useCallback(() => {
    setRefresh((prev) => !prev);
  }, []);

  const sortNode = (
    <div onClick={() => setSortOpen((prev) => !prev)}>
      <Select
        size={"small"}
        value={sortBy}
        open={sortOpen}
        onChange={handleSortChanged}
        style={{ width: 88 }}
      >
        <Option
          style={{ fontSize: 12, minHeight: 30 }}
          key={"sort.pay"}
          value={SORT_PAY}
        >
          {t("sort.pay")}
        </Option>
        <Option
          style={{ fontSize: 12, minHeight: 30 }}
          key={"sort.route"}
          value={SORT_ROUTE}
        >
          {t("sort.route")}
        </Option>
        <Option
          style={{ fontSize: 12, minHeight: 30 }}
          key={"sort.general"}
          value={FILTER_GENERAL_ORDER}
        >
          {t("sort.general")}
        </Option>
        <Option
          style={{ fontSize: 12, minHeight: 30 }}
          key={"sort.return"}
          value={FILTER_RETURN_ORDER}
        >
          {t("sort.return")}
        </Option>
      </Select>
    </div>
  );

  const handleListFetchError = (error: any) => {
    showApiError(error);
  };

  const params: any = {
    ordering: sortBy,
    lat: currentPosition?.coords?.latitude,
    lng: currentPosition?.coords?.longitude,
  };
  if (sortBy === FILTER_RETURN_ORDER || sortBy === FILTER_GENERAL_ORDER) {
    params.pickup_only = sortBy === FILTER_RETURN_ORDER;
  }
  return (
    <div className={"container order-container"}>
      <div className={"inner-container"}>
        <ToptabView
          t={t}
          index={selectedIndex}
          tabs={[
            {
              url: "/flex/containers/assigned/",
              label: "available",
              title: "title.available",
              count: 0,
            },
            {
              url: "/flex/containers/claimed/",
              label: "claimed",
              title: "title.claimed",
              count: 0,
            },
            {
              url: "/flex/containers/returning/",
              label: "returned",
              title: "title.returned",
              count: 0,
            },
            {
              url: "/flex/containers/finished",
              label: "done",
              title: "title.done",
              count: 0,
            },
          ]}
          tabLabels={[
            t("available", { count: counts[0] }),
            t("claimed", { count: counts[1] }),
            t("returned", { count: counts[2] }),
            t("done", { count: counts[3] }),
          ]}
          refresh={refresh}
          onChange={handleTabChange}
        >
          {(tab, index) => (
            <InfiniteList
              url={tab.url}
              refresh={refresh}
              http={http as any}
              authenticated={!!type}
              onApiFailed={handleListFetchError}
              height={window.innerHeight - 160}
              params={params}
              onGetCount={(count, data) => updateCount(count, index)}
            >
              {(count?: number, results?: ContainerType[]) => {
                return (
                  <div>
                    <div className={"row space-between align-center order-row"}>
                      <div className={"body1 bold"}>
                        {tab.title && t(tab.title)}
                      </div>
                      {index === 0 && sortNode}
                    </div>
                    {results && results.length > 0 ? (
                      <OrderItemsInTab
                        index={index}
                        data={results}
                        currentPosition={currentPosition}
                        order_status={
                          tab.url.includes("returning")
                            ? "return"
                            : tab.url.includes("finished")
                            ? "finished"
                            : undefined
                        }
                        onClaimDone={handleClaimDone}
                        onUnclaimDone={handleUnclaimDone}
                        onRefresh={handleRefresh}
                        {...tab}
                      />
                    ) : (
                      <EmptyContent
                        title={t(tab.label + ".empty.title")}
                        label={t(tab.label + ".empty.content")}
                      />
                    )}
                  </div>
                );
              }}
            </InfiniteList>
          )}
        </ToptabView>
      </div>
    </div>
  );
};

export default ContainerWhiteScreen;
