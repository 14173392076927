import React from "react";
import "./install.style.css";
import { Trans } from "react-i18next";
import HandDown from "../../../assets/svgs/handDown.svg";

const InstallTooltipContent = () => (
  <div className={"install-tooltip-container"}>
    <img src={HandDown} className={"install-tooltip-img"} />
    <div>
      <Trans
        className={"body install-tooltip"}
        i18nKey={"profile.install.guide"}
        ns={"profile"}
        components={{ dark: <span className={"bold"} /> }}
      />
    </div>
  </div>
);

export default InstallTooltipContent;
