import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import styles from "./entryEdit.module.css";

import { Button, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import Radio from "antd/lib/radio";
import { ENTRANCE_OPTION, ShippingItemType } from "../../../api/apiTypes";
import { editShippingItem } from "../../../api/shippingApi";
import { showApiError, showMessageWithTitle } from "../../../fns/message";

interface Props {
  shippingItem?: ShippingItemType;
  isEdit?: boolean;
  onEdited: () => void;
}

const EntryEditPopup = forwardRef(
  ({ shippingItem, isEdit, onEdited }: Props, ref) => {
    const { t } = useTranslation(["delivery"]);
    const [visible, setVisible] = useState(false);
    const [isWarningCancel, setIsWarningCancel] = useState(false);
    const [isWarningEmpty, setIsWarningEmpty] = useState(false);
    const [method, setMethod] = useState<ENTRANCE_OPTION>("FREE");
    const [inputValue, setInputValue] = useState(
      shippingItem?.delivery_info?.entrance_password || ""
    );

    useEffect(() => {
      if (!!shippingItem?.delivery_info?.entrance_password) {
        setInputValue(shippingItem.delivery_info?.entrance_password);
        setMethod("GATECODE");
      }
    }, [shippingItem]);

    useImperativeHandle(
      ref,
      () => ({
        show: () => {
          setVisible(true);
        },
        hide: hide,
      }),
      []
    );

    const handleChangeMethod = (e: any) => {
      const value = e.target.value;
      setMethod(value);
    };

    const handleOk = () => {
      if (isWarningCancel) {
        setIsWarningCancel(false);
      } else if (isWarningEmpty) {
        setIsWarningEmpty(false);
      } else {
        if (method === "FREE" || (method === "GATECODE" && !!inputValue)) {
          editShippingItem(
            shippingItem?.tracking_number || "",
            { entrance_password: inputValue, entrance_option: method },
            () => {
              onEdited();
              hide();
              showMessageWithTitle(
                t("popup.edit.title"),
                t("popup.edit.success")
              );
            },
            (e) => {
              hide();
              showApiError(e);
            }
          );
        } else {
          setIsWarningEmpty(true);
        }
      }
    };

    const handleCancel = () => {
      if (isWarningCancel || isWarningEmpty) {
        hide();
      } else {
        setIsWarningCancel(true);
      }
    };

    const hide = () => {
      setVisible(false);
      setIsWarningCancel(false);
      setIsWarningEmpty(false);
    };

    const buttonNode = (
      <div className={"row popup-btn-container border"}>
        <Button
          type={"text"}
          className={"popup-btn cancel"}
          onClick={handleCancel}
        >
          {t("popup.edit.btn.cancel")}
        </Button>
        <div className={"popup-btn-divider"} />
        <Button type={"text"} className={"popup-btn ok"} onClick={handleOk}>
          {t("popup.edit.btn.ok")}
        </Button>
      </div>
    );

    return (
      <Modal
        closable={false}
        cancelText={""}
        visible={visible}
        maskClosable
        centered
        footer={buttonNode}
      >
        <div className={"popup-title"}>{t("popup.edit.title")}</div>
        <div className={"popup-message "}>
          {isWarningCancel
            ? t("popup.edit.cancel.content")
            : isWarningEmpty
            ? t("popup.edit.empty")
            : isEdit
            ? t("popup.edit.entry.content")
            : t("popup.register.entry.content")}
        </div>
        {isWarningCancel || isWarningEmpty || (
          <>
            <Radio.Group
              className={"row"}
              onChange={handleChangeMethod}
              value={method}
            >
              <Radio
                value={"FREE"}
                className={"small bold white " + styles.radio}
              >
                {t("popup.entrance.free")}
              </Radio>
              <Radio
                value={"GATECODE"}
                className={"small bold white " + styles.radio}
              >
                {t("popup.entrance.password")}
              </Radio>
            </Radio.Group>
            {method === "GATECODE" && (
              <Input
                className={styles.input}
                value={inputValue}
                placeholder={t("popup.entrance.input.place")}
                onChange={(e) => setInputValue(e.target.value)}
              />
            )}
          </>
        )}
      </Modal>
    );
  }
);

export default EntryEditPopup;
