import React from "react";
import styles from "./deliveryHistoryPhotos.module.css";
import close from "../../../assets/svgs/close.svg";
import { useTranslation } from "react-i18next";
import { PhotoType } from "../../../api/apiTypes";

type Props = {
  visible: boolean;
  onBack: () => void;
  photos?: PhotoType[];
  previewImage?: string;
};
const DeliveryHistoryPhotos = ({
  visible,
  onBack,
  photos,
  previewImage,
}: Props) => {
  const { t } = useTranslation(["delivery"]);
  console.log("preview", previewImage);
  if (!visible) return null;

  return (
    <div className={styles.modal}>
      <div className={styles.closeCntr} onClick={onBack}>
        <img alt={"close"} src={close} className={styles.close} />
      </div>
      <div className={styles.imgCntr}>
        {photos && photos?.length > 0 ? (
          photos.map((p, index) => (
            <div key={"history-ph-" + index} className={"column flex"}>
              <img
                key={"history" + index}
                alt={"history" + index}
                className={styles.img}
                src={p.url}
              />
              <span className={"small1 bold white"}>{p.date}</span>
            </div>
          ))
        ) : !!previewImage ? (
          <img
            alt={"deliveryPhoto"}
            className={styles.fullImage}
            src={previewImage}
          />
        ) : (
          <div className={"body bold white " + styles.empty}>
            {t("history.empty")}
          </div>
        )}
      </div>
    </div>
  );
};

export default DeliveryHistoryPhotos;
