export default function ({ color = "#6E6EFF" }: { color?: string }) {
  return (
    <svg
      width="26"
      height="30"
      viewBox="0 0 26 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.25 8.76503C12.2252 8.76503 11.2621 9.15608 10.5355 9.87077C10.1784 10.221 9.89529 10.6371 9.70236 11.0953C9.50942 11.5536 9.41051 12.0448 9.41129 12.5407C9.41129 13.5487 9.8123 14.496 10.5355 15.2107C10.8915 15.5619 11.3146 15.8404 11.7805 16.0302C12.2464 16.2199 12.7458 16.3172 13.25 16.3164C14.2748 16.3164 15.2379 15.922 15.9645 15.2107C16.6911 14.496 17.0887 13.5487 17.0887 12.5407C17.0887 11.5328 16.6911 10.5855 15.9645 9.87077C15.2379 9.15608 14.2748 8.76503 13.25 8.76503ZM24.9923 7.48736C24.3471 6.02387 23.4167 4.6988 22.2538 3.58692C21.0871 2.465 19.7117 1.57453 18.2026 0.964153C16.6329 0.323632 14.9671 0 13.25 0C11.5329 0 9.86714 0.323632 8.29738 0.960782C6.77903 1.57771 5.41835 2.46095 4.24617 3.58355C3.08401 4.69605 2.15378 6.02097 1.50766 7.48399C0.839314 9.00101 0.5 10.6124 0.5 12.271C0.5 14.6511 1.07923 17.021 2.21714 19.3067C3.13226 21.1439 4.41069 22.934 6.02157 24.6365C8.77379 27.5424 11.6597 29.319 12.4788 29.7944C12.7113 29.9294 12.9765 30.0005 13.2466 30C13.5139 30 13.7778 29.9326 14.0143 29.7944C14.8335 29.319 17.7194 27.5424 20.4716 24.6365C22.0825 22.9374 23.3609 21.1439 24.276 19.3067C25.4208 17.0244 26 14.6578 26 12.2744C26 10.6158 25.6607 9.00438 24.9923 7.48736ZM13.25 18.474C9.91855 18.474 7.21774 15.8175 7.21774 12.5407C7.21774 9.26396 9.91855 6.60748 13.25 6.60748C16.5815 6.60748 19.2823 9.26396 19.2823 12.5407C19.2823 15.8175 16.5815 18.474 13.25 18.474Z"
        fill={color}
      />
    </svg>
  );
}
