import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "../../pages/deliveryInContainer/deliveryinContainerScreen.module.css";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import {
  CreateDamagedInvoiceApiResponse,
  ShippingItemType,
  PhotoType,
  ReasonDeliveryIncomplete,
} from "../../../api/apiTypes";
import { useTranslation } from "react-i18next";
import Button from "antd/lib/button";
import Copy from "../../../assets/svgs/copy.svg";
import copy from "copy-to-clipboard";
import { showMessage, showMessageWithTitle } from "../../../fns/message";
import Drawer from "antd/lib/drawer";
import ReturnScanDrawer from "./ReturnScanDrawer";
import styles from "../../molecules/scanner/scanner.module.css";
import {
  createDamagedInvoice,
  getShippingItem,
  collectReturnItem,
} from "../../../api/shippingApi";
import PopupSpinner from "../../molecules/spinner/PopupSpinnerView";
import ShippingIncompleteDrawer from "../shippingComplete/ShippingIncompleteDrawer";

interface Props extends ShippingItemType {
  delivery_uuid: string;
  sector?: string;
  photos?: PhotoType[];
  onCompleted: () => void;
}

const ReturnCompleteDrawer = forwardRef(
  (
    {
      delivery_uuid,
      status,
      alias,
      tracking_number,
      collect_invoice_status,
      extra_memo,
      onCompleted,
    }: Props,
    ref
  ) => {
    const { t } = useTranslation("delivery");
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const reasonIncomplete = useRef<ReasonDeliveryIncomplete>();
    const incompleteRef = useRef<any>();
    const scannerRef = useRef<any>();

    useImperativeHandle(ref, () => ({
      show,
    }));

    const show = () => {
      setVisible(true);
      getShippingItem(tracking_number, (res) => {
        console.log("getShippingItem", res);
        reasonIncomplete.current = res.reason_delivery_incomplete;
      });
    };

    const showIncomplete = () => {
      incompleteRef.current.show(reasonIncomplete.current);
    };

    const onCompleteSuccess = () => {
      setVisible(false);
      onCompleted();
    };

    const handleCompleteReturn = () => {
      if (collect_invoice_status === "PENDING" && !loading) {
        //already reported invoice was damaged
        setLoading(true);
        createDamagedInvoice(
          tracking_number,
          (res: CreateDamagedInvoiceApiResponse) => {
            collectReturnItem(
              //collect invoice POST 하고 POST shipping complete도 보내줘야해요 by kokospapa
              tracking_number,
              () => {},
              {}
            );
            setLoading(false);
            showMessageWithTitle(
              t("popup.return.info.title"),
              <span className={styles.damagePopupContent}>
                {res.identifier}
              </span>,
              t("btn.ok")
            );
          },
          () => {
            setLoading(false);
          }
        );
      } else {
        scannerRef.current.show();
      }
    };

    const handleCopy = () => {
      document.execCommand("copy", true, tracking_number);
      copy(tracking_number);
      showMessage(t("copy.reg.no", { regno: tracking_number }));
    };

    const buttonNode = (
      <div className={"delivery-button-container"}>
        <Button
          type={"primary"}
          className={"btn-b delivery-button-cancel"}
          onClick={showIncomplete}
        >
          {t("undone.return")}
        </Button>
        <Button
          type={"primary"}
          className={"delivery-button-complete btn-c"}
          onClick={handleCompleteReturn}
        >
          {t("btn.done.return")}
        </Button>
      </div>
    );

    return (
      <Drawer
        closable={false}
        mask={false}
        closeIcon={<div />}
        onClose={() => setVisible(false)}
        visible={visible}
        placement={"bottom"}
        key={"delivery-complete"}
        height={window.innerHeight}
        bodyStyle={{ backgroundColor: "#f3f3f3" }}
      >
        {visible && (
          <LeftTitledTemplate
            header={t("incomplete.header")}
            classname={"delivery-detail-container"}
            headerProps={{ onLeft: () => setVisible(false) }}
          >
            <div className={"delivery-detail-inner"}>
              <div className={"delivery-detail-row"}>
                <div className={"subtitle bold black85 nowrap"}>{alias}</div>
              </div>
              <div className={"delivery-detail-row"}>
                <div className={"delivery-detail-col"}>
                  <div className={"small bold black45"}>{t("reg.no")}</div>
                  <span className={"subtitle bold black85 nowrap"}>
                    {tracking_number}
                  </span>
                </div>
                <Button
                  type={"text"}
                  shape={"circle"}
                  className={"delivery-icon"}
                  onClick={handleCopy}
                >
                  <img src={Copy} />
                </Button>
              </div>
              <div className={"delivery-detail-row"}>
                <div className={"delivery-detail-col"}>
                  <div className={"small bold black45"}>{t("req")}</div>
                  <div className={"body black85 nowrap"}>{extra_memo}</div>
                </div>
              </div>
            </div>
            {buttonNode}
            <ReturnScanDrawer
              ref={scannerRef}
              tracking={tracking_number}
              delivery_uuid={delivery_uuid}
              onCompleted={onCompleteSuccess}
            />
            <ShippingIncompleteDrawer
              ref={incompleteRef}
              is_return={"return"}
              delivery_uuid={delivery_uuid}
              tracking_number={tracking_number}
              onCompleted={onCompleteSuccess}
            />
            {loading && <PopupSpinner />}
          </LeftTitledTemplate>
        )}
      </Drawer>
    );
  }
);

export default ReturnCompleteDrawer;
