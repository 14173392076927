import React from "react";
import "./vehichle.style.css";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";

import Button from "antd/lib/button";
import Select from "antd/lib/select";
import { useTranslation } from "react-i18next";

const { Option } = Select;

type Props = {
  btnText: string;
  rideType: string;
  vehicles: string[];
  onNext: () => void;
  onSelectVehicle: (vehicle: string) => void;
};
const VehicleView = ({
  rideType,
  vehicles,
  onNext,
  btnText,
  onSelectVehicle,
}: Props) => {
  const { t } = useTranslation(["signup"]);
  const { t: textRide } = useTranslation(["ride"]);

  const onSelect = (value: any, option: any) => {
    onSelectVehicle(value);
  };

  return (
    <LeftTitledTemplate header={t("vehicle.header")} title={t("vehicle.title")}>
      <div data-cy={"vehicleDiv"} className={"vehicle-container"}>
        <Select
          size={"large"}
          className={"vehicle-select"}
          data-cy={"vehicleSelect"}
          placeholder={t("vehicle.select")}
          value={rideType ? rideType : undefined}
          onChange={onSelect}
        >
          {vehicles.map((v, index) => (
            <Option key={v} value={v}>
              {textRide(v)}
            </Option>
          ))}
        </Select>
        <div className={"vehicle-info small black45"}>{t("vehicle.info")}</div>
      </div>
      <Button
        data-cy={"vehicleNext"}
        type={"primary"}
        className={"bottom-btn"}
        onClick={onNext}
      >
        {btnText}
      </Button>
    </LeftTitledTemplate>
  );
};

export default VehicleView;
