import React, { useEffect, useState } from "react";
import "../login/loginPhoneScreen.css";
import SectorView from "./SectorView";
import { showMessage } from "../../../fns/message";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { updateUserSector } from "../../../api/userApi";
import { GetSectorApiResponse, SectorType } from "../../../api/apiTypes";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../../reducers/profileReducer";
import { RootState } from "../../../reducers/reducer";

const ProfileSectorContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(["profile"]);
  const { sector } = useSelector((state: RootState) => state.profile);
  const [selectedSector, setSector] = useState<SectorType>();
  const [selectedSectors, setSectors] = useState<SectorType[]>(sector);

  useEffect(() => {
    setSectors(sector);
  }, [sector]);

  const onSelect = (sector: SectorType) => {
    if (selectedSectors?.length >= 3) {
      showMessage(t("sector.msg.max3"));
    } else {
      setSector(sector);
    }
  };

  const addSector = () => {
    if (selectedSectors?.length >= 3) {
      showMessage(t("sector.msg.max3"));
    } else if (!selectedSector) {
      showMessage(t("sector.msg.select"));
    } else {
      setSectors([...selectedSectors, selectedSector]);
      setSector(undefined);
    }
  };

  const clearSector = (sector: SectorType) => {
    const filtered = selectedSectors.filter((s) => s.id !== sector.id);
    setSectors(filtered);
  };

  const saveSector = () => {
    if (selectedSectors?.length <= 0) {
      showMessage(t("sector.msg.select"));
    } else {
      updateUserSector(selectedSectors, navigateNext);
    }
  };

  const navigateNext = (data: GetSectorApiResponse) => {
    dispatch(updateProfile({ sector: data.results }));
    showMessage(t("sector.msg.saved"));
    setTimeout(() => {
      history.replace("/home/profile");
    }, 1000);
  };

  return (
    <SectorView
      btnText={t("sector.btn")}
      selectedSector={selectedSector}
      selectedSectors={selectedSectors}
      onSelectSector={onSelect}
      onAddSector={addSector}
      onDeleteSector={clearSector}
      onNext={saveSector}
    />
  );
};

export default ProfileSectorContainer;
