import React from "react";
import { Route, Redirect } from "react-router-dom";

function SignupRouteComponent({
  component: Component,
  isIdentified,
  ...rest
}: any) {
  return (
    <Route
      {...rest}
      render={(props) => {
        return isIdentified ? (
          <Component {...props} />
        ) : (
          <Redirect to={"/auth/login"} />
        );
      }}
    />
  );
}

export default SignupRouteComponent;
