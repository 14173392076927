import React, { ReactNode } from "react";
import "./leftTitleTemplate.css";
import Navbar, { NavbarProps } from "../../molecules/navbar/NavbarView";

type Props = {
  classname?: string;
  header?: string;
  headerProps?: NavbarProps;
  title?: string;
  subtitle?: string;
  children: ReactNode;
};
const LeftTitledTemplate = ({
  classname,
  header,
  headerProps,
  title,
  subtitle,
  children,
}: Props) => {
  return (
    <div className={"container left-container"}>
      {header && <Navbar title={header} {...headerProps} />}
      <div className={"inner-container left-inner-container " + classname}>
        {title && <div className={"title bold title-inner"}>{title}</div>}
        {subtitle && <div className={"body subtitle-inner"}>{subtitle}</div>}
        {children}
      </div>
    </div>
  );
};

export default LeftTitledTemplate;
