import { combineReducers } from "@reduxjs/toolkit";
import commonReducer from "./commonReducer";
import profileReducer from "./profileReducer";

const rootReducer = combineReducers({
  common: commonReducer,
  profile: profileReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
