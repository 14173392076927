export default function ({ color = "#6E6EFF" }: { color?: string }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5998 15.9333C23.7293 14.8048 24.5503 13.4052 24.984 11.8686C25.4178 10.332 25.4498 8.7098 25.0772 7.15726L28.4038 5.73059C28.5053 5.6871 28.616 5.66949 28.7259 5.67932C28.8358 5.68915 28.9416 5.72612 29.0337 5.78692C29.1259 5.84772 29.2014 5.93044 29.2537 6.02766C29.306 6.12488 29.3333 6.23355 29.3332 6.34393V25.3333L19.9998 29.3333L11.9998 25.3333L3.59584 28.9346C3.49439 28.9781 3.38373 28.9957 3.27379 28.9859C3.16385 28.976 3.05808 28.9391 2.96595 28.8783C2.87383 28.8175 2.79824 28.7347 2.74598 28.6375C2.69371 28.5403 2.66641 28.4316 2.6665 28.3213V9.33326L6.83851 7.54526C6.54496 9.04603 6.62505 10.596 7.07171 12.0585C7.51838 13.521 8.3179 14.8512 9.39984 15.9319L15.9998 22.5333L22.5998 15.9333ZM20.7145 14.0479L15.9998 18.7599L11.2852 14.0466C10.353 13.1142 9.71823 11.9263 9.46114 10.6331C9.20405 9.33997 9.33617 7.99961 9.84081 6.78154C10.3454 5.56347 11.1999 4.52238 12.2962 3.78992C13.3925 3.05745 14.6814 2.6665 15.9998 2.6665C17.3183 2.6665 18.6072 3.05745 19.7035 3.78992C20.7997 4.52238 21.6542 5.56347 22.1589 6.78154C22.6635 7.99961 22.7956 9.33997 22.5385 10.6331C22.2814 11.9263 21.6467 13.1142 20.7145 14.0466V14.0479Z"
        fill={color}
        fill-opacity="0.45"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.809 7.22456C20.067 7.52013 20.0631 7.99498 19.8004 8.28517L15.7263 12.7852C15.467 13.0716 15.0515 13.0716 14.7922 12.7852L12.1996 9.92154C11.9369 9.63134 11.933 9.15649 12.191 8.86092C12.4489 8.56535 12.871 8.561 13.1337 8.85119L15.2593 11.1989L18.8663 7.21483C19.129 6.92463 19.5511 6.92899 19.809 7.22456Z"
        fill="white"
      />
    </svg>
  );
}
