import React, { useEffect, useState } from "react";
import "./selectNavi.css";
import { useTranslation } from "react-i18next";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import Button from "antd/lib/button";
import { useDispatch, useSelector } from "react-redux";
import Radio from "antd/lib/radio";
import styles from "../../organisms/selectNaviApp/selectNavi.module.css";
import { NaviAppEnum } from "../../organisms/selectNaviApp/SelectNaviPopup";
import { updateNaviMap } from "../../../reducers/commonReducer";
import { showMessageWithTitle } from "../../../fns/message";
import { useHistory } from "react-router-dom";
import { RootState } from "../../../reducers/reducer";

const SelectNaviContainer = () => {
  const { t } = useTranslation(["profile"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { naviMap } = useSelector((state: RootState) => state.common);
  const [method, setMethod] = useState<NaviAppEnum>(
    naviMap || NaviAppEnum.tmap
  );

  useEffect(() => {
    setMethod(naviMap || NaviAppEnum.tmap);
  }, [naviMap]);

  const saveMap = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ key: "naviapp", method })
      );
    }
    dispatch(updateNaviMap(method));
    showMessageWithTitle(
      t("profile.map.popup.title"),
      t("profile.map.popup.content"),
      t("profile.map.popup.btn.ok"),
      () => {
        history.goBack();
      }
    );
  };

  const handleChangeMethod = (e: any) => {
    const value = e.target.value;
    setMethod(value);
  };

  return (
    <LeftTitledTemplate
      header={t("profile.map")}
      classname={"select-navi-container"}
    >
      <div className={"notify-para"}>
        <div className={"notify-title body1 bold"}>
          {t("profile.map.label")}
        </div>
      </div>
      <Radio.Group
        className={"column profile-round-cntr"}
        onChange={handleChangeMethod}
        value={method}
      >
        <Radio value={"tmap"} className={"small bold black85 " + styles.radio}>
          {t("tmap.connect")}
        </Radio>
        <div className={"profile-divider"} />
        <Radio value={"kakao"} className={"small bold black85 " + styles.radio}>
          {t("kakao.connect")}
        </Radio>
        <div className={"profile-divider"} />
        <Radio value={"naver"} className={"small bold black85 " + styles.radio}>
          {t("naver.connect")}
        </Radio>
      </Radio.Group>
      <Button
        type={"primary"}
        className={"bottom-btn"}
        data-cy={"addKakao"}
        onClick={saveMap}
      >
        {t("profile.map.btn")}
      </Button>
    </LeftTitledTemplate>
  );
};

export default SelectNaviContainer;
