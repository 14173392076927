import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  collectReturnItem,
  createDamagedInvoice,
} from "../../../api/shippingApi";
import { showMessage, showMessageWithTitle } from "../../../fns/message";
import Scanner from "../../molecules/scanner/Scanner";
import styles from "../../molecules/scanner/scanner.module.css";
import { Button } from "antd";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import { CreateDamagedInvoiceApiResponse } from "../../../api/apiTypes";
import Drawer from "antd/lib/drawer";
import { useDispatch } from "react-redux";
import { TokenStorage } from "../../../services/token.service";

interface Params {
  tracking: string;
  delivery_uuid: string;
  onCompleted: () => void;
}

const ReturnScanDrawer = forwardRef(
  ({ tracking, onCompleted, delivery_uuid }: Params, ref) => {
    const { t } = useTranslation(["scan"]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    const [visible, setVisible] = useState(false);

    useImperativeHandle(ref, () => ({
      show: () => {
        show();
      },
    }));

    const show = () => {
      setVisible(true);
    };

    const hide = () => {
      setVisible(false);
    };

    const handleDamagedInvoice = () => {
      createDamagedInvoice(tracking, onDamagedSuccess);
    };

    const onDamagedSuccess = (res: CreateDamagedInvoiceApiResponse) => {
      collectReturnItem(
        //collect invoice POST 하고 POST shipping complete도 보내줘야해요 by kokospapa
        tracking,
        () => {
          showMessageWithTitle(
            t("popup.return.info.title"),
            <span className={styles.damagePopupContent}>{res.identifier}</span>,
            t("btn.ok"),
            onCompleted
          );
        },
        {}
      );
    };

    const onCompleteSuccess = () => {
      showMessage(t("success.popup.product.returned"));
      hide();
      onCompleted();
    };

    const onCompleteError = () => {
      setLoading(false);
    };

    const onScan = useCallback(
      (res: string | null) => {
        const access = TokenStorage.getToken();
        if (res && !loading) {
          if (res === tracking && access) {
            collectReturnItem(
              //collect invoice POST 하고 POST shipping complete도 보내줘야해요 by kokospapa
              res,
              onCompleteSuccess,
              {},
              onCompleteError
            );
          } else {
            setLoading(false);
            showError(t("error.popup.tracking.diff"));
            showMessage(t("error.popup.tracking.diff"));
          }
        }
      },
      [loading, tracking]
    );

    const onScanError = useCallback(
      (err: any) => {
        console.log("onScanError", err);
        if (error === undefined) {
          showError("scanner");
        }
      },
      [error]
    );

    const showError = (error: string) => {
      setError(error);
      setTimeout(() => {
        setError(undefined);
      }, 5000);
    };

    return (
      <Drawer
        closable={false}
        mask={false}
        closeIcon={<div />}
        onClose={hide}
        visible={visible}
        placement={"bottom"}
        key={"delivery-complete"}
        height={window.innerHeight}
        bodyStyle={{ backgroundColor: "#f3f3f3" }}
      >
        <LeftTitledTemplate
          header={t("return.title")}
          headerProps={{ onLeft: hide }}
          classname={styles.scannerContainer}
        >
          <div className={" column " + styles.scannerTopInfo}>
            <span className={styles.scannerErrorTitle + " error"}>
              {error && t("return.error.title.scanner")}
            </span>
            <span className={styles.scannerError + " title white"}>
              {error &&
                (error === "scanner"
                  ? t("return.error.subtitle.scanner")
                  : error)}
            </span>
          </div>
          <Scanner onScan={onScan} onError={onScanError} />
          <div className={" column align-center " + styles.scannerBottomInfo}>
            <Button
              type={"primary"}
              className={styles.btnDamaged}
              onClick={handleDamagedInvoice}
            >
              {t("return.btn.damaged")}
            </Button>
          </div>
        </LeftTitledTemplate>
      </Drawer>
    );
  }
);

export default ReturnScanDrawer;
