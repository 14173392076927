import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./shipping.css";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import {
  AddressType,
  DeliveryCompleteOn,
  GetDeliveryDetailApiResponse,
  PhotoType,
  ReasonDeliveryCompleteChanged,
  ReasonDeliveryIncomplete,
  ShippingDetailApiResponse,
  ShippingItemType,
  UploadEvent,
} from "../../../api/apiTypes";
import { useTranslation } from "react-i18next";
import Input from "antd/lib/input";
import Radio from "antd/lib/radio";
import Button from "antd/lib/button";
import Copy from "../../../assets/svgs/copy.svg";
import copy from "copy-to-clipboard";
import { showMessage, showMessageWithTitle } from "../../../fns/message";
import ShippingPhoto from "./ShippingPhoto";
import { passwordRegex } from "../../../common/regex";
import { getShippingItem } from "../../../api/shippingApi";
import ShippingIncompleteDrawer from "./ShippingIncompleteDrawer";
import Drawer from "antd/lib/drawer";
import PopupSpinner from "../../molecules/spinner/PopupSpinnerView";
import { AxiosError } from "axios";
import {
  IndexDBErrorType,
  uploadShippingBG,
} from "../../../services/indexdb.service";
import { useDispatch } from "react-redux";
import { TokenStorage } from "../../../services/token.service";

interface Props extends ShippingItemType {
  photos?: PhotoType[];
  onCompleted: () => void;
  delivery_uuid: string;
  address?: AddressType;
}

const ShippingCompleteDrawer = forwardRef(
  (
    {
      alias,
      status,
      tracking_number,
      extra_memo,
      delivery_info,
      photo,
      photos,
      onCompleted,
      delivery_uuid,
      address,
    }: Props,
    ref
  ) => {
    const { t } = useTranslation("delivery");
    const dispatch = useDispatch();
    const [otherMethod, setOtherMethod] = useState("");
    const [loading, setLoading] = useState(false);
    const imageRef = useRef<File>();
    const boxRef = useRef(delivery_info?.delivery_box_number);
    const boxPassRef = useRef(delivery_info?.delivery_box_password);
    const reasonIncomplete = useRef<ReasonDeliveryIncomplete>();
    const incompleteRef = useRef<any>();
    const [detail, setDetail] = useState<ShippingDetailApiResponse>();
    const [reason, setReason] = useState<ReasonDeliveryCompleteChanged>();
    const [method, setMethod] = useState<DeliveryCompleteOn>("DOOR");
    const [visible, setVisible] = useState(false);
    const [uploading, setUploading] = useState(0);

    useImperativeHandle(ref, () => ({
      show,
    }));

    const show = () => {
      setVisible(true);
      getShippingItem(tracking_number, (res) => {
        console.log("getShippingItem", res);
        setDetail(res);
        if (res?.reason_delivery_change) {
          setMethod(res?.delivery_complete_on);
        }
        setReason(res?.reason_delivery_change);
        reasonIncomplete.current = res?.reason_delivery_incomplete;
      });
    };

    const hide = () => {
      setVisible(false);
    };

    const showIncomplete = () => {
      incompleteRef.current.show(reasonIncomplete.current);
    };

    const handleCopy = () => {
      document.execCommand("copy", true, tracking_number);
      copy(tracking_number);
      showMessage(t("copy.reg.no", { regno: tracking_number }));
    };

    const handleChangeReason = (e: any) => {
      setReason(e.target.value);
    };

    const handleChangeOther = (e: any) => {
      setOtherMethod(e.target.value);
    };

    const handleChangeMethod = (e: any) => {
      const value = e.target.value;
      if (value === "DOOR") {
        setReason(undefined);
      }
      setMethod(value);
    };

    const handleChangeBox = (e: any | { target: { value: string } }) => {
      boxRef.current = e.target.value;
    };

    const handleChangeBoxPass = (e: any | { target: { value: string } }) => {
      boxPassRef.current = e.target.value;
    };

    const handleChangeBoxInfo = (e: any | { target: { value: string } }) => {
      setOtherMethod(e.target.value);
    };

    const handleSelectPhoto = (image: File) => {
      imageRef.current = image;
    };

    const onCompleteSuccess = (data: GetDeliveryDetailApiResponse) => {
      setLoading(false);
      showMessage(t("popup.delivery.completed"));
      hide();
      onCompleted();
    };

    const onIncompleteSuccess = () => {
      hide();
      onCompleted();
    };

    const onCompleteError = (type: IndexDBErrorType, e: AxiosError) => {
      console.log("oncompleteerror", type);
      setLoading(false);
      if (type === IndexDBErrorType.offline) {
        hide();
      }
    };

    const onUploadProgress = (event: UploadEvent) => {
      console.log("onUploadProgress ", event);
      setUploading((event.loaded * 100) / event.total);
    };

    const postComplete = () => {
      if (!loading) {
        const access = TokenStorage.getToken();
        setLoading(true);
        access &&
          uploadShippingBG(
            tracking_number,
            access,
            delivery_uuid,
            onCompleteSuccess,
            {
              delivery_complete_on: method,
              reason_delivery_change: reason,
              photo: imageRef.current,
              delivery_complete_on_info: otherMethod,
              delivery_box_password: boxPassRef.current,
              delivery_box_number: boxRef.current,
            },
            onCompleteError,
            dispatch,
            t,
            onUploadProgress
          );
      }
    };

    const handleComplete = (e: any) => {
      if (!imageRef.current && !photo) {
        showMessage(t("complete.error.noimage"));
      } else {
        if (method.endsWith("BOX")) {
          //택배함 선택시
          if (!boxRef.current) {
            showMessage(t("popup.error.post.code.empty"));
            return;
          } else if (!passwordRegex.test(boxRef.current)) {
            showMessage(t("popup.error.code.invalid"));
            return;
          }
        } else if (method.endsWith("OTHER")) {
          if (!otherMethod) {
            showMessage(t("popup.error.other.empty"));
            return;
          }
        }
        showMessageWithTitle(
          t("popup.shipping.check.title"),
          t("popup.shipping.check.content", {
            name: address?.name,
            address1: address?.address1 || address?.address_jibun,
            address2: address?.address2,
          }),
          t("popup.shipping.check.btn.ok"),
          postComplete,
          t("popup.shipping.check.btn.cancel")
        );
      }
    };

    const infoNode = (
      <div className={"delivery-detail-inner"}>
        <div className={"delivery-detail-row"}>
          <div className={"subtitle bold black85 nowrap"}>{alias}</div>
        </div>
        <div className={"delivery-detail-row"}>
          <div className={"delivery-detail-col"}>
            <div className={"small bold black45"}>{t("reg.no")}</div>
            <span className={"subtitle bold black85 nowrap"}>
              {tracking_number}
            </span>
          </div>
          <Button
            type={"text"}
            shape={"circle"}
            className={"delivery-icon"}
            onClick={handleCopy}
          >
            <img src={Copy} />
          </Button>
        </div>
        <div className={"delivery-detail-row"}>
          <div className={"delivery-detail-col"}>
            <div className={"small bold black45"}>{t("req")}</div>
            <div className={"body bold black85 nowrap"}>{extra_memo}</div>
          </div>
        </div>

        {delivery_info && (
          <div className={"delivery-detail-row"}>
            <div className={"delivery-detail-col"}>
              <div className={"small primary bold black45"}>
                {t("item.method")}
              </div>
              <div className={"body1 bold black85 nowrap"}>
                {t(delivery_info?.delivery_complete_on)}
                {delivery_info?.delivery_complete_on === "OTHER" &&
                  "\u00a0" + delivery_info?.delivery_complete_on_info}
              </div>
            </div>
          </div>
        )}
      </div>
    );

    const methodNode = (
      <>
        <Radio
          checked={method === "DOOR"}
          value={"DOOR"}
          onChange={handleChangeMethod}
          className={"body bold delivery-radio-title"}
        >
          {t("complete.front.door")}
        </Radio>
        <Radio
          checked={method !== "DOOR"}
          value={"DIRECT"}
          onChange={handleChangeMethod}
          className={"body bold delivery-radio-title"}
        >
          {t("complete.change.place")}
        </Radio>

        <div className={"delivery-detail-inner"}>
          <Radio.Group
            className={"column"}
            onChange={handleChangeMethod}
            value={method}
          >
            <div className={"delivery-method-value"}>
              <Radio
                value={"DIRECT"}
                className={"body2 bold delivery-method-radio"}
              >
                {t("DIRECT")}
              </Radio>
            </div>
            <div className={"delivery-method-value  border-top"}>
              <div className={"row space-between"}>
                <Radio
                  value={"DELIVERYBOX"}
                  className={"body2 bold delivery-method-radio"}
                >
                  {t("DELIVERYBOX")}
                </Radio>
                {method === "DELIVERYBOX" &&
                  (!!delivery_info?.delivery_box_number ||
                    !!delivery_info?.delivery_box_password) && (
                    <div className={"row small1 black45 delivery-loc-entry"}>
                      {t("loc.entry")}
                      {!!delivery_info?.delivery_box_number && (
                        <span>{delivery_info?.delivery_box_number + "번"}</span>
                      )}
                      {!!delivery_info?.delivery_box_number &&
                        !!delivery_info?.delivery_box_password && (
                          <span>{t("count.divider")}</span>
                        )}
                      {!!delivery_info?.delivery_box_password && (
                        <span>{t(delivery_info?.delivery_box_password)}</span>
                      )}
                    </div>
                  )}
              </div>
              {method === "DELIVERYBOX" && (
                <div className={"column"}>
                  <div className={"row"}>
                    <Input
                      inputMode="numeric"
                      maxLength={20}
                      defaultValue={""}
                      autoComplete={"off"}
                      className={"delivery-input inputCode"}
                      placeholder={t("loc.number.place")}
                      onChange={handleChangeBox}
                    />
                    <Input
                      defaultValue={""}
                      maxLength={20}
                      autoComplete={"off"}
                      className={"delivery-input inputPass"}
                      placeholder={t("loc.entry.code.place")}
                      onChange={handleChangeBoxPass}
                    />
                  </div>
                  <Input
                    defaultValue={""}
                    maxLength={20}
                    autoComplete={"off"}
                    className={"delivery-input inputInfo"}
                    placeholder={t("loc.entry.code.info.place")}
                    onChange={handleChangeBoxInfo}
                  />
                </div>
              )}
            </div>
            <div className={"delivery-method-value  border-top"}>
              <Radio
                value={"GUARD"}
                className={"body2 bold delivery-method-radio"}
              >
                {t("GUARD")}
              </Radio>
            </div>
            <div className={"delivery-method-value  border-top"}>
              <Radio
                value={"OTHER"}
                className={"body2 bold delivery-method-radio"}
              >
                {t("OTHER")}
              </Radio>
              {method === "OTHER" && (
                <Input
                  defaultValue={""}
                  autoComplete={"off"}
                  maxLength={24}
                  className={"delivery-input"}
                  placeholder={t("input.complete.other")}
                  onChange={handleChangeOther}
                />
              )}
            </div>
          </Radio.Group>
          {method !== "DOOR" && (
            <Radio.Group
              className={"column"}
              onChange={handleChangeReason}
              value={reason}
            >
              <div className={"delivery-method-value border-top"}>
                <div className={"small bold black45"}>
                  {t("complete.reason.method")}
                </div>
                <Radio
                  value={"THEFT"}
                  className={"body2 bold delivery-method-radio"}
                >
                  {t("THEFT")}
                </Radio>
              </div>

              <div className={"delivery-method-value  border-top"}>
                <Radio
                  value={"INACCESIBLE"}
                  className={"body2 bold delivery-method-radio"}
                >
                  {t("INACCESIBLE")}
                </Radio>
              </div>

              <div className={"delivery-method-value  border-top"}>
                <Radio
                  value={"CUSTOMER_REQUEST"}
                  className={"body2 bold delivery-method-radio"}
                >
                  {t("CUSTOMER_REQUEST")}
                </Radio>
              </div>
            </Radio.Group>
          )}
        </div>
      </>
    );

    const buttonNode = (
      <div className={"delivery-button-container"}>
        <Button
          type={"primary"}
          className={"btn-b delivery-button-cancel"}
          onClick={showIncomplete}
        >
          {t("undone.delivery.btn")}
        </Button>
        <Button
          type={"primary"}
          className={"delivery-button-complete"}
          onClick={handleComplete}
        >
          {detail?.retry
            ? t("btn.again.delivery")
            : uploading
            ? t("btn.doing.delivery", { percent: uploading })
            : t("btn.done.delivery")}
        </Button>
      </div>
    );

    return (
      <Drawer
        closable={false}
        mask={false}
        closeIcon={<div />}
        onClose={hide}
        visible={visible}
        placement={"bottom"}
        key={"delivery-complete"}
        height={window.innerHeight}
        bodyStyle={{ backgroundColor: "#f3f3f3" }}
      >
        {visible && (
          <LeftTitledTemplate
            header={t("complete.header")}
            classname={"delivery-detail-container"}
            headerProps={{ onLeft: hide }}
          >
            {infoNode}
            <ShippingPhoto
              photo={photo}
              photos={photos}
              onSelect={handleSelectPhoto}
              disabled={method !== "DOOR"}
            />
            {methodNode}
            {buttonNode}
            <ShippingIncompleteDrawer
              ref={incompleteRef}
              is_return={"delivery"}
              tracking_number={tracking_number}
              delivery_uuid={delivery_uuid}
              onCompleted={onIncompleteSuccess}
            />
            {loading && <PopupSpinner />}
          </LeftTitledTemplate>
        )}
      </Drawer>
    );
  }
);

export default ShippingCompleteDrawer;
