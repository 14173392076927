import Button from "antd/lib/button";
import React from "react";
import "./install.style.css";
import Home from "../../../assets/svgs/home.svg";
import { isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { GUIDE_LINK } from "../../../common/consts.common";

type Props = {
  onIOS: () => void;
  onIOSMouseLeave: () => void;
};
const InstallButton = ({ onIOS, onIOSMouseLeave }: Props) => {
  const { t } = useTranslation(["profile"]);
  const prompt = window.install;

  const handleMouseLeave = () => {
    onIOSMouseLeave();
  };

  const handleInstall = () => {
    if (isSafari) {
      onIOS();
    } else {
      prompt.prompt();
    }
  };

  if (!window.installed && !prompt && !isSafari) return null;

  return (
    <Button
      onMouseLeave={handleMouseLeave}
      data-cy={"installBtn"}
      onClick={handleInstall}
      className={"install-btn"}
      disabled={window.installed}
    >
      <img src={Home} className={"install-logo"} />
      <div className={"body bold install-text"}>
        {window.installed ? t("profile.installed") : t("profile.install")}
      </div>
    </Button>
  );
};

export default InstallButton;
