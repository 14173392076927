import React from "react";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, {
  BugsnagPluginReactResult,
} from "@bugsnag/plugin-react";
import "./index.css";
import "antd/dist/antd.css";
import "@delivus/react-toggle-button/dist/esm/index.css";
import "@delivus/react-infinite-list/dist/esm/index.css";
import "@delivus/daas-naver-map/dist/esm/index.css";
import "./locales/i18n";
import App from "./App";
import store from "./reducers/store";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { onSuccess, onUpdate } from "./registrationStatus";
import { Provider } from "react-redux";

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY || "",
  plugins: [new BugsnagPluginReact()],
});

const plugin = Bugsnag.getPlugin("react") as BugsnagPluginReactResult;
const ErrorBoundary = plugin.createErrorBoundary(React);
ReactDOM.render(
  <ErrorBoundary>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </ErrorBoundary>,
  document.getElementById("root")
);

// @ts-ignore
if (window.Cypress) {
  // @ts-ignore
  window.store = store;
}

serviceWorkerRegistration.register({
  onUpdate,
  onSuccess,
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
