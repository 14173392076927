import React from "react";
import "./sector.style.css";

import Button from "antd/lib/button";
import Minus from "../../../assets/svgs/minus-circle";
import { SectorType } from "../../../api/apiTypes";

type Props = {
  sector: SectorType;
  onDeleteSector: (sector: SectorType) => void;
};
const SectorItem = ({ sector, onDeleteSector }: Props) => {
  const onDelete = () => {
    if (onDeleteSector) {
      onDeleteSector(sector);
    }
  };

  return (
    <div className={"sector-item-container"}>
      <div className={"sector-item-label body1"}>{sector.name}</div>
      <Button
        data-cy={"sectorDelete"}
        className={"sector-item-btn"}
        shape="circle"
        onClick={onDelete}
      >
        <Minus />
      </Button>
    </div>
  );
};

export default SectorItem;
