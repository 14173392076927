import React from "react";
import styles from "./emptyContent.module.css";

type Props = {
  title?: string;
  label?: string;
};
const EmptyContent = ({ title, label }: Props) => {
  return (
    <div className={styles.container}>
      {title && <div className={"body1 black85"}>{title}</div>}
      <div className={"body black45 " + styles.label}>{label}</div>
    </div>
  );
};

export default EmptyContent;
