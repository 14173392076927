import React, { useCallback } from "react";
import styles from "./deliveriesInTab.module.css";
import { useTranslation } from "react-i18next";
import { AddressType, DeliveryListItemType } from "../../../api/apiTypes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import { useHistory } from "react-router-dom";
import CurrentDeliveryItem from "../../organisms/currentDelivery/CurrentDeliveryItem";
import Button from "antd/lib/button";
import { updateDeliveryStarted } from "../../../reducers/commonReducer";
import EmptyContent from "../../organisms/emptyContent/EmptyContent";

interface Props {
  data?: DeliveryListItemType[];
  label?: string;
  title?: string;
  index: number;
  sortBy?: string;
  onChangeSort: (sort: string) => void;
}

const DeliveriesInTab = ({
  data,
  index,
  label,
  title,
  sortBy,
  onChangeSort,
}: Props) => {
  const { t } = useTranslation(["delivery"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const { count_shipping_completed, deliveryStarted } = useSelector(
    (state: RootState) => state.common
  );

  const showStartButton = !deliveryStarted && count_shipping_completed <= 0;

  const navigateMap = useCallback((address: AddressType, uuid?: string) => {
    history.push(`/home/${uuid}/delivery`);
  }, []);

  const handleStart = () => {
    dispatch(updateDeliveryStarted(true));
    data && data[0]?.address != undefined && history.push("/home/");
  };

  return (
    <div className={showStartButton ? styles.listContent : ""}>
      <div className={"row space-between order-row"}>
        <div className={"body1 bold"}>{title && t(title)}</div>
        {/*{index === 0 && sortNode}*/}
      </div>
      {data && data.length > 0 ? (
        <>
          {data.map((item, idx) => (
            <CurrentDeliveryItem
              key={"curdelivery" + idx}
              onMap={navigateMap}
              {...item}
              index={index}
            />
          ))}
          {showStartButton && index === 0 && (
            <Button
              type={"primary"}
              className={styles.bottomBtn}
              onClick={handleStart}
            >
              {t("delivery.btn.start")}
            </Button>
          )}
        </>
      ) : (
        <EmptyContent
          title={t(label + ".empty.title")}
          label={t(label + ".empty.content")}
        />
      )}
    </div>
  );
};

export default DeliveriesInTab;
