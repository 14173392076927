import React, { useMemo, useState } from "react";
import styles from "./shippingInContainerScreen.module.css";
import { useTranslation } from "react-i18next";
import { DeliveryHistoryItemType } from "../../../api/apiTypes";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import ShippingInContainerItem from "../../organisms/shippingInContainer/ShippingInContainerItem";
import EmptyContent from "../../organisms/emptyContent/EmptyContent";
import { showApiError } from "../../../fns/message";
import http from "../../../services/axios.service";
import { TokenStorage } from "../../../services/token.service";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import { InfiniteList } from "@delivus/react-infinite-list";
import { Space } from "antd";

interface Params {
  params: {
    uuid: string;
    box: string;
    unit: string;
  };
}
const ShippingInContainerScreen = ({ match }: { match: Params }) => {
  const { uuid, unit, box } = match.params; // selected order uuid
  const { t } = useTranslation(["delivery"]);
  const [refresh] = useState(false);
  const title = useMemo(() => unit + t("count.divider") + box, []);
  const { type } = useSelector((state: RootState) => state.profile);

  const handleListFetchError = (error: any) => {
    showApiError(error);
  };

  return (
    <LeftTitledTemplate
      header={title}
      data-cy={"shipping"}
      title={title}
      classname={styles.container}
    >
      <InfiniteList
        url={`/flex/containers/${uuid}/shippingitems/`}
        refresh={refresh}
        http={http as any}
        authenticated={!!type}
        // headers={TokenStorage.getAuthentication().headers}
        onApiFailed={handleListFetchError}
        height={"calc(100vh - 205px)"}
      >
        {(count?: number, results?: DeliveryHistoryItemType[]) => (
          <Space
            direction={"vertical"}
            className={styles.list}
            split={<div className={styles.divider} />}
          >
            {results && results?.length > 0 ? (
              results.map((order, index) => (
                <ShippingInContainerItem key={"delivery" + index} {...order} />
              ))
            ) : (
              <EmptyContent label={t("empty.history")} />
            )}
          </Space>
        )}
      </InfiniteList>
    </LeftTitledTemplate>
  );
};

export default ShippingInContainerScreen;
