import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./timer.css";
import { differenceInSeconds } from "date-fns";

type Props = {
  ref: any;
  children?: (sec: number, min: number) => ReactNode;
  expireDate?: Date;
  hoursMinSecs?: {
    minutes?: number;
    seconds?: number;
  };
  onEnd?: () => void;
};
const Timer = forwardRef(
  ({ hoursMinSecs, onEnd, children, expireDate }: Props, ref) => {
    const endCallefRef = useRef(false);
    const [[mins, secs], setTime] = useState([
      hoursMinSecs?.minutes || 0,
      hoursMinSecs?.seconds || 0,
    ]);

    useImperativeHandle(
      ref,
      () => ({
        reset: (endDate?: Date) => {
          reset(endDate);
        },
      }),
      []
    );

    const tick = () => {
      if (mins < 1 && secs < 1) {
        if (!endCallefRef.current) {
          !!onEnd && onEnd();
          endCallefRef.current = true;
        }
      } else if (secs === 0 && mins > 0) {
        setTime([mins - 1, 59]);
      } else if (secs > 0) {
        setTime([mins, secs - 1]);
      }
    };

    const reset = useCallback(
      (expireDate?: Date) => {
        let minutes = 0;
        let seconds = 0;
        if (!!expireDate) {
          const now = new Date();
          const totalSec = differenceInSeconds(expireDate, now);
          if (totalSec > 0) {
            minutes = Math.floor(totalSec / 60);
            seconds = totalSec % 60;
          }
        } else if (hoursMinSecs) {
          minutes = hoursMinSecs.minutes || 0;
          seconds = hoursMinSecs.seconds || 0;
        }
        console.log("timer reset", minutes, seconds, expireDate);
        setTime([minutes, seconds]);
      },
      [hoursMinSecs]
    );

    useEffect(() => {
      console.log("timer useeffect", expireDate);
      reset(expireDate);
    }, [reset, expireDate]);

    useEffect(() => {
      const timerId = setInterval(() => tick(), 1000);
      return () => clearInterval(timerId);
    }, [mins, secs]);

    return (
      <>
        {children ? (
          children(secs, mins)
        ) : (
          <div className={"timer-text body"}>
            <p>{`${mins.toString().padStart(2, "0")}:${secs
              .toString()
              .padStart(2, "0")}`}</p>
          </div>
        )}
      </>
    );
  }
);

export default Timer;
