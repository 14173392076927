import React, { useCallback, useEffect, useState } from "react";
import { List, ListRowProps } from "react-virtualized";
import { ContainerType } from "../../api/apiTypes";
import { useHistory } from "react-router-dom";
import ContainerItem from "./container/ContainerItem";

interface Props {
  currentPosition?: GeolocationPosition;
  order_status?: "return" | "finished";
  data: ContainerType[];
  index: number;
  label?: string;
  title?: string;
  onRefresh: () => void;
  onClaimDone: () => void;
  onUnclaimDone: () => void;
}

const ROW_HEIGHT = 205;
const PADDING = 20;
const OrderItemsInTab = ({
  currentPosition,
  order_status,
  data,
  onRefresh,
  onClaimDone,
  onUnclaimDone,
}: Props) => {
  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth - PADDING - PADDING);

  const resizeScreen = () => {
    setWidth(window.innerWidth - PADDING - PADDING);
    return;
  };

  useEffect(() => {
    resizeScreen();
    window.addEventListener("resize", resizeScreen);
    return () => {
      window.removeEventListener("resize", resizeScreen);
    };
  }, [resizeScreen]);

  const navigateMap = useCallback((uuid: string) => {
    history.push(`/home/${uuid}/order`);
  }, []);

  const navigateMapPreview = useCallback((uuid: string) => {
    history.push(`/home/${uuid}/order/true`);
  }, []);

  function rowRenderer({
    key, // Unique key within array of rows
    index, // Index of row within collection
    style, // Style object to be applied to row (to position it)
  }: ListRowProps) {
    if (data) {
      const order = data[index];
      return (
        <div key={key} style={style}>
          <ContainerItem
            key={"order" + index}
            {...order}
            index={index}
            currentPosition={currentPosition}
            onMap={navigateMap}
            order_status={order_status}
            onPreview={navigateMapPreview}
            onClaimDone={onClaimDone}
            onUnclaimDone={onUnclaimDone}
            onRefresh={onRefresh}
          />
        </div>
      );
    }
  }

  return (
    <List
      width={width}
      data-cy={"listContainer"}
      // className={classes.list}
      height={ROW_HEIGHT * data.length}
      rowCount={data.length}
      rowHeight={ROW_HEIGHT}
      rowRenderer={rowRenderer}
    />
  );
};

export default OrderItemsInTab;
