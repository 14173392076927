import React, { useCallback } from "react";
import "./containerItem.style.css";
import Button from "antd/lib/button";
import { Trans, useTranslation } from "react-i18next";
import { BoxType, ShippingBoxStatus } from "../../../api/apiTypes";
import { useHistory } from "react-router-dom";
import { pickupBox } from "../../../api/shippingApi";
import { showApiError, showMessageWithTitle } from "../../../fns/message";
import EyesSvg from "../../../assets/svgs/eyes.svg";

interface Props {
  order_status?: "return" | "finished";
  status: ShippingBoxStatus;
  box?: BoxType;
  pickup_only: boolean;
  unit_name?: string;
  uuid: string;
  count_box: number;
  count_total_items: number;
  onPreview?: (uuid: string) => void;
}
const ContainerMiddleBtn = ({
  order_status,
  status,
  box,
  count_box,
  count_total_items,
  pickup_only,
  unit_name,
  uuid,
  onPreview,
}: Props) => {
  const { t } = useTranslation(["order"]);
  const history = useHistory();

  const handlePickup = () => {
    if (box) pickupBox(box?.uuid, false, showPickedupReturn, showApiError);
  };

  const handleNavigateScan = () => {
    history.push(`/scan/container/`);
  };

  const navigateDelivery = useCallback(() => {
    history.replace("/home/delivery/");
  }, []);

  const handlePreview = () => {
    !!onPreview && onPreview(uuid);
  };

  const navigateDeliveryHistory = () => {
    history.push(`/delivery/recentlist/${uuid}/${box?.alias}/${unit_name}`);
  };

  const showPickedupReturn = () => {
    showMessageWithTitle(
      t("popup.pickedup.return.title"),
      <Trans
        className={"body grey92"}
        i18nKey={"popup.pickedup.return.content"}
        values={{
          cnt: count_total_items,
        }}
        ns={"order"}
        components={{ dark: <span className={"bold info"} /> }}
      />,
      t("popup.pickedup.return.btn"),
      navigateDelivery
    );
  };

  if (status === "CLAIMED") {
    if (pickup_only) {
      //if pickup only, dont have to scan box
      return (
        <Button
          type={"text"}
          className={"body bold flex order-item-btn errorActive "}
          onClick={handlePickup}
        >
          {t("btn.claimed.start")}
        </Button>
      );
    }
    return (
      <Button
        type={"text"}
        className={"body bold flex order-item-btn errorActive"}
        onClick={handleNavigateScan}
      >
        {t("btn.claimed.scan", { count: count_box })}
      </Button>
    );
  }
  if (status === "UNITARRIVED") {
    return (
      <Button
        type={"text"}
        className={"body bold flex order-item-btn "}
        onClick={handlePreview}
      >
        {t("btn.count.delivery", { count: count_total_items })}
        <img src={EyesSvg} className={"order-item-eyes"} />
      </Button>
    );
  }

  console.log("container middle", order_status);
  return (
    <Button
      type={"text"}
      className={
        "body bold flex order-item-btn " +
        (order_status === "finished" ? "black45" : " black85")
      }
      onClick={navigateDeliveryHistory}
    >
      {t("btn.count.delivered", { count: count_total_items })}
    </Button>
  );
};

export default React.memo(ContainerMiddleBtn);
