import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { showApiError, showMessageWithTitle } from "../../fns/message";
import { CompleteShippingApiParams } from "../../api/apiTypes";
import { addKakaoChannel } from "../../services/kakao.service";
import {
  IndexDBErrorType,
  uploadShippingBG,
} from "../../services/indexdb.service";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers/reducer";
import { TokenStorage } from "../../services/token.service";

type Props = {
  onComplete: () => void;
  tracking_number: string;
  delivery_uuid: string;
};

const ReDeliveryPopup = forwardRef(
  ({ onComplete, tracking_number, delivery_uuid }: Props, ref) => {
    const { t } = useTranslation(["delivery"]);
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const incompleteDataRef = useRef<CompleteShippingApiParams>();
    const access = TokenStorage.getToken();
    let loading = false;

    useImperativeHandle(
      ref,
      () => ({
        show: (incompleteData: CompleteShippingApiParams) => {
          setVisible(true);
          incompleteDataRef.current = incompleteData;
        },
      }),
      []
    );

    const hide = () => {
      setVisible(false);
    };

    const redelivery = () => {
      if (!loading && access) {
        loading = true;
        uploadShippingBG(
          tracking_number,
          access,
          delivery_uuid,
          onRedeliverySuccess,
          { ...incompleteDataRef.current, retry: true },
          onCompleteError,
          dispatch,
          t
        );
      }
    };

    const giveup = () => {
      if (!loading && access && incompleteDataRef.current) {
        loading = true;
        uploadShippingBG(
          tracking_number,
          access,
          delivery_uuid,
          onGiveupSuccess,
          incompleteDataRef.current,
          onCompleteError,
          dispatch,
          t
        );
      }
    };

    const onRedeliverySuccess = () => {
      showMessageWithTitle(
        t(`popup.delivery.incompleted.title`),
        t("popup.delivery.incompleted.redelivery"),
        t("popup.delivery.incompleted.btn.ok"),
        hide
      );
      loading = false;
      onComplete();
    };

    const onGiveupSuccess = () => {
      loading = false;
      showMessageWithTitle(
        t(`popup.delivery.incompleted.title`),
        t("popup.delivery.incompleted.giveup"),
        t("popup.delivery.incompleted.btn.ok"),
        hide
      );
      onComplete();
    };

    const onCompleteError = (type: IndexDBErrorType, e: any) => {
      loading = false;
      if (type === IndexDBErrorType.offline) {
        hide();
      } else {
        setVisible(false);
      }
    };
    const footerNode = (
      <div className={"column"}>
        <Button
          className={"popup-btn height ok border"}
          type={"text"}
          data-cy={"navigateHelmet"}
          onClick={redelivery}
        >
          {t("popup.delivery.incompleted.btn.redelivery")}
        </Button>

        <Button
          className={"popup-btn height ok border"}
          type={"text"}
          data-cy={"navigateHelmet"}
          onClick={giveup}
        >
          {t("popup.delivery.incompleted.btn.giveup")}
        </Button>

        <Button
          className={"popup-btn height ok border"}
          type={"text"}
          data-cy={"navigateHelmet"}
          onClick={addKakaoChannel}
        >
          {t("popup.delivery.incompleted.btn.ask")}
        </Button>
      </div>
    );

    return (
      <Modal
        closable={false}
        visible={visible}
        centered
        onCancel={hide}
        footer={footerNode}
      >
        <div className={"column align-center"}>
          <div className={"popup-title"}>
            {t("popup.delivery.incompleted.title")}
          </div>
          <div className={"body popup-message helmet-popup-content"}>
            {t("popup.delivery.incompleted.content")}
          </div>
        </div>
      </Modal>
    );
  }
);

export default ReDeliveryPopup;
