import React from "react";
import styles from "./deliveryTitle.module.css";
import { useTranslation } from "react-i18next";
import { Space } from "antd";

type Props = {
  totalCount: number;
  shippingCount: number;
  returnCount: number;
  alias?: string;
};

const DeliveryCountTitle = ({ shippingCount, returnCount, alias }: Props) => {
  const { t } = useTranslation("delivery");
  return (
    <div className={styles.rowTitle}>
      <span className={"small bold " + styles.aliasContainer}>{alias}</span>
      <Space split={"|"} className={"row"}>
        <span className={"body medium"}>
          {t("map.shipping.count")}
          <span className={"primary"}>{shippingCount}</span>
        </span>
        <span className={"body medium"}>
          {t("map.return.count")}
          <span className={"errorActive"}>{returnCount}</span>
        </span>
      </Space>
    </div>
  );
};

export default DeliveryCountTitle;
