import React from "react";
import "../login/loginPhoneScreen.css";
import SignupTermView, { Term } from "./SignupTermView";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showApiError, showMessage } from "../../../fns/message";
import { signup } from "../../../api/authApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import { KOREAN_CODE } from "../../../common/consts.common";
import { SignupApiResponse } from "../../../api/apiTypes";
import { deleteObjEmptyValue } from "../../../fns/objectFns";
import { clearProfile, updateProfile } from "../../../reducers/profileReducer";
import { getUser } from "../../../api/userApi";
import { TokenStorage } from "../../../services/token.service";
import {
  clearCommon,
  updateAuthenticated,
} from "../../../reducers/commonReducer";

const SignupTermContainer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(["signup"]);

  const { phonenumber, sector, ...profile } = useSelector(
    (state: RootState) => state.profile
  );

  const navigateNext = ({
    checkedTerm,
    checkedLate,
    checkedLocation,
    checkedPrivacy,
  }: Term) => {
    if (!(phonenumber && profile.res_seq)) {
      showMessage(t("vehicle.popup.identity"));
    } else if (!(checkedTerm && checkedLocation && checkedPrivacy)) {
      showMessage(t("term.popup.required"));
    } else {
      const filtered = deleteObjEmptyValue(profile);
      window.gtag("event", "signup_submit", { method: "form" });
      signup(
        {
          ...filtered,
          sector,
          noti_allow_late: checkedLate,
          phonenumber: KOREAN_CODE + phonenumber,
        },
        onSignupSuccess
      );
    }
  };

  const onSignupSuccess = (res: SignupApiResponse) => {
    TokenStorage.storeToken(res.access);
    TokenStorage.storeRefreshToken(res.refresh);
    dispatch(updateAuthenticated(true));
    dispatch(updateProfile({ ride_type: "" }));
    getUser(
      (pro) => {
        dispatch(updateProfile(pro));
      },
      (err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          TokenStorage.clear();
          dispatch(clearProfile());
          dispatch(clearCommon());
        } else {
          showApiError(err);
        }
      }
    );
    setTimeout(() => {
      history.push("/home/map");
    }, 2000);
  };

  return <SignupTermView onNext={navigateNext} />;
};

export default SignupTermContainer;
