import { isAndroid, isDesktop, isIOS } from "react-device-detect";

export const HEADER_HEIGHT = 50;
export const KOREAN_CODE = "+82";
export const GUIDE_LINK = "https://drabbit.oopy.io/manual";
export const GUIDE_BANK_LINK =
  "https://drabbit.notion.site/cb6b46f661ea430f84c1547ad53646b7";
export const GUIDE_ID_LINK =
  "https://drabbit.notion.site/9759429398ae495e99693190f1922606";
export const TERM_LINK =
  "https://drabbit.notion.site/fcf9a2199a80465297ca317b3e8190bc";
export const TERM_PRIVACY_LINK =
  "https://drabbit.notion.site/c112baf2fb234495ac49b7c14dc6bd04";
export const TERM_LOC_LINK =
  "https://drabbit.notion.site/c899b4346f9a44a38cb28ee2f6d3b08d";
export const TERM_DELIVERY_LINK =
  "https://drabbit.notion.site/BUNNY-0d7125fc5c88489888ee18ab883ad08e";
export const ASK_LINK = "https://drabbit.oopy.io/faq";

export const LOCATION_LINK =
  "https://drabbit.notion.site/BUNNY-38aede4493754e28b46f0a692937d1dc";

export const EDU_LINK = "https://www.safetyedu.net/safetyedu";
export const STORE_DIRECT_LINK = () => {
  if (isAndroid) {
    window.open("https://play.google.com/store/apps/details?id=co.kr.drabbit");
  } else {
    window.open("https://apps.apple.com/us/app/딜리래빗/id6446395560");
  }
};

export const NAVER_DIRECT_LINK = (
  lon: string,
  lat: string,
  address: string,
  slon?: any,
  slat?: any
) => {
  window.location.href = isDesktop
    ? `https://map.naver.com/v5/directions/${slon},${slat},내위치,,/${lon},${lat},${address},,/-/transit?c=14102803.7527127,4496697.2043692,10,0,0,0,dh`
    : `nmap://route/car?slat=${slat}&slng=${slon}&sname=내위치&dlat=${lat}&dlng=${lon}&dname=${address}`;
};

export const KAKAO_DIRECT_LINK = (
  lon: string,
  lat: string,
  address: string,
  slon?: any,
  slat?: any
) => {
  window.location.href = `kakaomap://route?sp=${slat},${slon}&ep=${lat},${lon}&q=${address}&by=CAR`;
};

export const TMAP_DIRECT_LINK = (lon: string, lat: string, address: string) => {
  window.location.href = `tmap://route?goalname=${address}&goalx=${lon}&goaly=${lat}`;
};

export const SORT_PAY = "-expected_price";
export const CONTACT_PHONE = "1522-5298";
export const SORT_ROUTE = "-distance";
export const FILTER_GENERAL_ORDER = "all_order";
export const FILTER_RETURN_ORDER = "pickup_only";

export const messageLink = (tel?: string) => `sms:${tel}`;
export const callLink = (tel?: string) => `tel:${tel}`;
