import React, { useCallback, useRef, useState } from "react";
import styles from "./pickupContainerManualScreen.module.css";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import { Trans, useTranslation } from "react-i18next";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import { ASK_LINK } from "../../../common/consts.common";
import { useHistory } from "react-router-dom";
import PopupSpinner from "../../molecules/spinner/PopupSpinnerView";
import { showApiError, showMessageWithTitle } from "../../../fns/message";
import { getDeliveriesCount, pickupBox } from "../../../api/shippingApi";
import { ContainerPickupResponse } from "../../../api/apiTypes";
import {
  updateDeliveryCount,
  updateDeliveryStarted,
} from "../../../reducers/commonReducer";
import { countDeliveries } from "../../../fns/commonFns";
import { AxiosError } from "axios";
import { useDispatch } from "react-redux";

const PickupContainerManualScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const inputRef = useRef<any>();
  const { t } = useTranslation(["order"]);
  const [loading, setLoading] = useState(false);

  const navigateBack = () => {
    history.goBack();
  };

  const handleFocus = () => {
    if (document.getElementById("fixed") == document.activeElement) {
      // @ts-ignore
      document.getElementById("fixed").class += "absolute";
    }
  };

  const handleBlur = () => {
    if (document.getElementById("fixed") == document.activeElement) {
      // @ts-ignore
      document.getElementById("fixed").class -= "absolute";
    }
  };

  const navigateDelivery = useCallback(() => {
    history.replace("/home/delivery/");
  }, []);

  const handleSubmit = ({ barcode }: { barcode: string }) => {
    if (!barcode && inputRef.current) {
      inputRef.current.focus();
      return;
    }
    handleSendCode(barcode);
  };

  const handleSendCode = (code: string) => {
    setLoading(true);
    showMessageWithTitle(
      t("popup.pickedup.return.title"),
      t("popup.pickup.shipping.content"),
      t("popup.pickedup.return.btn.cancel"),
      () => {
        pickupBox(code || "", true, onPickSuccess, onPickupError);
      },
      t("popup.pickedup.return.btn.ok"),
      () => {
        pickupBox(code || "", false, onPickSuccess, onPickupError);
      }
    );
  };

  const onPickSuccess = (res: ContainerPickupResponse) => {
    setLoading(false);
    showMessageWithTitle(
      t("popup.pickedup.return.title"),
      <Trans
        className={"body grey92"}
        i18nKey={"popup.pickedup.shipping.content"}
        values={{
          cnt: res.count_total_items,
        }}
        ns={"order"}
        components={{ dark: <span className={"bold info"} /> }}
      />,
      t("popup.pickedup.return.btn"),
      navigateDelivery
    );
    getDeliveriesCount((res) => {
      dispatch(updateDeliveryCount(countDeliveries(res.results)));
    });
    dispatch(updateDeliveryStarted(false));
  };

  const onPickupError = (error: AxiosError) => {
    showApiError(error);
    setLoading(false);
  };

  return (
    <LeftTitledTemplate
      header={t("header.manual")}
      title={t("title.manual")}
      subtitle={t("subtitle.manual")}
      headerProps={{ onLeft: navigateBack }}
    >
      <Form
        name="basic"
        labelCol={{ span: 20 }}
        wrapperCol={{ span: 30 }}
        initialValues={{ remember: true }}
        data-cy={"submitBar"}
        onFinish={handleSubmit}
      >
        <Form.Item name="barcode" className={"form-phone"}>
          <Input
            ref={inputRef}
            autoFocus={true}
            autoComplete={"off"}
            data-cy={"barInput"}
            className={"input-phone"}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={t("input.manual")}
          />
        </Form.Item>

        <Form.Item className={"form-get-code"}>
          <Button
            data-cy={"submitOk"}
            type={"primary"}
            className={"btn-get-code"}
            htmlType="submit"
          >
            {t("btn.ok")}
          </Button>
        </Form.Item>
      </Form>
      <Button
        type={"primary"}
        className={"btn-b " + styles.btnAsk}
        data-cy={"openAskLink"}
        onClick={() => window.open(ASK_LINK)}
      >
        {t("btn.ask")}
      </Button>
      {loading && <PopupSpinner />}
    </LeftTitledTemplate>
  );
};

export default PickupContainerManualScreen;
