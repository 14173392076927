import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./returnContainer.css";
import { showMessageWithTitle } from "../../../fns/message";
import { useTranslation } from "react-i18next";
import { ReturnContainerApiResponse } from "../../../api/apiTypes";
import PopupSpinner from "../../molecules/spinner/PopupSpinnerView";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import Drawer from "antd/lib/drawer";
import { returnContainerApi } from "../../../api/shippingApi";

const ReturnContainerDrawer = forwardRef(
  ({ uuid, onReturned }: { uuid: string; onReturned: () => void }, ref) => {
    const { t } = useTranslation(["order"]);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [previewImage, setPreview] = useState<any>();
    const fileRef = useRef<any>();

    useImperativeHandle(ref, () => ({
      show,
    }));

    const show = (file: File) => {
      setVisible(true);
      fileRef.current = file;
      if (!!file) {
        setPreview(URL.createObjectURL(file));
      }
    };

    const hide = () => {
      setVisible(false);
    };

    const sendImage = () => {
      setLoading(true);
      !!fileRef.current &&
        returnContainerApi(
          {
            uuid,
            photo_return: fileRef.current,
          },
          onUploadProgress,
          onReturnSuccess,
          onReturnError
        );
    };

    const onUploadProgress = (event: any) => {
      console.log("uploadProgress", event);
    };

    const onReturnError = () => {
      setLoading(false);
    };

    const onReturnSuccess = (res: ReturnContainerApiResponse) => {
      setLoading(false);
      if (res) {
        showMessageWithTitle(
          t("popup.success.return.title"),
          t("popup.success.return"),
          t("popup.success.return.btn")
        );
      } else {
        showMessageWithTitle(
          t("popup.success.return.title"),
          t("popup.success.return"),
          t("popup.success.return.btn")
        ); //wip : msg to upload in the future
      }
      onReturned();
      hide();
    };

    return (
      <Drawer
        closable={false}
        mask={false}
        closeIcon={<div />}
        onClose={hide}
        visible={visible}
        placement={"bottom"}
        key={"delivery-complete"}
        height={window.innerHeight}
        bodyStyle={{ backgroundColor: "#f3f3f3" }}
      >
        {visible && (
          <LeftTitledTemplate
            classname={"return-container"}
            header={t("header.return.image")}
            headerProps={{
              classname: "return-header",
              right: t("header.return.btn.right"),
              left: t("header.return.btn.left"),
              // titleClassname: "helmet-title",
              onRight: sendImage,
              onLeft: hide,
            }}
          >
            <div className={"return-image-container"}>
              {previewImage && (
                <img className={"return-image"} src={previewImage} />
              )}
            </div>
            {loading && <PopupSpinner />}
          </LeftTitledTemplate>
        )}
      </Drawer>
    );
  }
);

export default ReturnContainerDrawer;
