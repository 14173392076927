import React, { useMemo, useRef } from "react";
import styles from "./shippingItem.module.css";
import {
  ShippingItemType,
  PhotoType,
  AddressType,
} from "../../../api/apiTypes";
import { useTranslation } from "react-i18next";
import Button from "antd/lib/button";
import copy from "copy-to-clipboard";
import Copy from "../../../assets/svgs/copy.svg";
import { showMessage } from "../../../fns/message";
import { Tag } from "antd";
import ReturnCompleteDrawer from "../returnComplete/ReturnCompleteDrawer";
import ShippingCompleteDrawer from "../shippingComplete/ShippingCompleteDrawer";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";

interface Props {
  item: ShippingItemType;
  onCompleted: () => void;
  sector?: string;
  delivery_uuid: string;
  photos?: PhotoType[];
  address?: AddressType;
}

const ShippingItem = ({
  item,
  sector,
  photos,
  onCompleted,
  delivery_uuid,
  address,
}: Props) => {
  const { t } = useTranslation("delivery");
  const deliveryCompleteRef = useRef<any>();
  const returnCompleteRef = useRef<any>();
  const { pendingShippings } = useSelector((state: RootState) => state.common);

  const uploading = useMemo(() => {
    let uploading = undefined;
    for (let i = 0; i < pendingShippings?.length; i++) {
      if (pendingShippings[i].tracking_number === item.tracking_number) {
        uploading = pendingShippings[i].status;
        break;
      }
    }
    return uploading;
  }, [pendingShippings]);

  const handleCopy = (tracking_number: string) => {
    document.execCommand("copy", true, tracking_number);
    copy(tracking_number);
    showMessage(t("copy.reg.no", { regno: tracking_number }));
  };

  const showComplete = () => {
    if (item.is_return) {
      returnCompleteRef.current.show();
    } else {
      deliveryCompleteRef.current.show();
    }
  };

  const statusNode = (item: ShippingItemType) => {
    const { status, is_return } = item;
    let className = styles.return + " small";
    let text = t("return");
    let isComplete = false;
    if (is_return) {
      switch (status) {
        case "RETURN_CREATED":
          className = styles.return + " small btn-c";
          text = item.retry ? t("return.again") : t("return");
          break;
        case "RETURN_UNIT_AFT_COLLECT":
        case "RETURN_OUTFORCOLLECT":
          if (item.collect_invoice_status === "PENDING") {
            className = styles.return + " small " + styles.done;
            text = t("return.damaged");
            isComplete = true;
          }
          break;
        case "RETURN_NOTCOLLECTED":
          if (item.retry) {
            className = styles.return + " small btn-c";
            text = t("return.again");
          } else {
            className = styles.return + " small " + styles.done;
            text = t("return.giveup");
            isComplete = true;
          }
          break;
        default:
          className = styles.return + " small " + styles.done;
          text = item.retry_count ? t("retry.return.done") : t("return.done");
          isComplete = true;
      }
    } else {
      switch (status) {
        case "DELIVERYCOMPLETE":
          className = styles.return + " small " + styles.done;
          text =
            item.retry_count > 0
              ? t("retry.delivery.done")
              : t("delivery.done");
          isComplete = true;
          break;
        case "DELIVERYINCOMPLETE":
          if (item.retry) {
            className = styles.return + " small primary-bg";
            text = t("delivery.again");
          } else {
            className = styles.return + " small " + styles.done;
            text = t("delivery.giveup");
            isComplete = true;
          }
          break;
        default:
          className = styles.return + " small primary-bg";
          text = item.retry ? t("delivery.again") : t("delivery");
          break;
      }
    }

    return (
      <Button type={"primary"} className={className} onClick={showComplete}>
        {text}
      </Button>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.deliveryRow}>
        <div className={"subtitle bold black85 row"}>
          {item.alias}
          {uploading && (
            <div className={"row align-center"}>
              <div className={styles.badge} />
              <span className={styles.uploading + " small1 "}>
                {uploading === "fail"
                  ? t("shipping.uploading.failed")
                  : t("shipping.uploading")}
              </span>
            </div>
          )}
        </div>
        {statusNode(item)}
      </div>
      <div className={styles.deliveryRow}>
        <div className={styles.deliveryCol}>
          <div className={"small bold black45"}>{t("reg.no")}</div>
          <span className={"subtitle bold black85 " + styles.value}>
            {item.tracking_number}
          </span>
        </div>
        <Button
          type={"text"}
          shape={"circle"}
          className={styles.icon}
          onClick={() => handleCopy(item.tracking_number)}
        >
          <img alt={"copy"} className={styles.img} src={Copy} />
        </Button>
      </div>
      <div className={styles.deliveryRow}>
        <div className={styles.deliveryCol}>
          <div className={"small black black45"}>{t("req")}</div>
          <div className={"body1 " + styles.value}>{item.extra_memo}</div>
        </div>
      </div>
      {item.delivery_info && (
        <div className={styles.deliveryRow}>
          <div className={styles.deliveryCol}>
            <div className={"small primary bold black45"}>
              {t("item.method")}
            </div>
            <div
              className={
                "body1 primary bold delivery-detail-info " + styles.value
              }
            >
              <Tag>{t(item.delivery_info?.delivery_complete_on)}</Tag>
              {item.delivery_info?.delivery_complete_on === "OTHER" && (
                <Tag>{item.delivery_info?.delivery_complete_on_info}</Tag>
              )}
            </div>
          </div>
        </div>
      )}
      <ReturnCompleteDrawer
        ref={returnCompleteRef}
        {...item}
        sector={sector}
        delivery_uuid={delivery_uuid}
        onCompleted={onCompleted}
      />
      <ShippingCompleteDrawer
        ref={deliveryCompleteRef}
        {...item}
        address={address}
        photos={photos}
        delivery_uuid={delivery_uuid}
        onCompleted={onCompleted}
      />
    </div>
  );
};

export default ShippingItem;
