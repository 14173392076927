export default function ({
  color = "black",
  className,
}: {
  color: string;
  className: string;
}) {
  return (
    <svg
      className={className}
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 7.94141C10.1824 7.94141 9.41406 8.25859 8.83437 8.83828C8.54952 9.12232 8.32363 9.45988 8.16971 9.83155C8.01579 10.2032 7.93687 10.6016 7.9375 11.0039C7.9375 11.8215 8.25742 12.5898 8.83437 13.1695C9.11842 13.4544 9.45598 13.6803 9.82764 13.8342C10.1993 13.9881 10.5977 14.067 11 14.0664C11.8176 14.0664 12.5859 13.7465 13.1656 13.1695C13.7453 12.5898 14.0625 11.8215 14.0625 11.0039C14.0625 10.1863 13.7453 9.41797 13.1656 8.83828C12.5859 8.25859 11.8176 7.94141 11 7.94141ZM20.368 6.90508C19.8532 5.71803 19.111 4.64326 18.1832 3.74141C17.2524 2.83141 16.1551 2.10914 14.9512 1.61406C13.6988 1.09453 12.3699 0.832031 11 0.832031C9.63008 0.832031 8.30117 1.09453 7.04883 1.61133C5.8375 2.11172 4.75195 2.82813 3.8168 3.73867C2.88963 4.64103 2.1475 5.71568 1.63203 6.90234C1.09883 8.13281 0.828125 9.43984 0.828125 10.7852C0.828125 12.7156 1.29023 14.6379 2.19805 16.4918C2.92813 17.982 3.94805 19.434 5.2332 20.8148C7.42891 23.1719 9.73125 24.6129 10.3848 24.9984C10.5703 25.108 10.7818 25.1656 10.9973 25.1652C11.2105 25.1652 11.4211 25.1105 11.6098 24.9984C12.2633 24.6129 14.5656 23.1719 16.7613 20.8148C18.0465 19.4367 19.0664 17.982 19.7965 16.4918C20.7098 14.6406 21.1719 12.7211 21.1719 10.7879C21.1719 9.44258 20.9012 8.13555 20.368 6.90508ZM11 15.8164C8.34219 15.8164 6.1875 13.6617 6.1875 11.0039C6.1875 8.34609 8.34219 6.19141 11 6.19141C13.6578 6.19141 15.8125 8.34609 15.8125 11.0039C15.8125 13.6617 13.6578 15.8164 11 15.8164Z"
        fill={color}
        fillOpacity="0.85"
      />
    </svg>
  );
}
