import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ScanScreen from "../components/pages/scan/ScanScreen";
import BoxManualPickup from "../components/pages/pickupContainerManual/PickupContainerManualScreen";
import PickupContainerScreen from "../components/pages/pickupContainer/PickupContainerScreen";
function ScanRoutes() {
  const renderScanRedirect = () => <Redirect to={"/scan"} />;

  return (
    <Switch>
      <Route exact path="/scan/" component={ScanScreen} />
      <Route exact path="/scan/container/" component={PickupContainerScreen} />
      <Route exact path="/scan/container/manual/" component={BoxManualPickup} />
      <Route exact path="/scan/:index" component={ScanScreen} />
      <Route path="*" component={renderScanRedirect} />
    </Switch>
  );
}

export default ScanRoutes;
