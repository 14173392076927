import React from "react";
import "../login/loginPhoneScreen.css";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../../reducers/profileReducer";
import BankView from "./BankView";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UpdatePhotoApiParam } from "../../../api/apiTypes";

const SignupBankScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["signup"]);

  const onAdd = (param: UpdatePhotoApiParam) => {
    dispatch(updateProfile(param));
    history.push("/auth/term");
  };

  const onSkip = () => {
    dispatch(updateProfile({ssn: undefined, bank_info_bank_code: undefined, bank_info_name: undefined, bank_info_account_number: undefined, file_ssn: undefined, file_bank: undefined}));
    history.push("/auth/term");
  };

  return (
    <BankView btnText={t("sector.btn")} onNext={onAdd} onSkip={onSkip} />
  );
};

export default SignupBankScreen;
