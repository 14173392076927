import React, { useCallback, useEffect, useState } from "react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import styles from "./mapRecentContainer.module.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import {
  DeliveryWorkingType,
  GetDeliveryListCountApiResponse,
} from "../../../api/apiTypes";
import { getDeliveriesCount } from "../../../api/shippingApi";
import { useTranslation } from "react-i18next";

// Import Swiper styles
import "swiper/swiper.min.css";
import Button from "antd/lib/button";
import { NAVER_DIRECT_LINK } from "../../../common/consts.common";
import DeliveryCountTitle from "../deliveryTitle/DeliveryCountTitle";
import { navigateMapApp } from "../../../fns/commonFns";
// @ts-ignore
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

interface Props {
  onClick: (visible?: boolean) => void;
  visible: boolean;
}

const MapRecentContainer = ({ onClick, visible }: Props) => {
  const { t } = useTranslation(["delivery"]);
  const { type: user_type } = useSelector((state: RootState) => state.profile);
  const { naviMap } = useSelector((state: RootState) => state.common);
  const [workingContainer, setWorkingContainer] = useState<
    DeliveryWorkingType[]
  >();

  useEffect(() => {
    if (!!user_type) {
      getDeliveriesCount(onSuccessGetWorking, 1);
    }
  }, [user_type]);

  const onSuccessGetWorking = (data: GetDeliveryListCountApiResponse) => {
    setWorkingContainer(data?.results);
    if (data.results.length > 0) {
      //방금 완료한 업무에 회수건이 있거나 미배송 건이 있을 때 허브로 이동하라는 문구 보여줌
      onClick(
        data.results[0].count_return_collected > 0 ||
          data.results[0].count_shipping_incomplete > 0
      );
    }
  };

  const handleClickTohub = useCallback(() => {
    onClick();
    if (
      workingContainer &&
      workingContainer?.length > 0 &&
      workingContainer[0].unit_location
    ) {
      navigateMapApp(naviMap, workingContainer[0].unit_location);
    }
  }, [workingContainer]);

  if (visible && workingContainer && workingContainer.length > 0) {
    return (
      <div className={styles.container}>
        <DeliveryCountTitle
          totalCount={workingContainer[0].count_deliveries}
          shippingCount={workingContainer[0].count_shipping}
          returnCount={workingContainer[0].count_return}
          alias={workingContainer[0].unit_location?.name}
        />
        <div className={"column inner-container"}>
          <span className={styles.itemContainer + " " + styles.itemInfo}>
            {workingContainer[0].count_return_collected > 0 &&
              `${t("return")} {${
                workingContainer[0].count_return_collected
              }}${t("count")}`}
            {workingContainer[0].count_return_collected > 0 &&
              workingContainer[0].count_return_collected > 0 &&
              t("count.divider")}
            {workingContainer[0].count_shipping_incomplete > 0 &&
              `${t("incomplete")} {${
                workingContainer[0].count_shipping_incomplete
              }}${t("count")}`}
            {t("return.hub.info")}
          </span>
          <Button type={"primary"} onClick={handleClickTohub}>
            {t("btn.tohub")}
          </Button>
        </div>
      </div>
    );
  }
  return null;
};

export default MapRecentContainer;
