import React, { useCallback, useEffect, useState } from "react";
import "../../organisms/container/containerItem.style.css";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import { getCurrentPosition } from "../../../services/location.service";
import OrderItemsInTab from "../../organisms/ContainersInTab";
import { ContainerType } from "../../../api/apiTypes";
import Select from "antd/lib/select";
import {
  FILTER_GENERAL_ORDER,
  FILTER_RETURN_ORDER,
  SORT_PAY,
  SORT_ROUTE,
} from "../../../common/consts.common";
import { useHistory } from "react-router-dom";
import EmptyContent from "../../organisms/emptyContent/EmptyContent";
import http from "../../../services/axios.service";
import { InfiniteList } from "@delivus/react-infinite-list";
import { showApiError } from "../../../fns/message";
import { BunnyType } from "../../../reducers/reducerTypes";

const { Option } = Select;

interface Params {
  params: {
    index: number;
  };
}
const ContainerAssignedScreen = ({ match }: { match: Params }) => {
  const { index } = match.params; // selected order uuid
  const { t } = useTranslation(["order"]);
  const history = useHistory();
  const [refresh, setRefresh] = useState(false);
  const [sortBy, setSortBy] = useState(SORT_PAY);
  const [sortOpen, setSortOpen] = useState(false);
  const [currentPosition, setCurrentPosition] = useState<GeolocationPosition>();

  const { type, bunny_type } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    getCurrentPosition(onLocationRetrieved, onLocationError);
  }, []);

  function onLocationRetrieved(position: GeolocationPosition | null) {
    if (position?.coords) {
      setCurrentPosition(position);
    } else {
      console.log("current position");
    }
  }

  function onLocationError() {
    console.log("current position error");
  }

  const handleClaimDone = useCallback(() => {
    if (bunny_type === BunnyType.FIXED) {
      //blue doesn't have available container tab
      history.push("/home/container/0");
    } else {
      history.push("/home/container/1");
    }
  }, [bunny_type]);

  const handleUnclaimDone = useCallback(() => {
    setRefresh((prev) => !prev);
  }, []);

  const handleSortChanged = useCallback((res) => {
    setSortBy(res);
    setRefresh((prev) => !prev);
  }, []);

  const handleRefresh = useCallback(() => {
    setRefresh((prev) => !prev);
  }, []);

  const handleListFetchError = (error: any) => {
    showApiError(error);
  };

  const sortNode = (
    <div data-cy={"sortNode"} onClick={() => setSortOpen((prev) => !prev)}>
      <Select
        data-cy={"sortNodeSelect"}
        size={"small"}
        value={sortBy}
        open={sortOpen}
        onChange={handleSortChanged}
        style={{ width: 88 }}
      >
        <Option
          style={{ fontSize: 12, minHeight: 30 }}
          key={"sort.pay"}
          value={SORT_PAY}
        >
          {t("sort.pay")}
        </Option>
        <Option
          style={{ fontSize: 12, minHeight: 30 }}
          key={"sort.route"}
          value={SORT_ROUTE}
        >
          {t("sort.route")}
        </Option>
        <Option
          style={{ fontSize: 12, minHeight: 30 }}
          key={"sort.general"}
          value={FILTER_GENERAL_ORDER}
        >
          {t("sort.general")}
        </Option>
        <Option
          style={{ fontSize: 12, minHeight: 30 }}
          key={"sort.return"}
          value={FILTER_RETURN_ORDER}
        >
          {t("sort.return")}
        </Option>
      </Select>
    </div>
  );

  const params: any = {
    ordering: sortBy,
    lat: currentPosition?.coords?.latitude,
    lng: currentPosition?.coords?.longitude,
  };
  if (sortBy === FILTER_RETURN_ORDER || sortBy === FILTER_GENERAL_ORDER) {
    params.pickup_only = sortBy === FILTER_RETURN_ORDER;
  }
  return (
    <div className={"container order-black-container"}>
      <div className={"inner-container "}>
        <div className={"row space-between align-center order-row"}>
          <div className={"body1 bold"}>{t("title.assigned")}</div>
          {sortNode}
        </div>
        <InfiniteList
          refresh={refresh}
          http={http as any}
          authenticated={!!type}
          // headers={TokenStorage.getAuthentication().headers}
          onApiFailed={handleListFetchError}
          url={"/flex/containers/assigned/"}
          height={window.innerHeight - 190}
          params={params}
        >
          {(count?: number, results?: ContainerType[]) => {
            return (
              <div>
                {results && results.length > 0 ? (
                  <OrderItemsInTab
                    index={index}
                    data={results}
                    currentPosition={currentPosition}
                    onClaimDone={handleClaimDone}
                    onUnclaimDone={handleUnclaimDone}
                    onRefresh={handleRefresh}
                  />
                ) : (
                  <EmptyContent
                    title={t("assigned.empty.title")}
                    label={t("assigned.empty.content")}
                  />
                )}
              </div>
            );
          }}
        </InfiniteList>
      </div>
    </div>
  );
};

export default ContainerAssignedScreen;
