export const deleteObjEmptyValue = (obj: any) => {
  for (var propName in obj) {
    if (!obj[propName]) {
      delete obj[propName];
    }
  }
  return obj;
};

export function replaceAll(text: string, search: string, replace: string) {
  return text.split(search).join(replace);
}

export const isEmptyObject = (object: any) => {
  return !object || !(Object.keys(object).length > 0);
};

export function objectToStringArray(o: any): string[] {
  const keyValuePairs: string[] = [];
  Object.keys(o).forEach((key) => {
    if (o[key]) {
      keyValuePairs.push(
        encodeURIComponent(key) + "=" + encodeURIComponent(o[key])
      );
    }
  });
  return keyValuePairs;
}

export function objToQueryString(obj?: any) {
  if (!obj) {
    return null;
  }
  let keyValuePairs: string[] = [];
  if (Array.isArray(obj)) {
    obj.forEach((o) => {
      keyValuePairs = [...keyValuePairs, ...objectToStringArray(o)];
    });
  } else {
    keyValuePairs = objectToStringArray(obj);
  }
  return "?" + keyValuePairs.join("&");
}
