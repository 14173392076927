export default function ProfileIcon({
  color = "black",
  className,
}: {
  color: string;
  className: string;
}) {
  return (
    <svg
      className={className}
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 13.9727C3.5 14.2025 3.54526 14.43 3.63321 14.6424C3.72116 14.8547 3.85006 15.0476 4.01256 15.2101C4.17507 15.3726 4.36798 15.5015 4.5803 15.5894C4.79262 15.6774 5.02019 15.7227 5.25 15.7227C5.47981 15.7227 5.70738 15.6774 5.9197 15.5894C6.13202 15.5015 6.32493 15.3726 6.48744 15.2101C6.64994 15.0476 6.77884 14.8547 6.86679 14.6424C6.95474 14.43 7 14.2025 7 13.9727C7 13.7428 6.95474 13.5153 6.86679 13.303C6.77884 13.0906 6.64994 12.8977 6.48744 12.7352C6.32493 12.5727 6.13202 12.4438 5.9197 12.3559C5.70738 12.2679 5.47981 12.2227 5.25 12.2227C5.02019 12.2227 4.79262 12.2679 4.5803 12.3559C4.36798 12.4438 4.17507 12.5727 4.01256 12.7352C3.85006 12.8977 3.72116 13.0906 3.63321 13.303C3.54526 13.5153 3.5 13.7428 3.5 13.9727ZM12.25 13.9727C12.25 14.4368 12.4344 14.8819 12.7626 15.2101C13.0908 15.5383 13.5359 15.7227 14 15.7227C14.4641 15.7227 14.9092 15.5383 15.2374 15.2101C15.5656 14.8819 15.75 14.4368 15.75 13.9727C15.75 13.5085 15.5656 13.0634 15.2374 12.7352C14.9092 12.407 14.4641 12.2227 14 12.2227C13.5359 12.2227 13.0908 12.407 12.7626 12.7352C12.4344 13.0634 12.25 13.5085 12.25 13.9727ZM21 13.9727C21 14.4368 21.1844 14.8819 21.5126 15.2101C21.8408 15.5383 22.2859 15.7227 22.75 15.7227C23.2141 15.7227 23.6593 15.5383 23.9874 15.2101C24.3156 14.8819 24.5 14.4368 24.5 13.9727C24.5 13.5085 24.3156 13.0634 23.9874 12.7352C23.6593 12.407 23.2141 12.2227 22.75 12.2227C22.2859 12.2227 21.8408 12.407 21.5126 12.7352C21.1844 13.0634 21 13.5085 21 13.9727Z"
        fill={color}
        fillOpacity={0.85}
      />
    </svg>
  );
}
