import React, { useEffect, useRef, useState } from "react";
import "./loginPhoneScreen.css";
import {
  checkPhoneExist,
  requestPhoneCode,
  verifyPhoneCode,
} from "../../../api/authApi";
import { KOREAN_CODE } from "../../../common/consts.common";
import { AxiosError } from "axios";
import LoginPhoneScreen from "../login/LoginPhoneScreen";
import PopupSpinner from "../../molecules/spinner/PopupSpinnerView";
import { useTranslation } from "react-i18next";
import { showApiError, showMessage } from "../../../fns/message";
import {
  ConfirmCodeApiResponse,
  RefreshApiResponse,
} from "../../../api/apiTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  getUserClaimCount,
  getUserSector,
} from "../../../api/userApi";
import { clearProfile, updateProfile } from "../../../reducers/profileReducer";
import { getDeliveriesCount } from "../../../api/shippingApi";
import {
  clearCommon,
  updateAuthenticated,
  updateClaimSts,
  updateDeliveryCount,
} from "../../../reducers/commonReducer";
import { countDeliveries } from "../../../fns/commonFns";
import { TokenStorage } from "../../../services/token.service";
import { replaceAll } from "../../../fns/objectFns";
import { RootState } from "../../../reducers/reducer";

const LoginPhoneContainer = () => {
  const phoneRef = useRef("");
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [expireDate, setExpireDate] = useState<Date>();
  const { t } = useTranslation(["login"]);

  const handleCheckPhone = (phoneParam: string) => {
    console.log("phoneparam", phoneParam);
    const phonenumber =
      typeof phoneParam === "string"
        ? replaceAll(phoneParam, " ", "")
        : phoneParam;
    phoneRef.current = phonenumber;
    setLoading(true);
    checkPhoneExist(
      KOREAN_CODE + phoneRef.current,
      onPhoneExist,
      onPhoneCheckError
    );
  };

  const handleRequestCode = (onSuccess?: () => void) => {
    setLoading(true);
    requestPhoneCode(
      KOREAN_CODE + phoneRef.current,
      (expireDate) => {
        if (onSuccess) onSuccess();
        setExpireDate(expireDate);
        onRequestCodeSuccess();
      },
      onRequestCodeError
    );
  };

  const handleVerifyCode = (token: string) => {
    setLoading(true);
    verifyPhoneCode(
      KOREAN_CODE + phoneRef.current,
      token,
      onPhoneVerified,
      onPhoneVerifyError
    );
  };

  const onPhoneExist = () => {
    handleRequestCode();
  };

  const onPhoneCheckError = (error: AxiosError) => {
    setLoading(false);
    if (error?.response?.status === 404) {
      const redirectUrl = `${process.env.REACT_APP_NICE_URL}/checkplus_main?phonenumber=${phoneRef.current}`;
      window.open(redirectUrl, "_self");
    } else if (error?.response?.status === 400) {
      showMessage(t("login.popup.error.phone"));
    } else {
      showMessage(error.message);
    }
  };

  const onRequestCodeSuccess = () => {
    setLoading(false);
    showMessage(t("login.popup.success.sent"));
  };

  const onRequestCodeError = (err: AxiosError) => {
    setLoading(false);
    if (err.response?.status === 400) {
      showMessage(t("login.popup.error.phone"));
    } else if (err.response?.status === 429) {
      showMessage(t("login.popup.error.maxcode"));
    } else {
      showMessage(err.message);
    }
  };

  const onPhoneVerified = (data: ConfirmCodeApiResponse) => {
    TokenStorage.storeToken(data.access);
    TokenStorage.storeRefreshToken(data.refresh);
    getUser(
      (data) => {
        if (data.type === "RIDER") {
          dispatch(updateAuthenticated(true));
          dispatch(updateProfile(data));
          getUserSector((res) => {
            dispatch(updateProfile({ sector: res.results }));
          });
          getDeliveriesCount((res) => {
            dispatch(updateDeliveryCount(countDeliveries(res.results)));
          });
          getUserClaimCount((res) => {
            dispatch(updateClaimSts(res));
          });
        } else {
          setLoading(false);
          showMessage(t("login.popup.error.notrider"), "warning");
          TokenStorage.clear();
          dispatch(clearProfile());
          dispatch(clearCommon());
        }
      },
      (err) => {
        setLoading(false);
        showApiError(err);
        if (err.response?.status === 401 || err.response?.status === 403) {
          TokenStorage.clear();
          dispatch(clearProfile());
          dispatch(clearCommon());
        }
      }
    );
  };

  const onPhoneVerifyError = (error: AxiosError) => {
    setLoading(false);
    if (error?.response?.status === 400) {
      showMessage(t("login.popup.error.code"));
    } else {
      showApiError(error);
    }
  };

  return (
    <div>
      <LoginPhoneScreen
        expireDate={expireDate}
        onCheckPhone={handleCheckPhone}
        onRequestCode={handleRequestCode}
        onVerifyCode={handleVerifyCode}
      />
      {loading && <PopupSpinner />}
    </div>
  );
};

export default LoginPhoneContainer;
