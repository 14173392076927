import useSWRInfinite from "swr/infinite";
import {
  DeliveryListItemType,
  GetListResponse,
  IGSectorsApiParams,
  SectorType,
} from "./apiTypes";
import { useMemo } from "react";
import { fetcher } from "../services/axios.service";
import { objToQueryString } from "../fns/objectFns";
export function useSectors(params: IGSectorsApiParams) {
  let paramString = useMemo(
    () => objToQueryString({ ...params, page_size: 50 }),
    [params]
  );
  const getKey = (
    pageIndex: number,
    previousPageData: GetListResponse<SectorType>
  ) => {
    if (!params.name_category__icontains) return null;
    if (pageIndex === 0) return `/location/sectors/${paramString}`;
    if (previousPageData && previousPageData.next) return previousPageData.next; // reached the end
    return null;
  };

  const { data, error, size, setSize, mutate } = useSWRInfinite<
    GetListResponse<DeliveryListItemType>
  >(getKey, fetcher, { initialSize: 1 });

  const sectors = useMemo(() => {
    console.log("useSectors", data);
    let listData: any[] = [];
    if (data && data?.length > 0) {
      const lastRes = data[data?.length - 1];
      if (lastRes.current_page * lastRes.items_per_page < lastRes.count)
        setSize(size + 1);
      else {
        data.forEach((item, idx) => {
          if (item?.results) {
            listData = listData.concat(item.results);
          }
        });
      }
    }
    return listData;
  }, [data]);

  return {
    sectors,
    mutate,
    isError: error,
  };
}
