import React, { ReactNode, useEffect, useState } from "react";
import "./toptab.css";
import "swiper/swiper.min.css";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { ToggleButton } from "@delivus/react-toggle-button";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

type ToptabItemType = {
  url: string;
  label: string;
  title?: string;
  count?: number;
};

type Props = {
  t: any;
  index?: number;
  refresh?: boolean;
  children: (tab: ToptabItemType, index: number) => ReactNode;
  tabs: ToptabItemType[];
  className?: string;
  tabLabels: string[];
  onChange?: (key: number) => void;
};
const ToptabView = ({
  t,
  index,
  onChange,
  tabs,
  className,
  tabLabels,
  children,
  refresh,
}: Props) => {
  const swiperCurrent: any = document.querySelector(".swiper-container");
  const [selectedIndex, setIndex] = useState(index || 0);

  useEffect(() => {
    if (index !== undefined) {
      setIndex(index);
      const swiper = swiperCurrent?.swiper;
      if (swiper && index >= 0) {
        swiper.slideTo(index);
      }
    }
  }, [index, refresh]);

  const handleClick = (index: number) => {
    const swiper = swiperCurrent?.swiper;
    if (swiper && index >= 0) {
      swiper.slideTo(index);
    }
    setIndex(index);
    if (onChange) {
      onChange(index);
    }
  };

  const handleSwipe = (e: any) => {
    setIndex(e.realIndex);
    if (onChange) {
      onChange(e.realIndex);
    }
  };

  return (
    <div className={"toptab-container"}>
      <ToggleButton
        onSelect={handleClick}
        containerClass={"toptab-inner " + className}
        textClass={"toptab"}
        selectedIdx={selectedIndex}
        items={tabLabels}
      />
      <Swiper
        spaceBetween={40}
        slidesPerView={"auto"}
        scrollbar={{ draggable: true }}
        width={300}
        initialSlide={selectedIndex}
        centeredSlidesBounds
        onSlideChange={handleSwipe}
      >
        {tabs.map((tab, index) => (
          <SwiperSlide
            key={"delivery_slide" + index}
            style={{ width: "calc(100vw - 40px)" }}
            virtualIndex={index}
          >
            {index == selectedIndex && children(tab, index)}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ToptabView;
