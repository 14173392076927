import React, { useEffect, useState } from "react";
import { showApiError } from "../../../fns/message";
import { AxiosError } from "axios";
import Scanner from "../../molecules/scanner/Scanner";
import styles from "./scanView.module.css";

interface Props {
  pendingLabel: string;
  guideTitle: string;
  guideLabel: string;
  pendingCount: number;
  callApi: (
    scannedValue: string,
    onSuccess: (datan: any) => void,
    onError: (e: any) => void
  ) => void;
  onSuccess: (data: any) => void;
}
const ScanView = React.forwardRef(
  (
    {
      callApi,
      guideLabel,
      guideTitle,
      pendingLabel,
      pendingCount,
      onSuccess,
    }: Props,
    ref: any
  ) => {
    const [loading, setLoading] = useState(false);

    const onScan = (scanValue: string | null) => {
      if (!!scanValue && !loading) {
        setLoading(true);
        callApi(scanValue, onApiSuccess, onApiError);
      }
    };

    const onApiError = (error: AxiosError) => {
      showApiError(error);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    const onApiSuccess = (data: any) => {
      setLoading(false);
      onSuccess(data);
    };

    return (
      <div className={styles.scannerTabContainer}>
        <span className={styles.scannerCntInfo + " small white"}>
          {pendingLabel}
          <span className={"body bold white"}>{pendingCount}</span>
        </span>
        <Scanner ref={ref} onScan={onScan} />
        <span className={styles.scannerInfo + " body1 bold white85"}>
          {guideTitle}
        </span>
        <div style={{ height: 10 }} />
        <span
          className={
            styles.scannerInfoSubtitle +
            " preline body bold white45 text-center"
          }
        >
          {guideLabel}
        </span>
      </div>
    );
  }
);

export default ScanView;
