import React, { useEffect, useRef } from "react";
import "./edu.style.css";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import Button from "antd/lib/button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import { updateVisitedEdu } from "../../../reducers/commonReducer";
import { EDU_LINK } from "../../../common/consts.common";
import WebView from "../../molecules/webview/WebView";

const SafetyEduScreen = () => {
  const termRef = useRef<any>();
  const { t } = useTranslation(["signup"]);
  const dispatch = useDispatch();
  const { visitedEdu } = useSelector((state: RootState) => state.common);

  useEffect(() => {
    if (!visitedEdu) {
      dispatch(updateVisitedEdu(true));
    }
  }, [visitedEdu]);

  return (
    <LeftTitledTemplate
      header={t("edu.header")}
      title={t("edu.title")}
      subtitle={t("edu.subtitle")}
      data-cy={"eduDiv"}
    >
      <div>
        <div className={"edu-info body"}>{t("edu.info")}</div>
        <Button
          data-cy={"eduClick"}
          onClick={() => termRef.current.show(t("edu.link"), EDU_LINK)}
          className={"edu-link-container"}
        >
          <div className={"edu-link body1"}>{t("edu.link")}</div>
        </Button>
      </div>
      <WebView ref={termRef} />
    </LeftTitledTemplate>
  );
};

export default SafetyEduScreen;
