import React, { useCallback, useRef } from "react";
import "./containerItem.style.css";
import Button from "antd/lib/button";
import ForkRight from "../../../assets/svgs/fork_right.svg";
import MapMarker from "../../../assets/svgs/mapMarker";
import MapFlatMarker from "../../../assets/svgs/mapFlatMarker";
import SelectNaviPopup from "../selectNaviApp/SelectNaviPopup";
import { navigateMapApp } from "../../../fns/commonFns";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import { ShippingBoxStatus } from "../../../api/apiTypes";
import { useHistory } from "react-router-dom";

interface Props {
  status: ShippingBoxStatus;
  calledFromMap?: boolean;
  uuid: string;
  alias?: string;
  unit_location: any;
  onMap?: (uuid: string) => void;
}
const ContainerMapBtn = ({
  status,
  calledFromMap,
  uuid,
  alias,
  onMap,
  unit_location,
}: Props) => {
  const navigateRef = useRef<any>();
  const history = useHistory();
  const { naviMap } = useSelector((state: RootState) => state.common);

  const navigateDirection = useCallback(() => {
    if (unit_location) {
      if (!naviMap) {
        navigateRef.current.show(unit_location);
      } else {
        navigateMapApp(naviMap, unit_location);
      }
    } else {
      console.log("no address selected");
    }
  }, [naviMap, unit_location]);

  const navigateMap = useCallback(() => {
    if (onMap) {
      onMap(uuid);
    }
  }, [uuid]);

  const navigateCompletedMap = () => {
    history.push(`/delivery/container/${uuid}/${alias}`);
  };

  if (calledFromMap) {
    return (
      <Button
        type={"primary"}
        shape={"circle"}
        className={"order-item-direction"}
        onClick={navigateDirection}
      >
        <img alt={"orderfork"} className={"fork-img"} src={ForkRight} />
        <SelectNaviPopup ref={navigateRef} />
      </Button>
    );
  }

  if (status === "UNIT_RETURNED") {
    return (
      <Button
        type={"text"}
        shape={"circle"}
        className={"order-item-map"}
        onClick={navigateCompletedMap}
      >
        <MapFlatMarker color={"var(--black45)"} />
      </Button>
    );
  }

  return (
    <Button
      type={"text"}
      shape={"circle"}
      className={"order-item-map"}
      onClick={navigateMap}
    >
      <MapMarker color={"var(--primary)"} />
    </Button>
  );
};

export default React.memo(ContainerMapBtn);
