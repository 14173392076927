import React, { forwardRef, useImperativeHandle, useState } from "react";
import styles from "./web.module.css";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import { Drawer } from "antd";

const WebView = forwardRef(({}, ref) => {
  const [visible, setVisible] = useState(false);
  const [url, setUrl] = useState("");
  const [header, setHeader] = useState("");
  useImperativeHandle(ref, () => ({
    show: (header: string, url: string) => {
      show(header, url);
    },
  }));

  const show = async (header: string, url: string) => {
    setVisible(true);
    setHeader(header);
    setUrl(url);
  };

  const hide = () => {
    setVisible(false);
  };

  return (
    <Drawer
      closable={false}
      onClose={hide}
      visible={visible}
      placement={"bottom"}
      height={window.innerHeight}
    >
      <LeftTitledTemplate
        header={header}
        classname={styles.cntr}
        headerProps={{ onLeft: hide }}
      >
        {!!url && <iframe src={url} className={styles.webView}></iframe>}
      </LeftTitledTemplate>
    </Drawer>
  );
});

export default WebView;
