import { showMessage } from "../fns/message";
const options = {
  enableHighAccuracy: true,
  timeout: 20000,
  maximumAge: 20000, //항상 최신 정보를 얻음
};
export const watchLocation = (
  callback: (position: GeolocationPosition) => void
) => {
  if (navigator.geolocation) {
    const watchID = navigator.geolocation.watchPosition(
      callback,
      () => {},
      options
    );
    return watchID;
  }
};

export const clearWatchLocation = (id?: number) => {
  if (!!navigator.geolocation && !!id) {
    return navigator.geolocation.clearWatch(id);
  }
};

export const getCurrentPosition = (
  callback: (position: GeolocationPosition | null) => void,
  onError: () => void
) => {
  console.log("navigator error", navigator.geolocation);
  if (navigator.geolocation) {
    // GeoLocation을 이용해서 접속 위치를 얻어옵니다
    navigator.geolocation.getCurrentPosition(callback, onError);
  } else {
    console.log("navigator error");
    showMessage("Location service error");
  }
};
