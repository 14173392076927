import React from "react";
import { NavLink } from "react-router-dom";
import "./tabbar.style.css";
import { useTranslation } from "react-i18next";

type Props = {
  route: string;
  icon: any;
  label: string;
  active?: boolean;
  count?: number;
  badge?: boolean;
};
const TabItem = ({ route, icon, label, active, count, badge }: Props) => {
  const { t } = useTranslation(["common"]);
  const Icon = icon;
  console.log("TabItem", route, active);
  return (
    <NavLink to={route} className="tab-link" activeClassName="active">
      {!!count && <span className={"small medium tab-count"}>{count}</span>}
      {badge && <span className={"tab-badge"} />}
      <div className="tab-inner">
        <Icon
          className={"tab-icon"}
          color={active ? "var(--primary)" : "black"}
        />
        <p className={active ? "small1 tab-active" : "tab-text small1"}>
          {t(label)}
        </p>
      </div>
    </NavLink>
  );
};

export default TabItem;
