import React, { useEffect, useRef } from "react";
import { Html5Qrcode } from "html5-qrcode";
import Bugsnag from "@bugsnag/js";

type Props = {
  onScan: (res: string | null, onEnd?: () => void) => void;
  onError?: (err: any) => void;
  height?: number;
};

const Scanner = React.forwardRef(
  ({ onScan, onError, height }: Props, ref: any) => {
    const scannerRef = useRef<any>();
    const timerRef = useRef<any>();
    const loadingRef = useRef(false);
    const failureSentRef = useRef(true);

    useEffect(() => {
      console.log("ref scanner: ", ref);
      scannerRef.current = new Html5Qrcode("reader");
      if (ref) ref.current = scannerRef.current;
      startScan();
      timerRef.current = setTimeout(() => {
        failureSentRef.current = false;
      }, 3000);

      return () => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        const state = scannerRef.current.getState();
        if (state !== 0) {
          try {
            scannerRef.current
              .stop()
              .then(() => {
                // QR Code scanning is stopped.
              })
              .catch(() => {
                // Stop failed, handle it.
              });
          } catch (e) {
            console.log("scanner stop", e);
          }
        }
      };
    }, []);

    function onScanSuccess(decodedText: string, decodedResult: any) {
      // handle the scanned code as you like, for example:
      if (loadingRef.current) return;
      loadingRef.current = true;
      console.log(`Code matched = ${decodedText}`, decodedResult);
      onScan(decodedText);
      timerRef.current = setTimeout(() => {
        loadingRef.current = false;
      }, 3000);
    }

    function onScanFailure(error: any) {
      if (!failureSentRef.current) {
        failureSentRef.current = true;
        if (onError) onError(error);
      }
    }

    const startScan = () => {
      const html5QrcodeScanner = scannerRef.current;
      const config = {
        fps: 20,
        // qrbox: { width: 250, height: 250 },
        width: window.innerWidth,
        aspectRatio: window.innerWidth / (window.innerHeight - 350),
        formatsToSupport: ["QR_CODE", "CODE_128"],
      };
      html5QrcodeScanner
        .start(
          { facingMode: "environment" },
          config,
          onScanSuccess,
          onScanFailure
        )
        .catch((e: any) => {
          Bugsnag.notify(e);
        });
    };

    const pauseScan = () => {
      const html5QrcodeScanner = scannerRef.current;

      const sts = html5QrcodeScanner.getState();
      console.log("scanner sts", sts);
      if (sts === "SCANNING") html5QrcodeScanner.pause(false);
    };

    const resumeScan = () => {
      const html5QrcodeScanner = scannerRef.current;
      const sts = html5QrcodeScanner.getState();
      console.log("scanner sts", sts);
      if (sts === "PAUSED") html5QrcodeScanner.resume();
    };

    return (
      <div
        style={{
          width: "100%",
          height: height || window.innerHeight - 350,
          overflow: "hidden",
        }}
      >
        <div id="reader" />
      </div>
    );
  }
);

export default Scanner;
