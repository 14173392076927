import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DeliveryListItemType } from "../../../api/apiTypes";
import ToptabView from "../../molecules/toptab/ToptabView";
import DeliveriesInTab from "./DeliveriesInTab";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import { updateClaimSts } from "../../../reducers/commonReducer";
import { showApiError } from "../../../fns/message";
import http from "../../../services/axios.service";
import { InfiniteList } from "@delivus/react-infinite-list";
import { getUserClaimCount } from "../../../api/userApi";

const TAB = [
  {
    url: `/flex/deliveries/?filter=pending`,
    label: "waiting",
    title: "title.waiting",
    count: 0,
  },
  {
    url: "/flex/deliveries/?filter=complete",
    label: "done",
    title: "title.done",
    count: 0,
  },
  {
    url: "/flex/deliveries/?filter=retry",
    label: "retry",
    title: "title.retry",
    count: 0,
  },
];

interface Params {
  params: {
    index: number;
  };
}

const CurrentDeliveriesScreen = ({ match }: { match: Params }) => {
  const { index = 0 } = match.params;
  const { t } = useTranslation(["delivery"]);
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const [tabIndex, setTabIndex] = useState(index);
  const {
    count_shipping_pending,
    count_shipping_completed,
    count_shipping_retry,
    pendingShippings,
  } = useSelector((state: RootState) => state.common);
  const { type } = useSelector((state: RootState) => state.profile);

  useEffect(() => {
    setRefresh((prev) => !prev);
  }, [pendingShippings]);

  const handleSort = useCallback((index: string) => {
    // setSortBy(index);
    setRefresh((prev) => !prev);
  }, []);

  const handleChangeTab = useCallback((index: number) => {
    setTabIndex(index);
    window.history.replaceState("", "", "" + index);
  }, []);

  const handleListFetchError = (error: any) => {
    showApiError(error);
  };

  const updateCount = useCallback(() => {
    getUserClaimCount((res) => {
      dispatch(updateClaimSts(res));
    });
  }, []);

  return (
    <div className={"container order-container"}>
      <div className={"inner-container"}>
        <ToptabView
          t={t}
          tabs={TAB}
          tabLabels={[
            t("waiting", { count: count_shipping_pending }),
            t("done", {
              count: count_shipping_completed,
            }),
            t("retry", { count: count_shipping_retry || 0 }),
          ]}
          index={tabIndex}
          onChange={handleChangeTab}
        >
          {(tab, index) => {
            return (
              <InfiniteList
                http={http as any}
                authenticated={!!type}
                // headers={TokenStorage.getAuthentication().headers}
                onApiFailed={handleListFetchError}
                url={tab.url}
                refresh={refresh}
                height={window.innerHeight - 160}
                onGetCount={updateCount}
              >
                {(count?: number, results?: DeliveryListItemType[]) => {
                  console.log("delivery infinite", results);
                  return (
                    <DeliveriesInTab
                      index={index}
                      {...tab}
                      data={results}
                      onChangeSort={handleSort}
                    />
                  );
                }}
              </InfiniteList>
            );
          }}
        </ToptabView>
      </div>
    </div>
  );
};

export default CurrentDeliveriesScreen;
