import http from "../services/axios.service";
import { AxiosError } from "axios";
import {
  ConfirmCodeApiResponse,
  SignupApiParam,
  SignupApiResponse,
} from "./apiTypes";
import { showApiError } from "../fns/message";
import { addMinutes } from "date-fns";
import Bugsnag from "@bugsnag/js";

export const checkPhoneExist = (
  phonenumber: string,
  onExist: () => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post("/auth/phonenumber/check/", { phonenumber })
    .then((res) => {
      console.log("checkPhoneExist", phonenumber);
      onExist();
    })
    .catch((err) => {
      onError(err);
    });
};

export const requestPhoneCode = (
  phonenumber: string,
  onSuccess: (expireDate: Date) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post("/auth/passwordless/signin/token_request/", { phonenumber })
    .then((res) => {
      console.log("requestPhoneCode", res);
      //wip: until give real expire Date
      const expireDate = !!res.data?.timestamp_expires
        ? new Date(res.data.timestamp_expires)
        : addMinutes(new Date(), 3);
      onSuccess(expireDate);
    })
    .catch((err) => {
      onError(err);
    });
};

export const verifyPhoneCode = (
  phonenumber: string,
  token: string,
  onSuccess: (data: ConfirmCodeApiResponse) => void,
  onError: (err: AxiosError) => void
) => {
  http
    .post("/auth/passwordless/signin/token_confirm/", { phonenumber, token })
    .then((res) => {
      console.log("verifyPhone", res.data.refresh);
      onSuccess(res?.data);
    })
    .catch((err) => {
      onError(err);
    });
};

export const verifyTokenApi = (token: string | null) => {
  return http.post("/auth/token/verify/", { token });
};

export const refreshToken = (refresh: string | null) => {
  return http.post("/auth/token/refresh/", { refresh });
};

export const signup = (
  {
    file_ssn,
    file_bank,
    noti_allow_late,
    phonenumber,
    res_seq,
    ride_type,
    sector,
    ssn,
    bank_info_account_number,
    bank_info_bank_code,
  }: SignupApiParam,
  onSuccess: (data: SignupApiResponse) => void
) => {
  let formData = new FormData();
  file_ssn && formData.append("photo_ssn", file_ssn, file_ssn.name);
  file_bank && formData.append("photo_bank", file_bank, file_bank.name);
  formData.append("noti_allow_late", noti_allow_late + "");
  formData.append("phonenumber", phonenumber);
  formData.append("res_seq", res_seq);
  formData.append("ride_type", ride_type);
  for (var i = 0; sector && i < sector.length; i++) {
    formData.append("sector[]", sector[i]);
  }
  ssn && formData.append("ssn", ssn);
  bank_info_bank_code && formData.append("bank_info_bank_code", bank_info_bank_code);
  bank_info_account_number && formData.append("bank_info_account_number", bank_info_account_number);
  http
    .post("/auth/signup/niceid/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      onSuccess(res?.data);
    })
    .catch((error)=>{
        Bugsnag.notify(error);
        showApiError(error);
    });
};

export const signout = (
  refresh: string,
  onSuccess: (data: SignupApiResponse) => void,
  onError: () => void
) => {
  http
    .post("/auth/signout/", { refresh })
    .then((res) => {
      onSuccess(res?.data);
    })
    .catch(onError);
};

export const inactivateAccount = (
  refresh: string,
  onSuccess: (data: SignupApiResponse) => void
) => {
  http
    .post("/auth/unregister/", { refresh })
    .then((res) => {
      onSuccess(res?.data);
    })
    .catch(showApiError);
};
