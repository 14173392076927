export default function ({
  color = "black",
  className,
}: {
  color: string;
  className: string;
}) {
  return (
    <svg
      className={className}
      width="30"
      height="28"
      viewBox="0 0 30 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.375 6.125H22V4.59375C22 4.47344 21.9016 4.375 21.7812 4.375H20.25C20.1297 4.375 20.0312 4.47344 20.0312 4.59375V6.125H15.9844V4.59375C15.9844 4.47344 15.8859 4.375 15.7656 4.375H14.2344C14.1141 4.375 14.0156 4.47344 14.0156 4.59375V6.125H9.96875V4.59375C9.96875 4.47344 9.87031 4.375 9.75 4.375H8.21875C8.09844 4.375 8 4.47344 8 4.59375V6.125H3.625C3.14102 6.125 2.75 6.51602 2.75 7V22.75C2.75 23.234 3.14102 23.625 3.625 23.625H26.375C26.859 23.625 27.25 23.234 27.25 22.75V7C27.25 6.51602 26.859 6.125 26.375 6.125ZM12.5938 18.8125C12.5938 18.9328 12.4953 19.0312 12.375 19.0312H7.34375C7.22344 19.0312 7.125 18.9328 7.125 18.8125V17.5C7.125 17.3797 7.22344 17.2812 7.34375 17.2812H12.375C12.4953 17.2812 12.5938 17.3797 12.5938 17.5V18.8125ZM12.5938 15.0938C12.5938 15.2141 12.4953 15.3125 12.375 15.3125H7.34375C7.22344 15.3125 7.125 15.2141 7.125 15.0938V13.7812C7.125 13.6609 7.22344 13.5625 7.34375 13.5625H12.375C12.4953 13.5625 12.5938 13.6609 12.5938 13.7812V15.0938ZM22.834 12.5973L18.3223 18.8508C18.282 18.9067 18.229 18.9522 18.1677 18.9836C18.1063 19.015 18.0384 19.0314 17.9695 19.0314C17.9006 19.0314 17.8327 19.015 17.7714 18.9836C17.7101 18.9522 17.6571 18.9067 17.6168 18.8508L14.4941 14.525C14.3902 14.3801 14.4941 14.1777 14.6719 14.1777H16.173C16.3125 14.1777 16.4437 14.2461 16.5258 14.3582L17.9695 16.357L20.8051 12.4277C20.8871 12.3129 21.0184 12.2473 21.1578 12.2473H22.6562C22.834 12.25 22.9379 12.4523 22.834 12.5973Z"
        fill={color}
        fillOpacity={0.85}
      />
    </svg>
  );
}
