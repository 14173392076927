import React, { forwardRef, useImperativeHandle, useState } from "react";
import "./returnPhoto.css";
import Drawer from "antd/lib/drawer";

const ReturnPhotoDrawer = forwardRef(({ image }: { image?: string }, ref) => {
  const [visible, setVisible] = useState(false);

  useImperativeHandle(ref, () => ({
    show: () => {
      setVisible(true);
    },
  }));

  const hide = () => {
    setVisible(false);
  };

  return (
    <Drawer
      closable={false}
      mask={false}
      closeIcon={<div />}
      onClose={hide}
      visible={visible}
      placement={"bottom"}
      height={window.innerHeight}
      bodyStyle={{ backgroundColor: "#f3f3f3" }}
    >
      {visible && (
        <div className={"return-photo-cntr"} onClick={hide}>
          <img className={"return-photo"} src={image} />
        </div>
      )}
    </Drawer>
  );
});

export default ReturnPhotoDrawer;
