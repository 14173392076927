import React from "react";
import "./kakao.css";
import { useTranslation } from "react-i18next";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import Button from "antd/lib/button";
import { addKakaoChannel } from "../../../services/kakao.service";

const KakaoContainer = () => {
  const { t } = useTranslation(["notify"]);

  return (
    <LeftTitledTemplate header={t("header")} title={t("title")}>
      <div className={"vehicle-container"}>
        <div className={"notify-para"}>
          <div className={"notify-title body1 bold"}>{t("title.info")}</div>
          <div className={"notice-text"}>{t("text.info")}</div>
        </div>
        <div className={"notify-para"}>
          <div className={"notify-title body1 bold"}>{t("title.policy")}</div>
          <div className={"notify-text"}>{t("text.policy")}</div>
        </div>
        <div className={"notify-para"}>
          <div className={"notify-block body bold"}>{t("title.block")}</div>
          <div className={"notify-block-route body"}>
            {t("text.block.route")}
          </div>
          <ul>
            <li className={"notify-text body"}>{t("text.block.dot.1")}</li>
            <li className={"notify-text body"}>{t("text.block.dot.2")}</li>
            <ol>
              <li className={"notify-text body"}>{t("text.block.1")}</li>
              <li className={"notify-text body"}>{t("text.block.2")}</li>
              <li className={"notify-text body"}>{t("text.block.3")}</li>
            </ol>
          </ul>
        </div>
      </div>
      <Button
        type={"primary"}
        className={"bottom-btn"}
        data-cy={"addKakao"}
        onClick={addKakaoChannel}
      >
        {t("btn")}
      </Button>
    </LeftTitledTemplate>
  );
};

export default KakaoContainer;
