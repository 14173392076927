import React from "react";
import "./notify.css";
import { useTranslation } from "react-i18next";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import Button from "antd/lib/button";
import { Switch } from "antd";
import { updateUser } from "../../../api/userApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import { useHistory } from "react-router-dom";

const NotifyScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { noti_allow_late } = useSelector((state: RootState) => state.profile);
  const { t } = useTranslation(["notify"]);

  function navigateKakao() {
    history.push("notify/kakao");
  }

  function setNotify(noti_allow_late: boolean) {
    updateUser({ noti_allow_late }, () => {}, dispatch);
  }

  return (
    <LeftTitledTemplate
      classname={"notify-container"}
      header={t("title.notify")}
    >
      <div className={"vehicle-container"}>
        <div className={"title bold black85 notify-label"}>
          {t("label.notify")}
        </div>
        <div className={"profile-round-cntr"}>
          <Button
            data-cy={"navEdu"}
            type={"link"}
            onClick={navigateKakao}
            className={"profile-divide-item"}
          >
            <div className={"body black85l"}>{t("set.kakao")}</div>
          </Button>
          <div />
          <div className={"profile-divider"} />
          <div className={"notify-divide-item row"}>
            <span className={"body black85l"}>{t("set.night.msg")}</span>
            <Switch
              checked={noti_allow_late}
              data-cy={"showHelm"}
              onChange={setNotify}
            />
          </div>
          {/*<div className={"profile-divider"} />*/}
          {/*<div className={"notify-divide-item row"}>*/}
          {/*  <span className={"body black85l"}>{t("app.push")}</span>*/}
          {/*  <Switch*/}
          {/*    data-cy={"showHelm"}*/}
          {/*    onChange={setLatePush}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
      </div>
    </LeftTitledTemplate>
  );
};

export default NotifyScreen;
