import React, { useState } from "react";
import styles from "./containerHistoryScreen.module.css";
import { useTranslation } from "react-i18next";
import { format, subMonths } from "date-fns";
import DatePicker from "../../molecules/datepicker/DatePicker";
import { ContainerType } from "../../../api/apiTypes";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import ArrowRight from "../../../assets/svgs/arrowRight.svg";
import ContainerHistoryItem from "../../organisms/containerHistory/ContainerHistoryItem";
import { HEADER_HEIGHT } from "../../../common/consts.common";
import EmptyContent from "../../organisms/emptyContent/EmptyContent";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import http from "../../../services/axios.service";
import { TokenStorage } from "../../../services/token.service";
import { showApiError } from "../../../fns/message";
import { InfiniteList } from "@delivus/react-infinite-list";

const ContainerHistoryScreen = () => {
  const { t } = useTranslation(["delivery"]);
  const [startDate, setStartDate] = useState(subMonths(new Date(), 1));
  const [endDate, setEndDate] = useState(new Date());
  const [refresh, setRefresh] = useState(false);
  const { type } = useSelector((state: RootState) => state.profile);

  const handleChangeStartDate = (date: any, dataStr: string) => {
    if (!!date) {
      setStartDate(date);
    }
    setRefresh((prev) => !prev);
  };

  const handleChangeEndDate = (date: any, dataStr: string) => {
    if (!!date) {
      setEndDate(date);
    }
    setRefresh((prev) => !prev);
  };

  const handleListFetchError = (error: any) => {
    showApiError(error);
  };

  return (
    <LeftTitledTemplate
      header={t("history.header")}
      classname={styles.container}
    >
      <div className={"round-white row " + styles.dateRow}>
        <div className={"flex border-right column align-center justify-center"}>
          <DatePicker
            value={startDate}
            allowClear={false}
            picker={"month"}
            data-cy={"dateStart"}
            className={styles.date + " body bold black85"}
            suffixIcon={<img src={ArrowRight} />}
            onChange={handleChangeStartDate}
          />
        </div>
        <div className={"flex column align-center justify-center"}>
          <DatePicker
            value={endDate}
            picker={"month"}
            allowClear={false}
            data-cy={"dateEnd"}
            className={styles.date + " body bold black85"}
            suffixIcon={<img src={ArrowRight} />}
            onChange={handleChangeEndDate}
          />
        </div>
      </div>
      <div className={"row " + styles.titleContainer}>
        <span className={"body1 black black85"}>{t("history.header")}</span>
        <span className={"small black45"}>{`\u00a0( ${format(
          startDate,
          "yyyy.MM"
        )} - ${format(endDate, "yyyy.MM")})`}</span>
      </div>
      <InfiniteList
        http={http as any}
        authenticated={!!type}
        // headers={TokenStorage.getAuthentication().headers}
        onApiFailed={handleListFetchError}
        url={`/flex/containers/finished/?start=${format(
          startDate,
          "yyyy-MM"
        )}&end=${format(endDate, "yyyy-MM")}`}
        refresh={refresh}
        height={"calc(100vh - " + (HEADER_HEIGHT + 130) + "px)"}
      >
        {(count?: number, results?: ContainerType[]) => {
          return (
            <div>
              {results && results?.length > 0 ? (
                results.map((order, index) => (
                  <ContainerHistoryItem key={"delivery" + index} {...order} />
                ))
              ) : (
                <EmptyContent label={t("empty.history")} />
              )}
            </div>
          );
        }}
      </InfiniteList>
    </LeftTitledTemplate>
  );
};

export default ContainerHistoryScreen;
