import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import Radio from "antd/lib/radio";
import styles from "./selectNavi.module.css";
import { AddressType } from "../../../api/apiTypes";
import { useDispatch } from "react-redux";
import { navigateMapApp } from "../../../fns/commonFns";
import { updateNaviMap } from "../../../reducers/commonReducer";

type Props = {
  onOk?: () => void;
};

export enum NaviAppEnum {
  kakao = "kakao",
  tmap = "tmap",
  naver = "naver",
}

const SelectNaviPopup = forwardRef(({ onOk }: Props, ref) => {
  const { t } = useTranslation(["delivery"]);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [method, setMethod] = useState<NaviAppEnum>(NaviAppEnum.tmap);
  const addressRef = useRef<AddressType>();

  useImperativeHandle(
    ref,
    () => ({
      show: (address: AddressType) => {
        setVisible(!!address);
        addressRef.current = address;
      },
    }),
    []
  );

  const hide = () => {
    setVisible(false);
  };

  const navigateToApp = () => {
    const address = addressRef.current;
    hide();
    if (!address) return;
    navigateMapApp(method, address);
  };

  const useAppAlways = () => {
    navigateToApp();
    dispatch(updateNaviMap(method));
  };

  const handleChangeMethod = (e: any) => {
    const value = e.target.value;
    setMethod(value);
  };

  const footerNode = (
    <div className={"column"}>
      <Button
        className={"popup-btn height ok border"}
        type={"text"}
        data-cy={"navigateHelmet"}
        onClick={navigateToApp}
      >
        {t("select.navi.popup.btn.ok")}
      </Button>

      <Button
        className={"popup-btn height ok border"}
        type={"text"}
        data-cy={"navigateHelmet"}
        onClick={useAppAlways}
      >
        {t("select.navi.popup.btn.allways")}
      </Button>

      <Button
        className={"popup-btn height ok border"}
        type={"text"}
        data-cy={"navigateHelmet"}
        onClick={hide}
      >
        {t("select.navi.popup.btn.close")}
      </Button>
    </div>
  );

  return (
    <Modal
      closable={false}
      visible={visible}
      onOk={onOk}
      centered
      onCancel={hide}
      footer={footerNode}
    >
      <div className={"column align-center"}>
        <div className={"popup-title"}>{t("select.navi.popup.title")}</div>
        <div className={"body popup-message helmet-popup-content"}>
          {t("select.navi.popup.content")}
        </div>
        <Radio.Group
          className={"column"}
          onChange={handleChangeMethod}
          value={method}
        >
          <Radio value={"tmap"} className={"small bold white " + styles.radio}>
            {t("tmap")}
          </Radio>
          <Radio value={"kakao"} className={"small bold white " + styles.radio}>
            {t("kakao")}
          </Radio>
          <Radio value={"naver"} className={"small bold white " + styles.radio}>
            {t("naver")}
          </Radio>
        </Radio.Group>
      </div>
    </Modal>
  );
});

export default SelectNaviPopup;
