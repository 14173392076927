import React from "react";
import "../login/loginPhoneScreen.css";
import { useDispatch } from "react-redux";
import BankView from "./BankView";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showMessage } from "../../../fns/message";
import { updateUserBank } from "../../../api/userApi";
import { UpdatePhotoApiParam } from "../../../api/apiTypes";

const ProfileBankScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["profile"]);

  const onAdd = (param: UpdatePhotoApiParam) => {
    updateUserBank(param, navigateNext, dispatch);
  };

  const navigateNext = () => {
    showMessage(t("profile.bank.save"));
    setTimeout(() => {
      history.replace("/home/profile");
    }, 1000);
  };

  return <BankView btnText={t("sector.btn")} onNext={onAdd} />;
};

export default ProfileBankScreen;
