import React, { useCallback, useMemo, useState } from "react";
import styles from "./deliveryinContainerScreen.module.css";
import { useTranslation } from "react-i18next";
import { AddressType, DeliveryListItemType } from "../../../api/apiTypes";
import CurrentDeliveryItem from "../../organisms/currentDelivery/CurrentDeliveryItem";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import EmptyContent from "../../organisms/emptyContent/EmptyContent";
import http from "../../../services/axios.service";
import { showApiError } from "../../../fns/message";
import { InfiniteList } from "@delivus/react-infinite-list";

interface Params {
  params: {
    uuid: string;
    box: string;
    unit: string;
  };
}
const DeliveryinContainerScreen = ({ match }: { match: Params }) => {
  const { uuid, unit, box } = match.params; // selected order uuid
  const { t } = useTranslation(["delivery"]);
  const [refresh] = useState(false);
  const history = useHistory();
  const title = useMemo(() => unit + t("count.divider") + box, []);
  const { type } = useSelector((state: RootState) => state.profile);

  const navigateMap = useCallback((address: AddressType, uuid?: string) => {
    history.push(`/home/${uuid}/delivery`);
  }, []);

  const handleListFetchError = (error: any) => {
    showApiError(error);
  };

  return (
    <LeftTitledTemplate header={title} classname={styles.container}>
      <InfiniteList
        url={`/flex/containers/${uuid}/deliveries/`}
        refresh={refresh}
        http={http as any}
        authenticated={!!type}
        // headers={TokenStorage.getAuthentication().headers}
        onApiFailed={handleListFetchError}
        height={window.innerHeight - 50}
      >
        {(count?: number, results?: DeliveryListItemType[]) => {
          return (
            <div className={""}>
              <div className={styles.row}>
                <div className={"title bold"}>{t("title.done")}</div>
              </div>
              {results && results?.length > 0 ? (
                <div>
                  {results.map((order, index) => (
                    <CurrentDeliveryItem
                      key={"delivery" + index}
                      index={index}
                      onMap={navigateMap}
                      {...order}
                    />
                  ))}
                </div>
              ) : (
                <EmptyContent
                  title={t("done.empty.title")}
                  label={t("done.empty.content")}
                />
              )}
            </div>
          );
        }}
      </InfiniteList>
    </LeftTitledTemplate>
  );
};

export default DeliveryinContainerScreen;
