import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SignupTermContainer from "../components/pages/signupTerm/SignupTermContainer";
import SignupBankScreen from "../components/pages/bank/SignupBankScreen";
import SignupSectorContainer from "../components/pages/sector/SignupSectorContainer";
import SignupVehicleContainer from "../components/pages/vehicle/SignupVehicleContainer";
import LoginPhoneContainer from "../components/pages/login/LoginPhoneContainer";
import LaunchContainer from "../components/pages/launch/LaunchScreen";
import SignupRouteComponent from "./SignupRouteComponent";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/reducer";
function AuthRoutes() {
  const { res_seq, fullname, phonenumber } = useSelector(
    (state: RootState) => state.profile
  );
  const renderAuthRedirect = () => <Redirect to={"/auth"} />;
  return (
    <Switch>
      <Route exact path="/auth" component={LaunchContainer} />
      <SignupRouteComponent
        isIdentified={!!(fullname && phonenumber && res_seq)}
        path="/auth/term"
        component={SignupTermContainer}
      />
      <SignupRouteComponent
        isIdentified={!!(fullname && phonenumber && res_seq)}
        path="/auth/account"
        component={SignupBankScreen}
      />
      <SignupRouteComponent
        isIdentified={!!(fullname && phonenumber && res_seq)}
        path="/auth/sector"
        component={SignupSectorContainer}
      />
      <Route exact path="/auth/vehicle" component={SignupVehicleContainer} />
      <Route exact path="/auth/login" component={LoginPhoneContainer} />
      <Route path="*" component={renderAuthRedirect} />
    </Switch>
  );
}

export default AuthRoutes;
