import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  GetDeliveryDetailApiResponse,
  ShippingItemType,
} from "../../../api/apiTypes";
import {
  getDeliveriesCount,
  getDeliveryDetail,
} from "../../../api/shippingApi";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  updateClaimSts,
  updateDeliveryCount,
} from "../../../reducers/commonReducer";
import { countDeliveries } from "../../../fns/commonFns";
import Button from "antd/lib/button";
import Copy from "../../../assets/svgs/copy.svg";
import { callLink, messageLink } from "../../../common/consts.common";
import Message from "../../../assets/svgs/message.svg";
import Phone from "../../../assets/svgs/phone.svg";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import { Tag } from "antd";
import ShippingItem from "../../organisms/shippingItem/ShippingItem";
import PopupSpinner from "../../molecules/spinner/PopupSpinnerView";
import copy from "copy-to-clipboard";
import { showMessage, showMessageWithTitle } from "../../../fns/message";
import styles from "./deliveryScreen.module.css";
import EntryEditPopup from "../../organisms/entryEdit/EntryEditPopup";
import { getUserClaimCount } from "../../../api/userApi";
interface Params {
  params: {
    uuid: string;
    map: boolean;
  };
}
const DeliveryScreen = ({ match }: { match: Params }) => {
  const { uuid, map } = match.params;
  const history = useHistory();
  const dispatch = useDispatch();
  const editPopupRef = useRef<any>();
  const { t } = useTranslation(["delivery"]);
  const [delivery, setDelivery] = useState<GetDeliveryDetailApiResponse>();
  const { type } = useSelector((state: RootState) => state.profile);
  const { pendingShippings } = useSelector((state: RootState) => state.common);

  useEffect(() => {
    if (!!type && !!uuid && navigator.onLine) {
      getDeliveryDetail(uuid, onSuccessFirst);
    }
  }, [type, uuid, pendingShippings]);

  const firstShipping: ShippingItemType | undefined = useMemo(() => {
    if (delivery?.items && delivery?.items.length > 0)
      return delivery?.items[0];
    else return;
  }, [delivery]);

  const deliveryCompleteOn = useMemo(
    () =>
      !!delivery &&
      delivery?.items?.length > 0 &&
      delivery?.items[0].delivery_info
        ? delivery?.items[0].delivery_info?.delivery_complete_on
        : "",
    [delivery]
  );

  const handleEditedEntryIfo = () => {
    getDeliveryDetail(uuid, onSuccessFirst);
  };

  const showPhonePopup = () => {
    showMessageWithTitle(
      t("popup.phone.title"),
      t("popup.phone.content"),
      t("popup.phone.btn.ok"),
      handlePhone,
      t("popup.phone.btn.cancel")
    );
  };

  const handleCopyAddress = () => {
    if (!!address) {
      document.execCommand("copy", true, address);
      copy(address);
      showMessage(t("copy.address"));
    } else {
      showMessage(t("error.copy.empty"));
    }
  };

  const handlePhone = () => {
    window.location.href = callLink(delivery?.address?.mobile_tel);
  };

  const handleRefresh = () => {
    if (!!uuid) {
      getDeliveryDetail(uuid, onSuccess);
    }
  };

  const onSuccessFirst = (data: GetDeliveryDetailApiResponse) => {
    setDelivery(data);
  };

  const onSuccess = (data: GetDeliveryDetailApiResponse) => {
    setDelivery(data);
    if (
      data.shipping_count <=
        data.shipping_count_completed + data.shipping_count_incompleted &&
      data.return_count <=
        data.return_count_completed + data.return_count_incompleted
    ) {
      getDeliveriesCount((res) => {
        dispatch(updateDeliveryCount(countDeliveries(res.results)));
      });
      getUserClaimCount((res) => {
        dispatch(updateClaimSts(res));
      });
      history.goBack();
    }
  };

  const nameNode = (
    <div className={"row align-center"}>
      <div className={"subtitle2 black"}>
        {t("name", { name: delivery?.address?.name })}
      </div>
    </div>
  );

  const address =
    delivery?.address?.address_road || delivery?.address?.address1;
  const address_jibon =
    delivery?.address?.address_jibun || delivery?.address?.address1;

  const addressNode = (
    <div className={styles.row}>
      <div className={styles.col}>
        <div className={"small bold black45"}>{t("address")}</div>
        <div className={"body1 bold black85"}>
          {address + "\u00a0" + delivery?.address?.address2}
        </div>
        <span className={"small black45"}>
          <span className={"bold black45"}>{t("addr.jibon")}</span>
          {"\u00a0" + address_jibon}
        </span>
      </div>
      <Button
        data-cy={"copyAddress"}
        type={"text"}
        shape={"circle"}
        className={styles.icon}
        onClick={handleCopyAddress}
      >
        <img alt={"copy"} className={styles.img} src={Copy} />
      </Button>
    </div>
  );

  const phoneNode = (
    <div className={styles.row}>
      <div className={styles.col + " flex " + styles.divider}>
        <div className={"small bold black45"}>{t("phone")}</div>
        <div className={"subtitle black85 nowrap"}>
          {delivery?.address?.mobile_tel}
        </div>
      </div>
      <Button
        type={"text"}
        shape={"circle"}
        data-cy={"phoneNode"}
        className={styles.icon + " " + styles.iconMobile}
        href={messageLink(delivery?.address?.mobile_tel)}
      >
        <img alt={"msg"} src={Message} className={styles.img} />
      </Button>
      <Button
        type={"text"}
        shape={"circle"}
        className={styles.icon + " " + styles.iconMobile}
        data-cy={"showPhone"}
        onClick={showPhonePopup}
      >
        <img alt="phone" src={Phone} className={styles.img} />
      </Button>
    </div>
  );

  const entryNode = firstShipping && (
    <div className={"delivery-detail-row"}>
      <div className={"delivery-detail-col"}>
        <div className={"small bold primary"}>{t("item.entrance")}</div>
        {firstShipping?.delivery_info?.entrance_password ? (
          <Tag className={"body bold delivery-detail-info primary"}>
            {firstShipping?.delivery_info?.entrance_option === "GATECODE" &&
              firstShipping?.delivery_info?.entrance_password}
          </Tag>
        ) : (
          <span className={"small black45 delivery-detail-info"}>
            {t("item.entry.empty.info")}
          </span>
        )}
      </div>
      <Button
        type={"text"}
        data-cy={"delvEdit"}
        className={"black45 small delivery-detail-btn-edit"}
        onClick={() => editPopupRef.current.show()}
      >
        {t("item.btn.edit")}
      </Button>
    </div>
  );

  return (
    <LeftTitledTemplate
      header={t("name", { name: delivery?.address?.name })}
      headerProps={{
        onLeft: history.goBack,
        right: deliveryCompleteOn ? (
          <Tag>{t(deliveryCompleteOn)}</Tag>
        ) : undefined,
      }}
      classname={styles.container}
    >
      {delivery ? (
        <>
          {nameNode}
          <div className={styles.whiteRoundCntr}>
            {addressNode}
            {phoneNode}
            {entryNode}
          </div>
          <div className={"body bold " + styles.shippingTitle}>
            {t("items")}
          </div>
          {delivery?.items &&
            delivery?.items.map((item, index) => (
              <ShippingItem
                key={"shipping" + index}
                item={item}
                address={delivery.address}
                delivery_uuid={uuid}
                onCompleted={handleRefresh}
                photos={delivery.photos}
                sector={delivery.address?.sector?.name}
              />
            ))}
          <div className={styles.space} />
        </>
      ) : (
        <PopupSpinner />
      )}
      <EntryEditPopup
        ref={editPopupRef}
        isEdit={!!firstShipping}
        shippingItem={firstShipping}
        onEdited={handleEditedEntryIfo}
      />
    </LeftTitledTemplate>
  );
};

export default DeliveryScreen;
