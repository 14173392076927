import React, { useEffect, useRef, useState, useCallback } from "react";
import styles from "./mapScreen.module.css";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import {
  ContainerType,
  DeliveryListItemType,
  GetDeliveryListApiResponse,
} from "../../../api/apiTypes";
import { getDeliveriesInContainer } from "../../../api/shippingApi";
import "swiper/swiper.min.css";
import MapDeliveryList from "../../organisms/mapDeliveries/MapDeliveryList";
import { DaasMap } from "@delivus/daas-naver-map";
import LeftTitledTemplate from "../../templates/leftTitled/LeftTitledTemplate";
import { useHistory } from "react-router-dom";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

interface Params {
  params: {
    uuid: string;
    alias: string;
  };
}

const MapContainerScreen = ({ match }: { match: Params }) => {
  let { uuid, alias } = match.params; // selected item uuid
  const deliverySwiperRef = useRef<any>();
  const mapRef = useRef<any>();
  const allDeliveriesRef = useRef<DeliveryListItemType[]>();
  const [selectedContainers, setSelectedContainers] = useState<ContainerType[]>(
    []
  );
  const [swiperDeliveries, setSwiperDeliveries] = useState<
    DeliveryListItemType[]
  >();
  const [visibleRecentContainer, setVisibleRecentContainer] = useState(true);
  const { type: user_type } = useSelector((state: RootState) => state.profile);
  const history = useHistory();

  useEffect(() => {
    if (!!user_type) {
      refreshDeliveries();
    }
  }, [user_type]);

  const onSuccessGetDeliveries = useCallback(
    (data: GetDeliveryListApiResponse) => {
      allDeliveriesRef.current = data?.results;
      mapRef.current && mapRef.current.drawDeliveriesMarkers(data?.results);
      mapRef.current &&
        data?.results &&
        mapRef.current.selectDeliveryMarkerByUuid(data?.results[0].uuid);
      handleClickDeliverCluster(data?.results, 0);
    },
    []
  );

  const refreshDeliveries = useCallback(() => {
    getDeliveriesInContainer(uuid, onSuccessGetDeliveries);
  }, [uuid, onSuccessGetDeliveries]);

  const handleSlidedToDelivery = (uuid: string) => {
    mapRef.current && mapRef.current.selectDeliveryMarkerByUuid(uuid);
  };

  const handleClickDeliverMarker = useCallback((index: number) => {
    setSwiperDeliveries(allDeliveriesRef.current);
    setSelectedContainers([]);
    deliverySwiperRef.current &&
      deliverySwiperRef.current.setSelectedIndex(index);
  }, []);

  const handleClickDeliverCluster = useCallback(
    (deliveries: any[], index: number) => {
      setSwiperDeliveries(deliveries);
      deliverySwiperRef.current.setSelectedIndex(index);
    },
    []
  );

  const handleClickMap = useCallback(() => {
    setSwiperDeliveries([]);
    setVisibleRecentContainer(false);
    setSelectedContainers([]);
  }, [selectedContainers, visibleRecentContainer]);

  const navigateBack = () => {
    history.goBack();
  };

  return (
    <LeftTitledTemplate
      classname={styles.mapContainer}
      header={alias}
      headerProps={{ onLeft: navigateBack }}
    >
      <DaasMap
        ref={mapRef}
        mapClass={"map-class"}
        isDeliveryVisible
        isVisibleMarkers
        onClickMap={handleClickMap}
        onClickDelivery={handleClickDeliverMarker}
        onClickDeliveryCluster={handleClickDeliverCluster}
      >
        <div
          className={
            styles.floatingContainer + " " + styles.floatingMapContainer
          }
        >
          {swiperDeliveries && swiperDeliveries.length > 0 && (
            <MapDeliveryList
              ref={deliverySwiperRef}
              deliveries={swiperDeliveries}
              onSelectedChange={handleSlidedToDelivery}
            />
          )}
        </div>
      </DaasMap>
    </LeftTitledTemplate>
  );
};

export default MapContainerScreen;
