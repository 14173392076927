import React, { useState } from "react";
import "./vehichle.style.css";
import { useHistory } from "react-router-dom";
import VehicleView from ".//VehicleView";
import vehicles from "../../../datas/vehicles.data.json";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateUser } from "../../../api/userApi";
import { RootState } from "../../../reducers/reducer";
import { showMessage } from "../../../fns/message";

const ProfileVehicleContainer = () => {
  const history = useHistory();
  const { t } = useTranslation(["profile"]);
  const { ride_type } = useSelector((state: RootState) => state.profile);

  const dispatch = useDispatch();
  const [selectedRide, setRideType] = useState("");

  const updateRideType = () => {
    if (selectedRide) {
      updateUser({ ride_type: selectedRide }, navigateNext, dispatch);
    } else {
      showMessage(t("vehicle.popup.select"));
    }
  };

  const navigateNext = () => {
    showMessage(t("vehicle.save"));
    setTimeout(() => {
      history.replace("/home/profile");
    }, 1000);
  };

  const saveRideType = (vehicle: string) => {
    setRideType(vehicle);
  };

  return (
    <VehicleView
      rideType={selectedRide || ride_type}
      vehicles={vehicles}
      onSelectVehicle={saveRideType}
      btnText={t("vehicle.btn")}
      onNext={updateRideType}
    />
  );
};

export default ProfileVehicleContainer;
