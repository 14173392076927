import React, { useRef, useState } from "react";
import Button from "antd/lib/button";
import Tooltip from "antd/lib/tooltip";
import "./profile.style.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import {
  ASK_LINK,
  CONTACT_PHONE,
  GUIDE_LINK,
  TERM_LINK,
} from "../../../common/consts.common";
import HelmetPopup from "../../organisms/helmet/HelmetPopup";
import InstallTooltipContent from "../../molecules/install/InstallTooltipContent";
import InstallButton from "../../molecules/install/InstallButton";
import { useHistory } from "react-router-dom";
import { TokenStorage } from "../../../services/token.service";
import { clearProfile } from "../../../reducers/profileReducer";
import { clearCommon } from "../../../reducers/commonReducer";
import { signout, inactivateAccount } from "../../../api/authApi";
import { addKakaoChannel } from "../../../services/kakao.service";
import {showApiError, showMessageWithTitle} from "../../../fns/message";
import { BunnyType } from "../../../reducers/reducerTypes";

const ProfileScreen = () => {
  const { t } = useTranslation(["profile"]);
  const { t: tRide } = useTranslation(["ride"]);
  const dispatch = useDispatch();
  const {
    fullname,
    photo_bank,
    sector,
    ride_type,
    helmet_verified,
    bunny_type,
  } = useSelector((state: RootState) => state.profile);
  const { naviMap } = useSelector((state: RootState) => state.common);
  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const helmetRef = useRef<any>();
  const history = useHistory();

  const showHelmet = () => {
    helmetRef.current.show();
  };

  const showTooltip = () => {
    setVisibleTooltip(true);
  };

  const showLogout = () => {
    showMessageWithTitle(
      t("profile.logout"),
      t("popup.logout.content"),
      t("profile.logout"),
      handleLogout,
      t("popup.logout.btn.cncl")
    );
  };

  const showInactivate = () => {
    showMessageWithTitle(
      t("profile.inactivate"),
      t("popup.inactivate.content"),
      t("profile.inactivate"),
      handleInactivate,
      t("popup.logout.btn.cncl")
    );
  };

  const hideTooltip = () => {
    setVisibleTooltip(false);
  };

  const navigateHistory = () => {
    history.push("/profile/history");
  };

  const navigateEdu = () => {
    history.push("/profile/edu");
  };

  const navigateNotify = () => {
    history.push("/profile/notify");
  };

  const navigateVehicle = () => {
    history.push("/profile/vehicle");
  };

  const navigateBank = () => {
    history.push("/profile/bank");
  };

  const navigateSelectNavi = () => {
    history.push("/profile/navi");
  };

  const showCallPopup = () => {
    showMessageWithTitle(
      <a className={"body1 white"} href={"tell:" + CONTACT_PHONE}>
        {CONTACT_PHONE}
      </a>,
      "",
      t("profile.btn.close")
    );
  };

  const navigateSector = () => {
    history.push("/profile/sector");
  };

  const handleLogout = () => {
    const refresh = TokenStorage.getRefreshToken() || "";
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ key: "logout" }));
    }
    signout(
      refresh,
      () => {
        TokenStorage.clear();
        dispatch(clearProfile());
        dispatch(clearCommon());
        history.replace("/auth/login");
      },
      () => {
        TokenStorage.clear();
        dispatch(clearProfile());
        dispatch(clearCommon());
        history.replace("/auth/login");
      }
    );
  };

  const handleInactivate = () => {
    const refresh = TokenStorage.getRefreshToken() || "";
    inactivateAccount(refresh, () => {
      TokenStorage.clear();
      dispatch(clearProfile());
      dispatch(clearCommon());
      history.replace("/auth/login");

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ key: "logout" }));
    }
    });
  };

  const nameNode = (
    <div data-cy={"nameMode"} className={"profile-name-container"}>
      <div>
        <div className={"white small bold"}>ID</div>
        <span>
          <span className={"white title"}>{`${fullname} 님 |`}</span>
          <span className={"profile-ride bold white small"}>
            {tRide(ride_type)}
          </span>
        </span>
        <div className={"profile-bunny_type small bold text-center"}>
          {bunny_type}
        </div>
      </div>
      <InstallButton onIOS={showTooltip} onIOSMouseLeave={hideTooltip} />
    </div>
  );

  const rowNode = (
    <div className={"profile-row-container"}>
      <Button
        data-cy={"navHistory"}
        onClick={navigateHistory}
        className={"profile-btn"}
      >
        <div className={"body bold profile-btn-text"}>
          {t("profile.delivery")}
        </div>
      </Button>
      <div className={"profile-row-div"} />
      <Button
        data-cy={"navNotify"}
        onClick={navigateNotify}
        className={"profile-btn"}
      >
        <div className={"body bold profile-btn-text"}>
          {t("profile.notify")}
        </div>
      </Button>
      <div className={"profile-row-div"} />
      <Button
        data-cy={"openAsk"}
        onClick={() => window.open(ASK_LINK)}
        className={"profile-btn"}
      >
        <div className={"body bold profile-btn-text"}>{t("profile.faq")}</div>
      </Button>
    </div>
  );

  const requiredNode = (
    <>
      <p className={"profile-title body1 bold"}>{t("profile.required")}</p>
      <div className={"profile-round-cntr"}>
        <Button
          data-cy={"navEdu"}
          onClick={navigateEdu}
          className={"profile-divide-item"}
        >
          <div className={"body profile-label"}>{t("profile.edu")}</div>
        </Button>
        <div />
        {ride_type === "BIKE" && (
          <>
            <div className={"profile-divider"} />
            <Button
              data-cy={"showHelm"}
              onClick={showHelmet}
              className={"profile-divide-item"}
            >
              <div className={"body profile-label"}>{t("profile.helmet")}</div>
              <div className={"small profile-link"}>
                {helmet_verified
                  ? t("helmet.verified")
                  : t("helmet.notverified")}
              </div>
            </Button>
          </>
        )}
      </div>
    </>
  );

  const registeredNode = (
    <div>
      <p className={"profile-title body1 bold"}>{t("profile.register")}</p>
      <div className={"profile-round-cntr"}>
        <Button
          data-cy={"navVehicle"}
          onClick={navigateVehicle}
          className={"profile-divide-item"}
        >
          <div className={"body profile-label"}>{t("profile.ride")}</div>
          {!!ride_type ? (
            <div className={"small profile-link"}>{tRide(ride_type)}</div>
          ) : (
            <span className={"small medium black45"}>{t("not.selected")}</span>
          )}
        </Button>
        <div />
        <div className={"profile-divider"} />
        <Button
          data-cy={"navSector"}
          onClick={navigateSector}
          className={"profile-divide-item"}
        >
          <div className={"body profile-label"}>{t("profile.sector")}</div>
          <div className={"small profile-link"}>
            {sector && sector.length > 0 ? (
              sector.map((s, index) => {
                console.log("sector", s.name);
                return (
                  <span key={"sector_" + index}>
                    {s.name}
                    <span>{index + 1 < sector.length ? "/" : ""}</span>
                  </span>
                );
              })
            ) : (
              <span className={"small medium black45"}>
                {t("not.selected")}
              </span>
            )}
          </div>
        </Button>
        <div className={"profile-divider"} />
        <Button
          data-cy={"navBank"}
          onClick={navigateBank}
          className={"profile-divide-item text-inside"}
        >
          <div className={"body profile-label"}>{t("profile.bank")}</div>
          {photo_bank ? (
            <div className={"small profile-link text-inside"}>
              {t("registered")}
            </div>
          ) : (
            <span className={"small medium black45"}>
              {t("not.registered")}
            </span>
          )}
        </Button>
        <div className={"profile-divider"} />
        <Button
          data-cy={"openNavi"}
          onClick={navigateSelectNavi}
          className={"profile-divide-item"}
        >
          <div className={"body profile-label"}>{t("profile.map")}</div>
          {naviMap ? (
            <div className={"small profile-link"}>{t(naviMap)}</div>
          ) : (
            <span className={"small medium black45"}>
              {t("not.registered")}
            </span>
          )}
        </Button>
        <div className={"profile-divider"} />
        <Button
          data-cy={"openGuide"}
          onClick={() => window.open(GUIDE_LINK)}
          className={"profile-divide-item"}
        >
          <div className={"body profile-label"}>{t("profile.guide")}</div>
        </Button>
      </div>
    </div>
  );

  const contactNode = (
    <div>
      <p className={"profile-title body1 bold"}>{t("profile.center")}</p>
      <div className={"profile-round-cntr"}>
        <Button
          data-cy={"callPopup"}
          className={"profile-divide-item"}
          href={"tell:" + CONTACT_PHONE}
          onClick={showCallPopup}
        >
          <a className={"body profile-label"}>{CONTACT_PHONE}</a>
          <div className={"small primary"}>{t("profile.working.time")}</div>
        </Button>
        <div className={"profile-divider"} />
        <Button
          data-cy={"kakao"}
          onClick={addKakaoChannel}
          className={"profile-divide-item"}
        >
          <div className={"body profile-label"}>{t("profile.contact")}</div>
        </Button>
        <div className={"profile-divider"} />
        <Button
          data-cy={"terms"}
          onClick={() => window.open(TERM_LINK)}
          className={"profile-divide-item"}
        >
          <div className={"body profile-label"}>{t("profile.term")}</div>
        </Button>
      </div>
    </div>
  );

  const inactivateNode = (
    <div className={"profile-logout-cntr"}>
      <Button
        data-cy={"logout"}
        onClick={showLogout}
        className={"profile-logout-btn"}
      >
        {t("profile.logout")}
      </Button>
      <Button
        data-cy={"inactivate"}
        onClick={showInactivate}
        className={"profile-logout-btn"}
      >
        {t("profile.inactivate")}
      </Button>
    </div>
  );

  return (
    <div className={"container profile-container"}>
      <div className={"profile-inner-container"}>
        <Tooltip
          placement="top"
          visible={visibleTooltip}
          className={"tooltip"}
          title={<InstallTooltipContent />}
        >
          <div className={"install-bottom"} />
        </Tooltip>
        {nameNode}
        <div className={"inner-container"}>
          {rowNode}
          {requiredNode}
          {registeredNode}
          {contactNode}
          {inactivateNode}
        </div>
      </div>
      <HelmetPopup ref={helmetRef} />
    </div>
  );
};

export default ProfileScreen;
