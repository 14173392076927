import React, { useEffect, useRef, useState, useCallback } from "react";
import Button from "antd/lib/button";
import styles from "./mapScreen.module.css";

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import RefreshOutlined from "../../../assets/svgs/refreshOutlined.svg";
import MyLocationSvg from "../../../assets/svgs/nearby.svg";
import { CompassSvg } from "../../../assets/svgs/btn_compass";
import EyesSvg from "../../../assets/svgs/eyes.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";
import {
  ContainerType,
  DeliveryListItemType,
  GetDeliveryListApiResponse,
} from "../../../api/apiTypes";
import {
  DeliveryFilter,
  getAssignedContainers,
  getClaimedContainers,
  getCurrentDeliveries,
  getDeliveriesCount,
  getDeliveriesInContainer,
  getFinishedContainers,
} from "../../../api/shippingApi";
import { useTranslation } from "react-i18next";
import "swiper/swiper.min.css";
import { useHistory } from "react-router-dom";
import {
  updateDeliveryCount,
  updateClaimedOrder, updateAssignedOrder, updateShowedInstallAlarm,
} from "../../../reducers/commonReducer";
import { countDeliveries } from "../../../fns/commonFns";
import MapDeliveryList from "../../organisms/mapDeliveries/MapDeliveryList";
import MapContainerList from "../../organisms/mapContainers/MapContainerList";
import MapRecentContainer from "../../organisms/mapRecentContainer/MapRecentContainer";
import MapWarningEdu from "../../organisms/mapWarningEdu/MapWarningEdu";
import { isIOS } from "react-device-detect";
import { DaasMap } from "@delivus/daas-naver-map";
import {
  clearWatchLocation,
  getCurrentPosition,
  watchLocation,
} from "../../../services/location.service";
import { showApiError, showMessageWithTitle } from "../../../fns/message";
import {LOCATION_LINK, STORE_DIRECT_LINK} from "../../../common/consts.common";
import { DeliverySortType } from "../../../reducers/reducerTypes";
import { clearDeliveryUuid } from "../../../reducers/profileReducer";
import {differenceInHours} from "date-fns";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

interface Params {
  params: {
    uuid?: string;
    preview?: true;
    type?: "order" | "delivery";
  };
}

const MapScreen = ({ match }: { match: Params }) => {
  let { uuid, type, preview } = match.params; // selected item uuid
  const { t } = useTranslation(["delivery"]);
  const history = useHistory();
  const watchIdRef = useRef<any>();
  const deliverySwiperRef = useRef<any>();
  const locationErrorRef = useRef(true);
  const activeDeliveryIdx = useRef("");
  const containersRef = useRef<ContainerType[]>([]);
  let initialOrientationOffset: number;
  const dispatch = useDispatch();
  const mapRef = useRef<any>();
  const isPreviewed = useRef(false);
  const allDeliveriesRef = useRef<DeliveryListItemType[]>();
  const [isCompassEnabled, setIsCompassEnabled] = useState(false);
  const [isControlVisible, setIsControlVisible] = useState(true);
  const [isContainerVisible, setIsContainerVisible] = useState(true);
  const [isDeliveryVisible, setIsDeliveryVisible] = useState(true);
  const [isMarkerVisible, setIsMarkerVisible] = useState(true);
  const [isPreviewEnabled, setIsPreviewEnabled] = useState(false);
  const [filterBy, setFilterBy] = useState<DeliveryFilter>("pending");
  const [selectedContainers, setSelectedContainers] = useState<ContainerType[]>(
    []
  );
  const { isFromRN, showedInstallAlarm } = useSelector(
      (state: RootState) => state.common
  );
  const [swiperDeliveries, setSwiperDeliveries] = useState<
    DeliveryListItemType[]
  >();
  const [visibleRecentContainer, setVisibleRecentContainer] = useState(true);
  const { type: user_type, clickedDeliveryUuid } = useSelector(
    (state: RootState) => state.profile
  );

  useEffect(() => {
    let hours = 25;
    if (showedInstallAlarm) {
      hours = Math.abs(
          differenceInHours(new Date(), new Date(showedInstallAlarm))
      );
    }
    if (hours > 24 && !isFromRN) {
      dispatch(updateShowedInstallAlarm());
      showMessageWithTitle(
          t("앱 출시"),
          t(
              "딜리래빗 앱이 출시되었습니다. \n" +
              "새로운 기능과 원활한 서비스 이용을 위해 \n" +
              "지금 바로 설치하시겠습니까?"
          ),
          t("앱 설치하기"),
          STORE_DIRECT_LINK,
          t("나중에")
      );
    }
  }, [showedInstallAlarm]);

  useEffect(() => {
    return () => {
      watchIdRef.current && clearWatchLocation(watchIdRef.current);
      window.removeEventListener(
        "deviceorientation",
        handleDeviceOrientationIOS
      );
      window.removeEventListener(
        "deviceorientationabsolute",
        handleDeviceOrientationAndroid
      );
    };
  }, []);

  useEffect(() => {
    if (type == "order") {
      filterContainerPreview();
    }
  }, [type, preview, uuid]);

  useEffect(() => {
    if (!!user_type) {
      fetchContainers();
    }
  }, [user_type]);

  useEffect(() => {
    if (!!user_type) {
      refreshDeliveries();
    }
  }, [filterBy, user_type]);

  const filterContainerPreview = () => {
    let filtered: ContainerType[] = [];
    if (!isPreviewed.current && !!uuid) {
      for (var i in containersRef.current) {
        const c = containersRef.current[i];
        if (uuid === c.uuid) {
          filtered = [c];
          isPreviewed.current = true;
          !!preview && handlePreview(c.uuid);
          break;
        }
      }
      setSelectedContainers(filtered);
    }
  };

  const fetchContainers = useCallback(async () => {
    containersRef.current = [];
    try {
      let assigned = await getAssignedContainers();
      dispatch(updateAssignedOrder(assigned.data.count));
      if (assigned.data.count > 10) {
        //if more than default page_size
        assigned = await getAssignedContainers(assigned.data.count);
      }
      containersRef.current = containersRef.current.concat(
          assigned.data.results
      );
    } catch (error) {
      showApiError(error);
    }
    try {
      let claimed = await getClaimedContainers();
      if (claimed.data.count > 10) {
        //if more than default page_size
        claimed = await getClaimedContainers(claimed.data.count);
      }
      dispatch(updateClaimedOrder(claimed.data.count));
      containersRef.current = containersRef.current.concat(
        claimed.data.results
      );
    } catch (error) {
      showApiError(error);
    }
    try {
      let finished = await getFinishedContainers();
      if (finished.data.count > 10) {
        //if more than default page_size
        finished = await getFinishedContainers(finished.data.count);
      }
      containersRef.current = containersRef.current.concat(
        finished.data.results
      );
    } catch (error) {
      showApiError(error);
    }
    // try {
    //   let returning = await getReturningContainers();
    //   if (returning.data.count > 10) {
    //     //if more than default page_size
    //     returning = await getReturningContainers(returning.data.count);
    //   }
    //   containersRef.current = containersRef.current.concat(
    //       returning.data.results
    //   );
    // } catch (error) {
    //   showApiError(error);
    // }
    console.log('containers', containersRef.current)
    if (mapRef.current)
      mapRef.current.drawContainerMarkers(containersRef.current);
    filterContainerPreview();
  }, []);

  const onSuccessGetDeliveries = useCallback(
    (data: GetDeliveryListApiResponse) => {
      allDeliveriesRef.current = data?.results;
      mapRef.current && mapRef.current.drawDeliveriesMarkers(data?.results);
      data?.results.every((d, index) => {
        if (clickedDeliveryUuid || (type === "delivery" && uuid)) {
          if (clickedDeliveryUuid === d.uuid || uuid === d.uuid) {
            uuid = d.uuid;
            handleClickDeliverCluster(data?.results, index);
            mapRef.current && mapRef.current.selectDeliveryMarkerByUuid(uuid);
            return false;
          }
        }
        return true;
      });
      if (clickedDeliveryUuid || uuid) {
        dispatch(clearDeliveryUuid());
      } else {
      }
    },
    []
  );

  const refreshDeliveries = useCallback(() => {
    getCurrentDeliveries(onSuccessGetDeliveries, {
      filter: filterBy,
    });
  }, [filterBy, onSuccessGetDeliveries]);

  const navigateLocationNotion = () => {
    window.open(LOCATION_LINK);
  };

  function onLocationRetrieved(position: GeolocationPosition | null) {
    console.log("onLocationRetrieved", position, mapRef.current);
    if (!!position) {
      locationErrorRef.current = false;
      if (mapRef.current) {
        mapRef.current.drawMarkerMyPosition(position);
      }
    } else {
      locationErrorRef.current = true;
    }
  }

  function onLocationWatched(position: GeolocationPosition | null) {
    console.log("onLocationWatched", position, mapRef.current);
    if (!!position) {
      locationErrorRef.current = false;
      if (mapRef.current) {
        mapRef.current.drawMarkerMyPosition(position);
      }
    }
  }

  function onLocationError(error?: GeolocationPositionError) {
    showMessageWithTitle(
      t("popup.nolocation.title"),
      t("popup.nolocation.content"),
      t("popup.nolocation.btn.ok"),
      () => {},
      t("popup.nolocation.btn.cancel"),
      navigateLocationNotion
    );
    locationErrorRef.current = true;
  }

  const onSortDeliveries = (sortBy: DeliverySortType) => {
    // dispatch(updateDeliverySort(sortBy));
  };

  const onRecentContainerClick = () => {
    setVisibleRecentContainer(false);
  };

  const handleSlidedToDelivery = (uuid: string) => {
    activeDeliveryIdx.current = uuid;
    mapRef.current && mapRef.current.selectDeliveryMarkerByUuid(uuid);
  };

  const handleSwiperSelectContainer = useCallback(
    (uuid: string) => {
      setSwiperDeliveries([]);
      if (isPreviewEnabled) {
        handlePreview(uuid);
      } else {
        mapRef.current && mapRef.current.removePreviewMarkers(); //remove delivery preview markers in container.
      }
    },
    [isPreviewEnabled]
  );

  const handleFocusToMyLocation = () => {
    handleEnableCompassAndroid();
    handleEnableCompassIOS();
    console.log("handleFocusToMyLocation", locationErrorRef.current);
    if (!!locationErrorRef.current) {
      getCurrentPosition(onLocationRetrieved, onLocationError);
      if (!watchIdRef.current) {
        watchIdRef.current = watchLocation(onLocationWatched);
      }
    } else {
      mapRef.current && mapRef.current.navigateToMyLocation();
    }
  };

  const handleMapBoundChanged = useCallback(() => {
    //do something
  }, []);

  const handleClickDeliverMarker = useCallback((index: number) => {
    setSwiperDeliveries(allDeliveriesRef.current);
    setSelectedContainers([]);
    activeDeliveryIdx.current = index + "";
    deliverySwiperRef.current &&
      deliverySwiperRef.current.setSelectedIndex(index);
  }, []);

  const handleClickDeliverCluster = useCallback(
    (deliveries: any[], index: number) => {
      setSwiperDeliveries(deliveries);
      deliverySwiperRef.current.setSelectedIndex(index);
      activeDeliveryIdx.current =
        deliveries.length > 0 ? deliveries[0].uuid : index + "";
    },
    []
  );

  const handleClickContainerMarker = useCallback((index: number) => {
    setSwiperDeliveries([]);
    setVisibleRecentContainer(false);
    if (
      index >= 0 &&
      containersRef.current &&
      containersRef.current.length > index
    ) {
      setSelectedContainers([containersRef.current[index]]);
    }
  }, []);

  const handlePreview = useCallback((uuid: string) => {
    getDeliveriesInContainer(uuid, (res) => {
      mapRef.current && mapRef.current.drawPreviewMarkers(res.results);
    });
  }, []);

  const handleRefresh = useCallback(() => {
    getDeliveriesCount((data) => {
      const counts = countDeliveries(data.results);
      dispatch(updateDeliveryCount(counts));
      refreshDeliveries();
    });
    fetchContainers();
  }, [refreshDeliveries]);

  const handleEnableCompassAndroid = () => {
    if (isCompassEnabled) {
      return;
    }
    if (!isIOS) {
      window.addEventListener(
        "deviceorientationabsolute",
        handleDeviceOrientationAndroid,
        true
      );
    }
  };

  function handleEnableCompassIOS() {
    if (isCompassEnabled) {
      return;
    }
    if (isIOS) {
      if (!!window.DeviceOrientationEvent) {
        if (typeof window.DeviceOrientationEvent.requestPermission === "function") {
          window.DeviceOrientationEvent.requestPermission()
            .then((response) => {
              if (response === "granted") {
                setIsCompassEnabled(true);
                window.addEventListener(
                  "deviceorientation",
                  handleDeviceOrientationIOS,
                  true
                );
              } else {
                console.log("compass not allowed");
              }
            })
            .catch((err) => {
              console.log("compass not allowed", err);
            });
        }
      }
    }
  }

  function handleDeviceOrientationIOS(evt: any) {
    if (
      initialOrientationOffset === undefined &&
      evt.absolute !== true &&
      +evt.webkitCompassAccuracy > 0 &&
      +evt.webkitCompassAccuracy < 50
    ) {
      initialOrientationOffset = evt.webkitCompassHeading || 0;
    }
    let alpha = evt.alpha - initialOrientationOffset;
    if (alpha < 0) {
      alpha += 360;
    }
    const compass = evt.webkitCompassHeading || Math.abs(alpha - 360);
    const compassCircle: any = document.querySelector(".compass-circle");
    if (!!compassCircle) {
      //rotate my location marker according to device orientation
      compassCircle.style.transform = `translate(-50%, -50%) rotate(${compass}deg)`;
    }
  }

  function handleDeviceOrientationAndroid(e: any) {
    const compassCircle: any = document.querySelector(".compass-circle");
    const compass = e.webkitCompassHeading || Math.abs(e.alpha - 360);
    isCompassEnabled || setIsCompassEnabled(!!e.absolute);
    if (!!compassCircle) {
      //rotate my location marker according to device orientation
      compassCircle.style.transform = `translate(-50%, -50%) rotate(${compass}deg)`;
    }
  }

  const handleClickMap = useCallback(() => {
    setSwiperDeliveries([]);
    setVisibleRecentContainer(false);
    setSelectedContainers([]);
    setIsControlVisible((isControlVisible) => !isControlVisible);
  }, [selectedContainers, visibleRecentContainer]);

  const handleCLickOverlapedContainer = useCallback((containers: any[]) => {
    setSwiperDeliveries([]);
    setSelectedContainers(containers);
    setVisibleRecentContainer(false);
  }, []);

  const filterNode = (
    <div className={"row " + styles.filterContainer}>
      <Button
        data-cy={"filterAll"}
        onClick={() => setFilterBy("all")}
        type={filterBy === "all" ? "primary" : undefined}
        className={styles.filterBtn + " " + styles.filterBtnL}
      >
        <span
          className={
            "small medium " + (filterBy === "all" ? "white" : "grey8c")
          }
        >
          {t("sort.all")}
        </span>
      </Button>
      <Button
        onClick={() => setFilterBy("pending")}
        type={filterBy === "pending" ? "primary" : undefined}
        className={styles.filterBtn + " " + styles.filterBtnM}
      >
        <span
          className={
            "small medium " + (filterBy === "pending" ? "white" : "grey8c")
          }
        >
          {t("sort.pending")}
        </span>
      </Button>
      <Button
        onClick={() => setFilterBy("complete")}
        type={filterBy === "complete" ? "primary" : undefined}
        className={styles.filterBtn + " " + styles.filterBtnR}
      >
        <span
          className={
            " small medium " + (filterBy === "complete" ? "white" : "grey8c")
          }
        >
          {t("sort.done")}
        </span>
      </Button>
    </div>
  );

  const zoomControl = (
    <div className={"column"}>
      <Button
        data-cy={"zoomPlus"}
        className={styles.zoomBtn + " " + styles.zoomTop}
        onClick={() => mapRef.current.zoomIn()}
      >
        +
      </Button>
      <div className={styles.zoomDivider} />
      <Button
        data-cy={"zoomMinus"}
        className={styles.zoomBtn + " " + styles.zoomBtm}
        onClick={() => mapRef.current.zoomOut()}
      >
        -
      </Button>
    </div>
  );

  const leftControlNode = (
    <div className={styles.floatingBtnCntr + " " + styles.leftFloating}>
      <Button
        data-cy={"previewEnable"}
        className={
          styles.floatingBtn + (isPreviewEnabled ? " primary-bg white" : "")
        }
        shape="circle"
        onClick={() =>
          setIsPreviewEnabled((isPreviewEnabled) => !isPreviewEnabled)
        }
        icon={
          <img alt={"refresh"} className={styles.compassImg} src={EyesSvg} />
        }
      />
      <Button
        data-cy={"scan"}
        className={styles.floatingBtn + " primary"}
        onClick={() => history.push(`/scan/1`)}
      >
        {t("map.btn.scan")}
      </Button>
      <Button
        data-cy={"delivery"}
        className={
          styles.floatingBtn + (isDeliveryVisible ? " primary-bg white" : "")
        }
        onClick={() =>
          setIsDeliveryVisible((isDeliveryVisible) => !isDeliveryVisible)
        }
      >
        {t("map.btn.delivery")}
      </Button>
      <Button
        data-cy={"containerVisible"}
        className={
          styles.floatingBtn + (isContainerVisible ? " primary-bg white" : "")
        }
        onClick={() =>
          setIsContainerVisible((isContainerVisible) => !isContainerVisible)
        }
      >
        {t("map.btn.container")}
      </Button>
      {zoomControl}
    </div>
  );

  const rightControlNode = (
    <div className={styles.floatingBtnCntr + " " + styles.rightFloating}>
      <Button
        data-cy={"markerVisible"}
        className={
          styles.floatingBtn + (isMarkerVisible ? "" : " primary-bg white")
        }
        onClick={() =>
          setIsMarkerVisible((isMarkerVisible) => !isMarkerVisible)
        }
      >
        {t("map.btn.hide")}
      </Button>
      {isControlVisible && (
        <>
          <Button
            className={styles.floatingBtn}
            data-cy={"refreshBtn"}
            onClick={handleRefresh}
            icon={
              <img
                alt={"refresh"}
                className={styles.refreshImg}
                src={RefreshOutlined}
              />
            }
          />
          {isIOS && (
            <Button
              className={
                styles.floatingBtn + (!!isCompassEnabled ? " primary-bg" : "")
              }
              shape="circle"
              onClick={handleEnableCompassIOS}
              icon={
                <CompassSvg
                  fill={!!isCompassEnabled ? " white" : "var(--primary)"}
                  className={styles.compassImg}
                />
              }
            />
          )}
          <Button
            className={styles.floatingBtn}
            data-cy={"navLoc"}
            onClick={handleFocusToMyLocation}
            icon={
              <img
                alt={"nearby"}
                className={styles.nearbyImg}
                src={MyLocationSvg}
              />
            }
          />
        </>
      )}
    </div>
  );

  return (
    <DaasMap
      ref={mapRef}
      compassSupported={isCompassEnabled}
      isContainerVisible={isContainerVisible}
      isDeliveryVisible={isDeliveryVisible}
      isVisibleMarkers={isMarkerVisible}
      onMapBoundChanged={handleMapBoundChanged}
      onClickMap={handleClickMap}
      onClickDelivery={handleClickDeliverMarker}
      onClickDeliveryCluster={handleClickDeliverCluster}
      onClickContainer={handleClickContainerMarker}
      onClickContainerCluster={handleCLickOverlapedContainer}
    >
      <>
        {isControlVisible && leftControlNode}
        {rightControlNode}
        <div className={styles.floatingContainer}>
          {(swiperDeliveries &&
            swiperDeliveries.length > 0 &&
            selectedContainers?.length > 0) ||
            visibleRecentContainer || <MapWarningEdu />}
        </div>
        <div className={styles.floatingContainer}>
          {isDeliveryVisible &&
            swiperDeliveries &&
            swiperDeliveries.length > 0 &&
            filterNode}
          {swiperDeliveries && swiperDeliveries.length > 0 ? (
            <MapDeliveryList
              ref={deliverySwiperRef}
              deliveries={swiperDeliveries}
              onSort={onSortDeliveries}
              onSelectedChange={handleSlidedToDelivery}
            />
          ) : (
            <MapRecentContainer
              visible={visibleRecentContainer}
              onClick={onRecentContainerClick}
            />
          )}
        </div>
        <MapContainerList
          filteredContainers={selectedContainers}
          onRefresh={fetchContainers}
          onPreview={handlePreview}
          isPreviewEnabled={isPreviewEnabled}
          onSelectedChange={handleSwiperSelectContainer}
        />
      </>
    </DaasMap>
  );
};

export default MapScreen;
