import React, { useState } from "react";
import "../login/loginPhoneScreen.css";
import HelmetView from "./HelmetView";
import { showMessage } from "../../../fns/message";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { UpdateUserApiParam } from "../../../api/apiTypes";
import { useDispatch } from "react-redux";
import { updateProfile } from "../../../reducers/profileReducer";
import PopupSpinner from "../../molecules/spinner/PopupSpinnerView";
import { uploadHelmetImage } from "../../../api/userApi";

const HelmetScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(["helmet"]);
  const [loading, setLoading] = useState(false);

  const sendImage = (image: any) => {
    if (image) {
      setLoading(true);
      uploadHelmetImage(image, onUploadProgress, onUploadSuccess);
    } else {
      showMessage(t("msg.noimage"));
    }
  };

  const onUploadProgress = (event: any) => {
    console.log("uploadProgress", event, navigateNext);
  };

  const onUploadSuccess = (res: UpdateUserApiParam) => {
    setLoading(false);
    if (res) {
      showMessage(t("msg.saved"));
      dispatch(updateProfile(res));
    } else {
      showMessage(t("msg.will.saved"));
    }
    navigateNext();
  };

  const navigateNext = () => {
    setTimeout(() => {
      history.replace("/home/profile");
    }, 1000);
  };

  return (
    <>
      <HelmetView onSubmit={sendImage} />
      {loading && <PopupSpinner />}
    </>
  );
};

export default HelmetScreen;
