import { AccountType } from "../components/pages/bank/types";
import {
  SectorType,
  UserType,
  DeliveryCountType,
  GetShippingStatusApiResponse,
  GetUserClaimCountApiResponse,
  GetUserApiResponse,
} from "../api/apiTypes";
import { NaviAppEnum } from "../components/organisms/selectNaviApp/SelectNaviPopup";

export enum BunnyType {
  FLEX = "FLEX",
  FIXED = "FIXED",
  STAFF = "STAFF",
}

export interface ProfileType extends AccountType, GetUserApiResponse {
  dob: string;
  fullname: string;
  is_available: boolean;
  ride_type: string;
  bunny_type: BunnyType;
  helmet_verified: boolean;
  uuid: string;
  email: string;
  locale: string;
  phonenumber: string;
  noti_allow_late?: boolean;
  username: string;
  type: UserType;
  di?: string;
  gender: string;
  res_seq: string;
  clickedDeliveryUuid: string;
  sector: SectorType[];
}

export interface UpdateProfileParam {
  phonenumber?: string;
  di?: string;
  fullname?: string;
  dob?: string;
  gender?: string;
  res_seq?: string;
  photo_ssn?: string;
  photo_bank?: string;
  file_ssn?: File;
  file_bank?: File;
  ride_type?: string;
  sector?: SectorType[];
  bank_info_name?: string;
  ssn?: string;
  bank_info_bank_code?: string;
  bank_info_account_number?: string;
}
export type PendingShippingType = {
  delivery_uuid: string;
  tracking_number: string;
  status: "pending" | "fail";
};

export type DeliverySortType = "count" | "distance" | "";
export interface CommonType
  extends DeliveryCountType,
    GetShippingStatusApiResponse,
    GetUserClaimCountApiResponse {
  showedInstallAlarm?: Date;
  deliveryStarted: boolean;
  visitedEdu: boolean;

  isFromRN: boolean;
  count_claimed_container: number;
  count_assigned_container: number;
  sortBy: DeliverySortType;
  authenticated: boolean;
  naviMap?: NaviAppEnum;
  pendingShippings: PendingShippingType[];
}
