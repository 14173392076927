import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import Delivery from "../../../assets/svgs/tab.delivery";
import List from "../../../assets/svgs/tab.list";
import Map from "../../../assets/svgs/tab.map";
import Profile from "../../../assets/svgs/tab.profile";
import "./tabbar.style.css";
import TabItem from "./TabItem";
import { useSelector } from "react-redux";
import { RootState } from "../../../reducers/reducer";

const TabbarView = () => {
  const location = useLocation();
  const {
    count_assigned_container,
    count_claimed_container,
    count_shipping_pending,
  } = useSelector((state: RootState) => state.common);
  const { bunny_type } = useSelector((state: RootState) => state.profile);
  const tabs = useMemo(() => {
    return [
      {
        route: "/home/map",
        icon: Map,
        label: "map",
      },
      {
        route: "/home/container",
        icon: List,
        label: "order",
        count: count_assigned_container + count_claimed_container,
      },
      {
        route: "/home/delivery",
        icon: Delivery,
        label: "delivery",
        count: count_shipping_pending,
      },
      {
        route: "/home/profile",
        icon: Profile,
        label: "profile",
      },
    ];
  }, [
    bunny_type,
    count_assigned_container,
    count_claimed_container,
    count_shipping_pending,
  ]);

  const path = useMemo(() => location.pathname, [location.key]);
  if (!path.includes("home")) {
    return null;
  }

  return (
    <nav className="tab-container" role="navigation">
      {tabs.map((tab, index) => {
        return (
          <TabItem
            key={`tab-${index}`}
            {...tab}
            count={tab.count}
            active={path.includes(tab.route)}
          />
        );
      })}
    </nav>
  );
};

export default TabbarView;
