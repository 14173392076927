import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import loginKO from "../locales/ko/login.json";
import signupKO from "../locales/ko/signup.json";
import bankKO from "../locales/ko/bank.json";
import commonKo from "../locales/ko/common.json";
import profileKO from "../locales/ko/profile.json";
import rideKO from "../locales/ko/ride.json";
import orderKO from "./ko/order.json";
import notifyKO from "../locales/ko/notify.json";
import helmetKO from "../locales/ko/helmet.json";
import scanKO from "../locales/ko/scan.json";
import deliveryKO from "../locales/ko/delivery.json";
import login from "../locales/en/login.json";
import signup from "../locales/en/signup.json";
import bank from "../locales/en/bank.json";
import common from "../locales/en/common.json";
import profile from "../locales/en/profile.json";
import ride from "../locales/en/ride.json";
import order from "./en/order.json";
import notify from "../locales/en/notify.json";
import helmet from "../locales/en/helmet.json";
import scan from "../locales/en/scan.json";
import delivery from "../locales/en/delivery.json";
const resources = {
  ko: {
    login: loginKO,
    common: commonKo,
    signup: signupKO,
    bank: bankKO,
    profile: profileKO,
    ride: rideKO,
    order: orderKO,
    notify: notifyKO,
    helmet: helmetKO,
    scan: scanKO,
    delivery: deliveryKO,
  },
  en: {
    login: login,
    common: common,
    signup: signup,
    bank: bank,
    profile: profile,
    ride: ride,
    order: order,
    notify: notify,
    helmet: helmet,
    scan: scan,
    delivery: delivery,
  },
};
i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    ns: [
      "common",
      "login",
      "signup",
      "bank",
      "ride",
      "notice",
      "notify",
      "helmet",
    ],
    fallbackLng: "ko",
    lng: "ko",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
