import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import "../../pages/helmet/helmet.style.css";
import { useTranslation } from "react-i18next";
import Helmet from "../../../assets/svgs/helmet.svg";
import { useHistory } from "react-router-dom";

type Props = {
  onOk?: () => void;
};

const HelmetPopup = forwardRef(({ onOk }: Props, ref) => {
  const { t } = useTranslation(["helmet"]);
  const [visible, setVisible] = useState(false);
  const history = useHistory();

  useImperativeHandle(
    ref,
    () => ({
      show: () => {
        setVisible(true);
      },
    }),
    []
  );

  const hide = () => {
    setVisible(false);
  };

  const navigateHelmet = () => {
    history.push("/profile/helmet");
  };
  const footerNode = (
    <div className={"popup-btn-container"}>
      <Button
        key="ok"
        className={"popup-btn ok"}
        type={"text"}
        data-cy={"navigateHelmet"}
        onClick={navigateHelmet}
      >
        {t("popup.btn")}
      </Button>
    </div>
  );

  return (
    <Modal
      closable={false}
      visible={visible}
      onOk={onOk}
      centered
      onCancel={hide}
      footer={footerNode}
    >
      <div className={"column align-center"}>
        <div className={"popup-title"}>{t("popup.title")}</div>
        <div className={"body popup-message helmet-popup-content"}>
          {t("popup.content")}
        </div>
        <div className={"helmet-svg-round"} />
        <img src={Helmet} />
      </div>
    </Modal>
  );
});

export default HelmetPopup;
